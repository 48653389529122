import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseChartDirective} from "ng2-charts/ng2-charts";
import {MatDialog, MatDialogRef} from "@angular/material";
import {CallsPopupComponent} from "./calls-popup/calls-popup.component";
import {CallsService} from "../../services/calls.service";
import {CallModel} from "../../models/call.model";
import {ListingsService} from "../../services/listings.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {Subscription} from 'rxjs';
import {ChartsService} from "../../services/charts.service";

@Component({
    selector: 'ms-calls-chart',
    templateUrl: './calls-chart.component.html',
    styleUrls: ['./calls-chart.component.scss']
})
export class CallsChartComponent implements OnInit, OnDestroy {
    @ViewChild('callsLineChart') callsLineChart: BaseChartDirective;
    callDates: Array<any>;
    callsResult: any;
    calls: any;
    callsTooltip: Array<any>;
    callsArray: Array<any>;
    callsChartLabels: Array<any>;
    callsChartData: Array<any>;
    callsChartOptions: any;
    callsData: CallModel[];
    callsListObj: CallModel;
    date: any;
    chartName = 'calls';
    callsDialog: MatDialogRef<CallsPopupComponent>;
    private callSubscription: Subscription = new Subscription();
    listing_id = this.localStorage.getItem('listing_id');
    @ViewChild(BaseChartDirective) private chart;

    callsChartColors: Array<any> = this.chartService.getChartColors();

    public callsChartLegend: boolean = false;
    public callsChartType: string = 'line';

    constructor(public dialog: MatDialog,
                public callsService: CallsService,
                public chartService: ChartsService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService) {
        this.callsChartData = [{data: [], arr: []}];
        this.callsChartLabels = []
        this.callsData = [];
        this.callsTooltip = [];
    }

    ngOnInit() {
        this.initChart();
    }

    initChart() {
        this.callSubscription.add(this.callsService.getListingCalls(this.listing_id).subscribe(calls => {
                this.callsData = calls;
                this.callsChart();
                this.callsChartOptions = this.chartService.getChartOptions();
            })
        )
    }
    forceChartRefresh() {
        this.initChart();
            if (this.chart && this.chart.chart && this.chart.chart.config) {
                this.chart.chart.config.data.labels = this.callsChartLabels;
                this.chart.chart.config.data.callsArray = this.callsArray;
                this.chart.chart.update();
            }
    }

    onOpenCallsPopup() {
        this.callsDialog = this.dialog.open(CallsPopupComponent, {
            disableClose: false
        });

        this.callsDialog.afterClosed().subscribe(result => {
            this.forceChartRefresh();
            this.callsDialog = null;
        });
    }

    callsChart() {

        this.callDates = [];
        this.callsArray = [];
        const helper = {};
        this.callsResult = this.callsData.reduce(function (r, o) {
            const key = o.created_at;
            o.call = 1;
            if (!helper[key]) {
                helper[key] = Object.assign({}, o);
                r.push(helper[key]);
            } else {
                helper[key].call += o.call;
            }
            return r;
        }, []);
        this.callsResult.forEach(days => {
            const tooltip = this.callsData.filter(
                item => {
                    return item.created_at === days.created_at;
                });
            this.callsTooltip.push(tooltip);
            const date = days.created_at;
            this.date = date;
            this.callDates.push(this.date);
            this.calls = days.call;
            this.callsArray.push(this.calls);
        });
        const sortedArray = this.callDates.sort((a, b) => a.localeCompare(b));
        this.callDates = this.callDates
            .sort((a, b) => a.localeCompare(b))
            .map(date => {
                const dateR = new Date(date);
                return this.listingService.formatDate(dateR);
            });
        this.callsArray = this.getCalls(this.callsResult, sortedArray);
        this.evokeTooltips();
    }

    getCalls(comArr, dates) {
        const newCalls = [];

        for (let i = 0; i < dates.length; i++) {
            for (let j = 0; j < comArr.length; j++) {
                if (dates[i] === comArr[j].created_at) {
                    newCalls.push(comArr[j].call);
                    i++;
                    j = -1;
                }
            }
        }
        return newCalls;
    }

    evokeTooltips() {
        const limit = 7;
        if (this.callsArray.length <= limit) {
            this.callsChartLabels = this.callDates;
            this.callsLineChart.chart.config.data.labels = this.callsChartLabels;
            this.callsChartData = [{data: this.callsArray, arr: this.callsTooltip}];
        } else {
            let quantity = 0;
            let index = 0;
            const resultArr = [0];
            const resultArrLabels = [this.callDates[0]];
            for (let i = 0; i < this.callsArray.length; i++) {
                if (quantity < limit) {
                    quantity += 1;
                } else {
                    quantity = 1;
                    index += 1;
                    resultArr[index] = 0;
                    resultArrLabels[index] = this.callDates[i];
                }
                resultArr[index] += this.callsArray[i];
            }
            this.callsChartLabels = resultArrLabels;
            this.callsLineChart.chart.config.data.labels = this.callsChartLabels;
            this.callsChartLabels[this.callsChartLabels.length - 1] = resultArrLabels[resultArrLabels.length - 1] = 'This Week';

            this.callsChartData = [{data: resultArr, arr: this.callsTooltip}];
        }
    }

    ngOnDestroy(): void {
        this.callSubscription.unsubscribe();
    }
}
