import {Component, OnInit} from '@angular/core';
import {ListingsService} from "../services/listings.service";
import {LocalStorageService} from "../services/local-storage.service";
import {TasksService} from "../services/tasks.service";
import {TodosModel} from "../checklist/todos.model";
import {Router} from "@angular/router";

@Component({
    selector: 'ms-stage-buttons',
    templateUrl: './stage-buttons.component.html',
    styleUrls: ['./stage-buttons.component.scss']
})
export class StageButtonsComponent implements OnInit {
    todos: TodosModel[];
    incompleteTodos: TodosModel[];
    overdueTodos: TodosModel[];
    listing_id: string

    constructor(public listingService: ListingsService,
                public localStorage: LocalStorageService,
                public tasksService: TasksService,
                public router: Router) {
        this.overdueTodos = [];
        this.todos = [];
    }

    goToTask(array) {
        this.tasksService.sendTaskIndex(0);
        this.router.navigate([`./escrow-stage/tasks/${array[0].todo_id}`]);
    }

    goToOverdueItems() {
        this.getDatesDifference();
        if (this.overdueTodos.length > 0) {
            this.goToTask(this.overdueTodos);
        } else {
            this.router.navigate([`./escrow-stage`])
        }
    }

    goToCurrentTask() {
        if (this.todos.length > 0) {
            this.incompleteTodos = this.todos.filter(todo => todo.completed_at === null);
            this.goToTask(this.incompleteTodos);
        } else {
            this.router.navigate([`./escrow-stage`])
        }
    }

    getDatesDifference() {
        const now = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        this.todos.forEach(todo => {
            const start_date = new Date(todo.start_at);
            const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
            const utc2 = Date.UTC(start_date.getFullYear(), start_date.getMonth(), start_date.getDate());
            const difference = Math.floor((utc1 - utc2) / _MS_PER_DAY);
            if (todo.time_limit < difference) {
                this.overdueTodos.push(todo);
            }
        });
    }

    ngOnInit() {
        this.listing_id = this.localStorage.getListingId();
        this.tasksService.getTodoByListingId(this.listing_id).subscribe(todos => {
            if (todos.todos.length > 0) {
                this.todos = todos.todos;
            } else {
                this.todos = todos.templates;
            }
        })
    }

}
