export class SmdListingModel {
    constructor(
        public smd_listing_id: string,
        public smd_street_address: string,
        public area: number,
        public city: string,
        public country: string,
        public crossstreetaddress: string,
        public directions: string,
        public region: string,
        public streetname: string,
        public streetnumber: number,
        public streetpostdirection: string,
        public streetsuffix: string,
        public zipcode: string,
        public user_id: string,
        public agreementtype: string,
        public apn: string,
        public auction: string,
        public association: string,
        public age: number,
        public bathrooms: number,
        public additionalapn: string,
        public additionalapn2: string,
        public additionalapn3: string,
        public additionalapn4: string,
        public additionalapn5: string,
        public additionaldebt: string,
        public agentremarks: string,
        public agerestrictions: string,
        public bedroomlevel: string,
        public commissionamount: number,
        public commissiontype: string,
        public commissioncomments: string,
        public bedrooms: number,
        public hoassociation: string,
        public otheramenities: string,
        public hoadues: string,
        public mobilehometype: string,
        public emailoffers: string,
        public excludedfromsale: string,
        public email: string,
        public gatecode: string,
        public basement: string,
        public fireplace: string,
        public foundation: string,
        public numberoffireplaces: number,
        public fireplacelocation: string,
        public showing: string,
        public alarmcheckbox: boolean,
        public showinginstr: string,
        public alarm: string,
        public directionstoproperty: string,
        public lotmeasurement: string,
        public lotsizesource: string,
        public lotsquarefootage: number,
        public propertysubtype1: string,
        public propertysubtype2: string,
        public propertysubtype3: string,
        public propertytype: string,
        public phonetoshow: string,
        public parkdescr: string,
        public permanentfoundation: string,
        public reportavailable: string,
        public ownername: string,
        public ownerphone: string,
        public squarefootage: number,
        public squarefootagesource: string,
        public supralockbox: string,
        public shortsale: string,
        public sellerwillconsider: string,
        public showavmonvow: string,
        public showcmntsonvow: string,
        public yearbuilt: number,
        public yearbuiltexception: string,
        public availabledate: Date,
        public contingentexpirationdate: Date,
        public listingdate: Date,
        public pendingdate: Date,
        public subleaseexpiration: Date,
        public ftrustdeedamt: string,
        public strustdeedamt: string,
        public garagespaces: number,
        public leasetype: string,
        public leasetypemonthly: string,
        public marketingremarks: string,
        public notes: string,
        public occupantname: string,
        public occupanttype: string,
        public pool: string,
        public publishtointernet: boolean,
        public eatingareaarea: number,
        public eatingareadim: string,
        public eatingarealen: number,
        public eatingareawid: number,
        public secondbedroomarea: number,
        public secondbedroomdim: string,
        public secondbedroomlen: number,
        public secondbedroomwid: number,
        public thirdbedroomarea: number,
        public thirdbedroomdim: string,
        public thirdbedroomlen: number,
        public thirdbedroomwid: number,
        public fourthbedroomarea: number,
        public fourthbedroomdim: string,
        public fourthbedroomlen: number,
        public fourthbedroomwid: number,
        public utilityroomarea: number,
        public utilityroomdim: string,
        public utilityroomlen: number,
        public utilityroomwid: number,
        public mainbedroomarea: number,
        public mainbedroomdim: string,
        public mainbedroomlen: number,
        public mainbedroomwid: number,
        public livingroomarea: number,
        public livingroomdim: string,
        public livingroomlen: number,
        public livingroomwid: number,
        public kitchenarea: number,
        public kitchendim: string,
        public kitchenlen: number,
        public kitchenwid: number,
        public greatroomarea: number,
        public greatroomdim: string,
        public greatroomlen: number,
        public greatroomwid: number,
        public formaldiningroomarea: number,
        public formaldiningroomdim: string,
        public formaldiningroomlen: number,
        public formaldiningroomwid: number,
        public extraroomarea: number,
        public extraroomdim: string,
        public extraroomlen: number,
        public extraroomwid: number,
        public familyroomarea: number,
        public familyroomdim: string,
        public familyroomlen: number,
        public familyroomwid: number,
        public showaddresstopublic: boolean,
        public schooldistrict: string,
        public schoolname1: string,
        public schoolname2: string,
        public schoolname3: string,
        public schoolscomment: string,
        public sublease: string,
        public transactiontype: string,
        public listingagentmlsid: string,
        public listingcoagentmlsid: string,
        public listingcoofficemlsid: string,
        public listingofficemlsid: string,
        public resi1sln: string,
        public resi2ln: string,
        public resibssq: number,
        public resibthf: string,
        public resicnss: number,
        public residets: string,
        public residinf: string,
        public residsft: string,
        public residual: string,
        public resiener: string,
        public resiextr: string,
        public resiflor: string,
        public resifreq: string,
        public resigart: string,
        public resigpm: number,
        public resigsel: string,
        public resihtcl: string,
        public resihudn: string,
        public resiktcn: string,
        public resilbox: string,
        public resilics: string,
        public resilndr: string,
        public resilsde: string,
        public resiltdm: string,
        public resimodl: string,
        public resipato: string,
        public resipknm: string,
        public resipolt: string,
        public resiprkf: string,
        public resirebk: string,
        public resirent: number,
        public resiroof: string,
        public resiscop: string,
        public resisite: string,
        public resisnum: string,
        public resispat: string,
        public resispay: string,
        public resispec: string,
        public resispft: string,
        public resistor: number,
        public resitype: string,
        public resiunds: string,
        public resiuniq: string,
        public resiwlhp: number,
        public resiwtrl: number,
        public resiwatr: string,
        public resixtrr: string,
        public resiyard: string,
        public resizone: string,
        public listingprice: number,
        public unit: string,
        public enteredtomls: boolean,
        public latitude: number,
        public longitude: number,
        public county?: string,
        public streetdirection?: string,
        public streetnumbermodifier?: string,
        public zipplus4?: string,
        public boardloaded?: string,
        public cdom?: number,
        public dom?: number,
        public acres?: number,
        public bathroomsdisplay?: string,
        public required_changes?: Array<string>,
        public edited_fields?: Array<string>,
        public fullbathrooms?: number,
        public halfbathrooms?: number,
        public possiblebedrooms?: number,
        public quarterbathrooms?: number,
        public threequarterbathrooms?: number,
        public contingentdate?: Date,
        public entrydate?: Date,
        public estimatedsellingdate?: Date,
        public inactivedate?: Date,
        public lastmodifieddatetime?: Date,
        public picturemodifieddatetime?: Date,
        public searchcontractualdate?: Date,
        public sellingdate?: Date,
        public statusdate?: Date,
        public enteredbymlsid?: string,
        public financing?: string,
        public financingcomments?: string,
        public fipscode?: number,
        public legaldescription?: string,
        public listingrid?: number,
        public mlnumber?: number,
        public mls?: string,
        public photographerinstructions?: string,
        public picturecount?: number,
        public pictureprovidedby?: string,
        public propertydisclaimer?: string,
        public reciprocallisting?: string,
        public reciprocalmemberareacode?: string,
        public reciprocalmembername?: string,
        public reciprocalmemberphone?: string,
        public reciprocalofficename?: string,
        public state?: string,
        public status?: string,
        public statuscomments?: string,
        public schoolname4?: string,
        public schooltype1?: string,
        public schooltype2?: string,
        public schooltype3?: string,
        public schooltype4?: string,
        public tractbuilder?: string,
        public listingagentfirstname?: string,
        public listingagentlastname?: string,
        public listingagentnumber?: number,
        public listingcoagentfullname?: string,
        public listingcoagentnumber?: number,
        public nonmembersalesagentname?: string,
        public listingcoofficename?: string,
        public listingcoofficenumber?: number,
        public listingofficename?: string,
        public listingofficenumber?: number,
        public nonmembersalesofficename?: string,
        public resi2bdr?: string,
        public resi3bdr?: string,
        public resi4th?: string,
        public resiarea?: string,
        public resibsmd?: string,
        public resicrpt?: number,
        public residesc?: string,
        public resieata?: string,
        public resifamr?: string,
        public resifloo?: string,
        public resifmld?: string,
        public resifrpn?: number,
        public resifrpt?: string,
        public resigrtr?: string,
        public resiinpk?: string,
        public resiirpt?: string,
        public resikitc?: string,
        public resilivr?: string,
        public resimain?: string,
        public resipark?: string,
        public resipoly?: string,
        public resiramt?: number,
        public resiscdt?: string,
        public resisola?: string,
        public resisolr?: string,
        public resistyl?: string,
        public resiterm?: string,
        public resitrct?: string,
        public resiutlr?: string,
        public resiwldp?: number,
        public resixtra?: string,
        public originalprice?: number,
        public priceperacre?: number,
        public pricepersquarefoot?: number,
        public searchprice?: number,
        public sellingprice?: number,
        public virtualtoururl?: string,
        public zillow_views?: object,
        public realtor_views?: object
    ) {
    }
}
