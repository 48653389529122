import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {UsersService} from "../../services/users.service";
import {UserModel} from "../../models/user.model";

@Component({
    selector: 'ms-first-visit-popup',
    templateUrl: './welcome-popup.component.html',
    styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent implements OnInit {
    userId: string;

    constructor(public firstVisit: MatDialogRef<WelcomePopupComponent>,
                public userService: UsersService) {
    }

    ngOnInit() {
    }

    dontShowAgain() {
        this.userId = this.userService.getUserId();
        const data = {
            popup: 1
        };
        this.userService.updateUser(this.userId, data).subscribe(userId => {
            return userId;
        });
        this.firstVisit.close();
    }

}
