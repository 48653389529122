import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {UsersService} from "../../../services/users.service";
import {Subscription} from "rxjs";
import {UserModel} from "../../../models/user.model";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
  selector: 'ms-delete-user-popup',
  templateUrl: './delete-user-popup.component.html',
  styleUrls: ['./delete-user-popup.component.scss']
})
export class DeleteUserPopupComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  user: UserModel;

  constructor(public deletePopup: MatDialogRef<DeleteUserPopupComponent>,
              public userService: UsersService,
              public localStorage: LocalStorageService,
              public router: Router) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.subscription.add(this.userService.getUserData().subscribe(user => {
      if (user) {
        this.user = user.data;
      }
    }));
  }

  deleteUser() {
    const role = this.localStorage.getUserRole();
    this.subscription.add(this.userService.deleteUser(this.user).subscribe(user => user));
    this.deletePopup.close();
    if (role === 'super_admin') {
      this.router.navigate(['/users']);
    } else {
      this.router.navigate(['/register']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
