"use strict";

import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageTitleService} from '../../core/page-title/page-title.service';
import {fadeInAnimation} from "../../core/route-animation/route.animation";
import {UserModel, UserRole} from "../../models/user.model";
import {UsersService} from "../../services/users.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {DeleteUserPopupComponent} from "./delete-user-popup/delete-user-popup.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UpdateUserPopupComponent} from "./update-user-popup/update-user-popup.component";
import {Subscription} from "rxjs";

@Component({
    selector: 'ms-userprofile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [fadeInAnimation]
})
export class UserProfileComponent implements OnInit, OnDestroy {
    user: UserModel;
    role: string;
    roleData: UserRole;
    isProfile: boolean = false;
    superAdmin: boolean = false;
    isAdmin: boolean = false;
    userId: string;
    hasProperty: boolean = true;
    userRole: string = '';
    userRoles: UserRole[];
    passwordWasReset = false;
    message = '';
    deletePopup: MatDialogRef<DeleteUserPopupComponent>;
    updatePopup: MatDialogRef<UpdateUserPopupComponent>;
    private userSubscription: Subscription = new Subscription();
    public imagePath;

    constructor(private pageTitleService: PageTitleService,
                public userService: UsersService,
                private route: ActivatedRoute,
                public router: Router,
                public cookie: CookieService,
                public dialog: MatDialog,
                public localStorage: LocalStorageService) {
    }

    getUserData() {
        this.userId = this.route.snapshot.paramMap.get('userId');
        this.userSubscription.add(this.userService.getUser(this.userId).subscribe(user => {
            this.user = user;
            this.getUserRole();
        }));
    }

    getUserRole() {
        this.userSubscription.add(this.userService.getUserRole(this.user.user_role_id).subscribe(roleData => {
            this.userRole = roleData.name;
            })
        )
    }

    resetPassword(user) {
        this.userService.postUserEmail(user.email).subscribe(res => {
            if (res) {
                this.passwordWasReset = true;
                this.message = res['message'] || `An email was sent to your inbox.`;
            }
        });
    }

    getUserProfileImg(files) {
        if (files.length === 0)  {return; }

        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.user.profile_img = reader.result;
            this.userService.updateUser(this.user.user_id, this.user).subscribe(user => {
                this.user = user;
                this.localStorage.setItem('user', JSON.stringify(this.user));
            })
        }
    }

    getUserProfile() {
        this.user = this.userService.getUserObject();
        this.getUserRole();
        this.isProfile = true;
    }

    updateUser(user) {
        this.userService.sendUserData(user);
        this.openUpdatePopup();
    }

    openDeletePopup() {
        this.deletePopup = this.dialog.open(DeleteUserPopupComponent, {
            disableClose: false
        });

        this.deletePopup.afterClosed().subscribe(result => {
            this.deletePopup = null;
        });
    }

    openUpdatePopup() {
        this.updatePopup = this.dialog.open(UpdateUserPopupComponent, {
            disableClose: false
        });

        this.updatePopup.afterClosed().subscribe(result => {
            this.userSubscription.add(this.userService.getUserEditedData().subscribe(data => {
                if (data) {
                    this.user = data.data;
                    this.localStorage.setItem('user', JSON.stringify(this.user));
                    this.userSubscription.add(this.userService.getUserRole(this.user.user_role_id).subscribe(role => {
                        this.userRole = role.name;
                    }))
                }
            }));
            this.updatePopup = null;
        });
    }

    deleteUser(user) {
        this.openDeletePopup();
        this.userService.sendUserData(user);
    }

    ngOnInit() {
        this.pageTitleService.setTitle("User Profile");
        this.role = this.localStorage.getUserRole();
        if (this.router.url === '/profile') {
            this.superAdmin = this.role === 'super_admin';
            this.isAdmin = this.role === 'admin';
            this.getUserProfile();
        } else {
            this.superAdmin = false;
            this.isAdmin = false;
            this.getUserData();
        }
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }
}
