import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInAnimation} from "../../core/route-animation/route.animation";

@Component({
  selector: 'ms-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [ fadeInAnimation ]
})
export class PropertyDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
