import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material";
import {LocalStorageService} from "../../../services/local-storage.service";
import {SmdListingModel} from "../../../models/smd-listing.model";
import { DateTime } from "luxon";
import {ListingsService} from "../../../services/listings.service";

@Component({
    selector: 'ms-views-form-realtor',
    templateUrl: './views-form-realtor.component.html',
    styleUrls: ['./views-form-realtor.component.scss']
})
export class ViewsFormRealtorComponent implements OnInit {

    viewFormRealtor: FormGroup;
    user_id: any;
    error_message = true;
    listing: SmdListingModel;

    constructor(public realtorDialog: MatDialogRef<ViewsFormRealtorComponent>,
                public listingService: ListingsService,
                public localStorage: LocalStorageService) {
        this.listing = JSON.parse(localStorage.getItem('listing'));
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.viewFormRealtor = new FormGroup({
            'date': new FormControl('', Validators.required),
            'views': new FormControl('', Validators.required),
        });
    }

    sendViewData(): void {
        const now = new Date(this.viewFormRealtor.value.date).toISOString()
        const date = DateTime.fromISO(now).toISODate();
        const realtNum = this.viewFormRealtor.value.views;
        const data = {[date]: realtNum}
        const endObj = {...this.listing.realtor_views, ...data};
        this.listing.realtor_views = endObj;
        this.listingService.updateListing(this.listing).subscribe(list => {
            this.localStorage.removeItem('listing');
            this.localStorage.setItem('listing', JSON.stringify(list));
            this.realtorDialog.close();
        })
    }

    addViewRealtor() {
        if (this.viewFormRealtor.valid) {
            this.sendViewData();
        } else {
            this.error_message = false;
        }
    }

}
