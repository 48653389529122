import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {CallModel} from "../models/call.model";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {api_url} from "../../environments/environment";
import {HttpService} from "./http.service";


@Injectable({
  providedIn: 'root'
})
export class CallsService {
    private api_url = api_url.url;
    private callData = new BehaviorSubject(undefined);

    constructor(private httpClient: HttpClient, public httpService: HttpService) { }

    sendCallData(data: CallModel) {
        this.callData.next({data: data});
    }

    getCallData() {
        return this.callData;
    }

    getListingCalls(id): Observable<CallModel[]> {
        return this.httpClient.get<CallModel[]>(`${this.api_url}/call/listing/${id}`, {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    postListingCall(data: CallModel): Observable<CallModel> {
        const httpOptions = this.httpService.getHttpOptions();
        return this.httpClient.post<CallModel>(`${this.api_url}/call`,
            JSON.stringify(data), httpOptions
        )
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
