import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LocalStorageService} from "../services/local-storage.service";
import {TasksService} from "../services/tasks.service";
import {TodosModel} from "../checklist/todos.model";

@Component({
    selector: 'ms-prepare-stage',
    templateUrl: './prepare-stage.component.html',
    styleUrls: ['./prepare-stage.component.scss']
})

export class PrepareStageComponent implements OnInit {
    readyToSell: boolean = false;
    prepareForm: FormGroup;
    todos: TodosModel[];
    activeTab: number;

    constructor(private router: Router,
                public tasksService: TasksService,
                public localStorage: LocalStorageService) {
        this.todos = [];
    }

    ngOnInit() {
        this.activeTab = 1;
        this.tasksService.sendActiveTab(this.activeTab);
        this.initForm();
    }

    checkTask(task) {
        task.completed_at = new Date();
        // TODO update todo if user can do it
    }

    onSendTaskIndex(index) {
        this.tasksService.sendTaskIndex(index);
    }

    private initForm() {
        this.prepareForm = new FormGroup({
            'knowWhere': new FormControl(false, Validators.requiredTrue),
            'enoughEquity': new FormControl(false, Validators.requiredTrue),
            'enoughToAccomplish': new FormControl(false, Validators.requiredTrue),
            'readyToSell': new FormControl(false, Validators.requiredTrue)
        });
    }

    onReadyToSell() {
        this.localStorage.setItem('stage', 'mls');
        this.router.navigate(['/mls'], { queryParams: { stage: 'mls' }});
    }
}
