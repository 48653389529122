import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStorageService} from "../services/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationGuard implements CanActivate {
    constructor(public localStorage: LocalStorageService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const id = this.localStorage.getItem('listing_id');
        const stage = this.localStorage.getItem('stage');
        if (id || stage === 'prepare-stage' || stage === 'mls') {return true};
    }
}
