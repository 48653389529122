import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OfferService} from "../../services/offer.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'ms-popup-offer',
    templateUrl: './popup-offer.component.html',
    styleUrls: ['./popup-offer.component.scss'],
})
export class PopupOfferComponent implements OnInit, OnDestroy {
    private offerSubscription: Subscription = new Subscription();
    addOfferForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<PopupOfferComponent>,
                private localStorage: LocalStorageService,
                private offerService: OfferService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.addOfferForm = new FormGroup({
            'personname': new FormControl('', Validators.required),
            'offer_date': new FormControl('', Validators.required),
            'realtycompanyname': new FormControl('', Validators.required),
            'price': new FormControl(''),
            'smd_listing_id': new FormControl(this.localStorage.getItem('listing_id'))
        });

    }

    sendOfferData(): void {
        this.offerService.sendOfferData(this.addOfferForm.value);
        this.offerSubscription.add(this.offerService.postOffer(this.addOfferForm.value).subscribe(offer => {
            return offer;
        }));
    }

    addOffer() {
        this.sendOfferData();
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.offerSubscription.unsubscribe();
    }

}


