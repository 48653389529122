import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material";
import {ShowingsHistoryComponent} from "./showings-history/showings-history.component";
import {ShowingFormComponent} from "./showing-form/showing-form.component";
import {ShowingService} from "../../services/showing.service";
import {BaseChartDirective} from "ng2-charts/ng2-charts";
import {LocalStorageService} from "../../services/local-storage.service";
import {ShowingModel} from "../../models/showing.model";
import {ListingsService} from "../../services/listings.service";
import {ChartsService} from "../../services/charts.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'ms-showings-chart',
    templateUrl: './showings-chart.component.html',
    styleUrls: ['./showings-chart.component.scss']
})
export class ShowingsChartComponent implements OnInit, OnDestroy {
    @ViewChild(BaseChartDirective) private chart;
    showingListObj: ShowingModel;
    lineChartOptions: any;
    showingData: ShowingModel[];
    lineChartData: Array<any>;
    lineChartLabels: Array<any>;
    showingsTooltip: Array<any>;
    chartName = 'showings';
    lineChartColors: Array<any> = this.chartsService.getChartColors();

    public lineChartLegend: boolean = false;
    public lineChartType: string = 'line';

    dates: Array<any>;
    date: any;
    views: any;
    viewsArray: Array<any>;
    result: any;
    showingFormDialog: MatDialogRef<ShowingFormComponent>;
    showingHistoryDialog: MatDialogRef<ShowingsHistoryComponent>;
    private showingSubscription: Subscription = new Subscription();
    listing_id = this.localStorage.getItem('listing_id');
    addShowing = true;

    constructor(public dialog: MatDialog,
                public showingService: ShowingService,
                public localStorage: LocalStorageService,
                public chartsService: ChartsService,
                public listingService: ListingsService) {
        this.showingData = [];
        this.lineChartLabels = []
        this.showingsTooltip = [];
    }

    ngOnInit() {
   this.initChart();
    }
    initChart() {
        this.showingSubscription.add(this.showingService.getListingShowings(this.listing_id).subscribe(showings => {
            this.showingData = showings;
            this.showingsChart();
            this.lineChartOptions = this.chartsService.getChartOptions();
            if (this.chart && this.chart.chart && this.chart.chart.config) {
                this.chart.chart.config.data.labels = this.lineChartLabels;
                this.chart.chart.config.data.callsArray = this.viewsArray;
                this.chart.chart.update();
            }
        }))
    }

    showHistory() {
        this.showingHistoryDialog = this.dialog.open(ShowingsHistoryComponent, {
            disableClose: false
        });

        this.showingHistoryDialog.afterClosed().subscribe(result => {
            this.initChart();
            this.showingHistoryDialog = null;
        });
    }

    openDialog() {
        this.showingFormDialog = this.dialog.open(ShowingFormComponent, {
            disableClose: false
        });

        this.showingFormDialog.afterClosed().subscribe(result => {
            this.initChart();
            this.showingFormDialog = null;
        });
    }

    showingsChart() {
        this.dates = [];
        this.viewsArray = [];
        const helper = {};

        this.result = this.showingData.reduce(function (r, o) {
            const key = o.showing_date;
            o.views = 1;
            if (!helper[key]) {
                helper[key] = Object.assign({}, o);
                r.push(helper[key]);
            } else {
                helper[key].views += o.views;
            }
            return r;
        }, []);

        this.result.forEach(days => {
            const tooltip = this.showingData.filter(
                item => {
                    return item.showing_date === days.showing_date;
                });
            this.showingsTooltip.push(tooltip);
            const date = days.showing_date;
            this.date = date;
            this.dates.push(this.date);
            this.views = days.views;
            this.viewsArray.push(this.views);
        });
        const sortedArray = this.dates.sort((a, b) => a.localeCompare(b));
        this.dates = this.dates
            .sort((a, b) => a.localeCompare(b))
            .map(date => {
                const dateR = new Date(date);
                return this.listingService.formatDate(dateR);
            });
        this.viewsArray = this.getViews(this.result, sortedArray);
        this.evokeTooltip();
    }
    evokeTooltip() {
        const limit = 7;

        if (this.viewsArray.length <= limit) {
            this.lineChartLabels = this.dates;
            this.lineChartData = [{data: this.viewsArray, arr: this.showingsTooltip}];

        } else {
            let quantity = 0;
            let index = 0;
            const resultArr = [0];
            const resultArrLabels = [this.dates[0]];
            for (let i = 0; i < this.viewsArray.length; i++) {
                if (quantity < limit) {
                    quantity += 1;
                } else {
                    quantity = 1;
                    index += 1;
                    resultArr[index] = 0;
                    resultArrLabels[index] = this.dates[i];
                }
                resultArr[index] += this.viewsArray[i];
            }
            this.lineChartLabels = resultArrLabels;
            this.lineChartLabels[this.lineChartLabels.length - 1] = resultArrLabels[resultArrLabels.length - 1] = 'This Week';
            this.lineChartData = [{data: resultArr, arr: this.showingsTooltip}];
        }
    }
    getViews(comArr, dates) {
        const newViews = [];
        for (let i = 0; i < dates.length; i++) {
            for (let j = 0; j < comArr.length; j++) {
                if (dates[i] === comArr[j].showing_date) {
                    newViews.push(comArr[j].views);
                    i++;
                    j = -1;
                }
            }
        }
        return newViews;
    }
    ngOnDestroy(): void {
        this.showingSubscription.unsubscribe();
    }
}
