import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {AgmMap} from "@agm/core";
import {HousesService} from "../../../services/houses.service";
import {LocalStorageService} from "../../../services/local-storage.service";
import {ListingsService} from "../../../services/listings.service";

@Component({
    selector: 'ms-new-on-market-popup',
    templateUrl: './new-on-market-popup.component.html',
    styleUrls: ['./new-on-market-popup.component.scss']
})
export class NewOnMarketPopupComponent implements OnInit {
    circleRadius: number = 10000; // km
    filteredMarkers: Array<any> = [];
    similarProperty: object;
    lat: string;
    lon: string;
    address: string;
    zoom = 15;

    @ViewChild(AgmMap) map: AgmMap;
    @Input() public userProperty;

    constructor(
        public popup: MatDialogRef<NewOnMarketPopupComponent>,
        public housesService: HousesService,
        public localStorage: LocalStorageService,
        public listingService: ListingsService) {
    }

    ngOnInit() {
        this.filteredMarkers = JSON.parse(this.localStorage.getItem('houses'));
        this.lat = this.userProperty.latitude;
        this.lon = this.userProperty.longitude;
        this.address = this.userProperty.address;
        this.similarProperty = {
            price: this.userProperty.listingprice,
            lat: this.userProperty.latitude,
            long: this.userProperty.longitude
        }
    }

}
