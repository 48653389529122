import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replaceNull'
})
export class ReplaceNullPipe implements PipeTransform {
    transform(value: string, args?: any): string {
        if (!value) {
            return 'N/A';
        } else {
            return value
        }
    }
}
