import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../services/users.service';
import {LocalStorageService} from '../services/local-storage.service';

@Component({
    selector: 'ms-forgot-password',
    templateUrl: './forgot-password-component.html',
    styleUrls: ['./forgot-password-component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
    email: string;
    password: string;
    user: any;
    infoMessage: boolean = false;
    errorMessage: boolean = false;
    isEmailExists: boolean = true;

    constructor(private router: Router,
                private service: UsersService,
                public localStorage: LocalStorageService) {
    }

    send(email) {
        this.service.postUserEmail(email).subscribe(res => {
           if (res) {
               this.email = email;
               this.infoMessage = true;
           }
        }, error => {
            if (error.status === 409) {
                this.isEmailExists = false;
            } else {
                this.errorMessage = true;
            }
        });
    }

    ngOnInit() {

    }
}

