"use strict";

import {Component, OnDestroy, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {TranslateService} from 'ng2-translate/ng2-translate';
import {Router} from "@angular/router";
import {UsersService} from "./services/users.service";
import {LocalStorageService} from "./services/local-storage.service";

@Component({
    selector: 'force-app',
    template: '<router-outlet></router-outlet>',
    encapsulation: ViewEncapsulation.None,
})
export class ForceAppComponent implements OnInit {
    username = '';
    password = '';
    users: any;
    user: any;
    remember = false;

    constructor(translate: TranslateService,
                private router: Router,
                public service: UsersService,
                public localStorage: LocalStorageService) {
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('en');

        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit() {}
}
