import {Component, Input, OnInit} from '@angular/core';
import {MapsAPILoader} from "@agm/core";
import {HousesService} from "../../services/houses.service";
import {MatDialog, MatDialogRef} from "@angular/material";
import {AcceptedOffersPopupComponent} from "./accepted-offers-popup/accepted-offers-popup.component";
import {HouseModel} from "../../models/house.model";
import {LocalStorageService} from "../../services/local-storage.service";
import {ListingsService} from "../../services/listings.service";

declare var google: any;

@Component({
    selector: 'ms-accepted-offers',
    templateUrl: './accepted-offers.component.html',
    styleUrls: ['./accepted-offers.component.scss']
})
export class AcceptedOffersComponent implements OnInit {
    housesArr: HouseModel[];
    acceptedOffersPopup: MatDialogRef<AcceptedOffersPopupComponent>;
    @Input() userProperty;

    constructor(public mapsApiLoader: MapsAPILoader,
                public housesService: HousesService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService,
                public dialog: MatDialog) {
        this.housesArr = [];
    }

    ngOnInit() {
        const similarProperty = this.housesService.getSimilarProperties();
            this.housesService.getAcceptedOffers(similarProperty).subscribe(houses => {
                this.housesArr = houses;
            })
    }

    openAcceptedOffersMap() {
        this.acceptedOffersPopup = this.dialog.open(AcceptedOffersPopupComponent, {
            disableClose: false
        });
        this.acceptedOffersPopup.componentInstance.userProperty = this.userProperty;

        this.acceptedOffersPopup.afterClosed().subscribe(result => {
            this.acceptedOffersPopup = null;
        });
    }

}
