import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserModel, UserRole} from "../../models/user.model";
import {UsersService} from "../../services/users.service";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {LocalStorageService} from "../../services/local-storage.service";
import {MatDialogRef} from "@angular/material/dialog";
import {CustomValidators} from "../../shared/CustomValidation";

@Component({
  selector: 'ms-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  createUserForm: FormGroup;
  user: UserModel;
  isAdmin = false;
  changeUserRole = false;
  userRole = '';
  role: string;
  roleId: string;
  roleData: UserRole;
  selectedRole = '';
  userRoles: UserRole[];
  fingerprint: string;
  changedRole = false;

  constructor(public userService: UsersService,
              public router: Router,
              public cookieService: CookieService,
              public localStorage: LocalStorageService,
              public createPopup: MatDialogRef<CreateUserComponent>) {
  }

  createUser() {
    const userData = {
      first_name: this.createUserForm.value.firstName,
      last_name: this.createUserForm.value.lastName,
      email: this.createUserForm.value.email,
      password: this.createUserForm.value.password,
      user_role_id: this.roleId
    }
    this.userService.postUserBySuperAdmin(userData).subscribe(user => {
     if (user) {
       this.createPopup.close();
       this.router.navigate(['/users']);
     }
    });
  }

  private initForm() {
    this.createUserForm = new FormGroup({
      'firstName': new FormControl(''),
      'lastName': new FormControl(''),
      'email': new FormControl('', Validators.compose([Validators.required, CustomValidators.emailValidator])),
      'phone_number': new FormControl(''),
      'password': new FormControl('', Validators.required),
      'membershipPlan': new FormControl(''),
      'user_role_id': new FormControl('')
    });
  }

  changeRole(role) {
    const userRole = this.userRoles.filter((uRole: UserRole) => role === uRole.name);
    this.userRole = userRole[0].name;
    this.role = userRole[0].short_name;
    this.roleId = userRole[0].user_role_id;
    this.changedRole = true;
    this.changeUserRole = false;
  }

  getRolesArray() {
    this.userService.getUserRoles().subscribe(roles => {
      this.userRoles = roles;
      this.selectedRole = this.userRoles[0].name;
      this.roleId = this.userRoles[0].user_role_id;
    });
  }


  ngOnInit() {
    this.getRolesArray();
    this.initForm();
  }

}
