"use strict";

import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PageTitleService} from '../../core/page-title/page-title.service';
import {fadeInAnimation} from "../../core/route-animation/route.animation";
import {UserModel} from "../../models/user.model";
import {UsersService} from "../../services/users.service";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ElementRef} from '@angular/core';
import {fromEvent} from "rxjs";
import {debounceTime} from 'rxjs/internal/operators/debounceTime';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {map, filter} from "rxjs/operators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateUserComponent} from "../create-user/create-user.component";

@Component({
    selector: 'ms-userlist',
    templateUrl: './userlist-component.html',
    styleUrls: ['./userlist-component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [fadeInAnimation]
})
export class UserListComponent implements OnInit {
    users: UserModel[] = [];
    userLogo = '../../../assets/img/noavatar.png';
    config: any;
    searchUserForm: FormGroup;
    errorMessage = false;
    isSearching: boolean;
    isLoading: boolean;
    createPopup: MatDialogRef<CreateUserComponent>;
    @ViewChild('searchUser') searchUser: ElementRef;

    constructor(private pageTitleService: PageTitleService,
                public userService: UsersService,
                public dialog: MatDialog,
                public router: Router) {
        this.config = {
            itemsPerPage: 15,
            currentPage: 1,
            totalItems: this.users.length
        };
        this.isSearching = false;
        this.isLoading = true;
    }
    ngOnInit() {
        this.pageTitleService.setTitle("User List");
        this.initSearchUserForm();
        this.getUsers();
        fromEvent(this.searchUser.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            }),
            filter(res => res.length > 2),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((text: string) => {
            this.isSearching = true;
            this.userService.searchUsers(text).subscribe((res) => {
                this.isSearching = false;
                this.users = res.result;
                this.errorMessage = this.users.length === 0;
            }, (err) => {
                this.errorMessage = true;
            });

        });
    }

    createUser() {
        this.createPopup = this.dialog.open(CreateUserComponent, {
            disableClose: false
        });

        this.createPopup.afterClosed().subscribe(result => {
            this.createPopup = null;
        });
    }
    getUsers() {
        this.errorMessage = false;
        this.userService.getUsers().subscribe(users => {
            this.isLoading = false;
            this.users = Object.keys(users).map(key => users[key]);
        });
    }

    onShowUserInfo(user: UserModel) {
        this.userService.sendUserData(user);
        this.router.navigate(['/users', user.user_id]);
    }

    searchUsers(value) {
        this.userService.searchUsers(value).subscribe(users => {
                this.errorMessage = false;
                const res = users.result;
                this.users = res;
            },
            error => {
                this.errorMessage = true;
            })
    }

    pageChanged(event) {
        this.config.currentPage = event;
    }

    private initSearchUserForm() {
        this.searchUserForm = new FormGroup({
            searchValue: new FormControl('', Validators.required)
        });
        this.searchUserForm.valueChanges.subscribe(val => {
            if (this.searchUserForm.value.searchValue === '') {
                this.getUsers();
            }
        })
    }
}



