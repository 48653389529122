import {Component, OnInit} from '@angular/core';
import {UsersService} from '../services/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../services/login.service';
import {CookieService} from "ngx-cookie-service";

@Component({
    selector: 'ms-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    updatePasswordForm: FormGroup;
    password: string;
    user: any;
    token: string;
    fingerprint: string

    constructor(private router: Router,
                private loginService: LoginService,
                private cookie: CookieService,
                private service: UsersService,
                private route: ActivatedRoute) {
    }

    updatePassword() {
        const reset_token = this.route.snapshot.queryParams.reset_token;
        const password = this.updatePasswordForm.value.password;
        const data = {
            reset_token,
            fingerprint: this.fingerprint,
            password
        }
        this.service.updateUserPassword(data).subscribe(returnedData => {
            if (returnedData) {
                this.router.navigate(['/login']);
            }
        });
    }

    private initForm() {

        const password = '';
        const passwordConfirm = '';

        this.updatePasswordForm = new FormGroup({
            'password': new FormControl(password, Validators.required),
            'passwordConfirm': new FormControl(passwordConfirm, Validators.required),
        }, passwordMatchValidator);

        function passwordMatchValidator(g: FormGroup) {
            return g.get('password').value === g.get('passwordConfirm').value
                ? null : {'mismatch': true};
        }
    }

    ngOnInit() {
        this.initForm();
        this.loginService.generateHashedFingerprint().then(result => {
            this.fingerprint = result;
        });
    }
}
