import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-spinner',
  template: ' <mat-spinner diameter="40" style="margin:40vh auto;"></mat-spinner>',
 styles: [`:host ::ng-deep .mat-progress-spinner circle, .mat-spinner circle {
   stroke: #29BA9C;
 }`]
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
