import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {OfferService} from "../../services/offer.service";
import {OfferModel} from "../../models/offer.model";
import {Subscription} from "rxjs";
import {LocalStorageService} from "../../services/local-storage.service";
import {ListingsService} from "../../services/listings.service";

@Component({
    selector: 'ms-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit, OnDestroy {
    private offerSubscription: Subscription;

    offer: OfferModel;

    constructor(public dialogRef: MatDialogRef<OfferDetailsComponent>,
                private offerService: OfferService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService) {
    }

    ngOnInit() {
        this.offerSubscription = this.offerService.getOfferDetail().subscribe(offer => {
            this.offer = offer.data;
        });
    }

    acceptOffer(offer) {
// SEND THE INFO TO MLS THAT THIS OFFER WAS ACCEPTED
        offer.accepted_at = new Date();
        this.offerSubscription.add(this.offerService.updateOffer(offer).subscribe(result => {
            this.offerService.sendUpdatedOfferDetail(offer);
            this.dialogRef.close();
        }));
    }

    declineOffer(offer) {
        offer.accepted_at = null;
        this.offerSubscription.add(this.offerService.deleteOffer(offer).subscribe(result => {
            this.offerService.sendUpdatedOfferDetail(offer);
            this.localStorage.setItem('stage', 'offer-stage');
            this.dialogRef.close();
        }));
    }

    ngOnDestroy(): void {
        this.offerSubscription.unsubscribe();
    }

}
