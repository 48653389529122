import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocalStorageService} from "../../../services/local-storage.service";
import {CallsService} from "../../../services/calls.service";
import {CallModel} from "../../../models/call.model";

@Component({
    selector: 'ms-calls-popup',
    templateUrl: './calls-popup.component.html',
    styleUrls: ['./calls-popup.component.scss']
})
export class CallsPopupComponent implements OnInit {

    callsForm: FormGroup;
    user_id: string;
    error_message = true;
    mask: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(public dialogRef: MatDialogRef<CallsPopupComponent>,
                public localStorage: LocalStorageService,
                public callsService: CallsService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.callsForm = new FormGroup({
            'created_at': new FormControl('', Validators.required),
            'name': new FormControl('', Validators.required),
            'phone': new FormControl('', Validators.required),
            'notes': new FormControl('', Validators.required),
            'smd_listing_id': new FormControl(this.localStorage.getItem('listing_id'))
        });
    }

    sendCallsData(): void {
        const now = new Date(this.callsForm.value.created_at).toISOString()
        this.callsForm.value.created_at = now;
        this.callsService.postListingCall(this.callsForm.value).subscribe((call: CallModel) => {
            this.callsService.sendCallData(call);
        })
    }

    addCall() {
        if (this.callsForm.valid) {
            this.sendCallsData();
            this.dialogRef.close();
        } else {
            this.error_message = false;
        }
    }

}
