import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TasksService} from "../services/tasks.service";
import {Router} from "@angular/router";
import {TodosModel} from "../checklist/todos.model";
import {Subscription} from "rxjs";
import {LocalStorageService} from "../services/local-storage.service";

@Component({
    selector: 'ms-task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit, OnDestroy {
    @Input() todo: TodosModel;
    todos: TodosModel[];
    index: number;
    activeTab: string;
    isLoading: boolean;
    private taskSubscription: Subscription = new Subscription();

    constructor(public taskService: TasksService,
                public localStorage: LocalStorageService,
                public router: Router) {
        this.todos = [];
        this.activeTab = 'escrow-stage';
        this.isLoading = true;
        this.getTasks();
    }

    getTasks() {
        this.taskSubscription.add(this.taskService.getTodos().subscribe(todos => {
            if (todos) {
                this.isLoading = false;
                this.todos = todos;
                this.getTaskInd();
            }
        }));
    }

    getTaskInd() {
        this.taskSubscription.add(this.taskService.getTaskIndex().subscribe(i => {
            this.index = i.index;
            this.todo = this.todos[this.index];
        }));
    }

    completeTask(todo) {
        todo.completed_at = todo.completed_at ? null : new Date().toISOString();
        this.taskService.updateTodo(todo).subscribe(res => {
            this.showNextTask();
        })
    }

    closeTask() {
        this.router.navigate([`/${this.activeTab}`])
    }

    showPrevTask() {
        this.index = (this.index > 0) && (this.index < this.todos.length) ? this.index = this.index - 1 : this.index = this.todos.length - 1;
        this.todo = this.todos[this.index];
        this.router.navigate([`/${this.activeTab}/tasks/${this.todo.todo_id}`])
    }

    showNextTask() {
        this.index = this.index < this.todos.length - 1 ? this.index = this.index + 1 : this.index = 0;
        this.todo = this.todos[this.index];
        this.router.navigate([`/${this.activeTab}/tasks/${this.todo.todo_id}`])
    }


    ngOnInit() {
    }

    ngOnDestroy() {
        this.taskSubscription.unsubscribe();
    }

}
