import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInAnimation} from "../core/route-animation/route.animation";

@Component({
  selector: 'ms-transaction-choice',
  templateUrl: './transaction-choice.component.html',
  styleUrls: ['./transaction-choice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [ fadeInAnimation ]
})
export class TransactionChoiceComponent implements OnInit {
    rows = [];

    columns = [
        { name: 'Address' },
        { name: 'Bed' },
        { name: 'Bath' },
        { name: 'Current Price' },
        { name: 'Status' }
    ];

    constructor() {
        this.fetch((data) => {
            this.rows = data;
        });
    }

    ngOnInit() {
    }

    fetch(cb) {
        const req = new XMLHttpRequest();
        req.open('GET', `assets/data/transitions.json`);

        req.onload = () => {
            const data = JSON.parse(req.response);
            console.log(data);
            cb(data);
        };

        req.send();
    }

}
