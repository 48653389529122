import {Component, Input, OnInit} from '@angular/core';
import {MapsAPILoader} from "@agm/core";
import {MatDialog, MatDialogRef} from "@angular/material";
import {HousesService} from "../../services/houses.service";
import {NewOnMarketPopupComponent} from "./new-on-market-popup/new-on-market-popup.component";
import {HouseModel} from "../../models/house.model";
import {LocalStorageService} from "../../services/local-storage.service";
import {ListingsService} from "../../services/listings.service";

@Component({
    selector: 'ms-new-on-market',
    templateUrl: './new-on-market.component.html',
    styleUrls: ['./new-on-market.component.scss']
})
export class NewOnMarketComponent implements OnInit {

    housesArr: HouseModel[];
    newOnMarketPopup: MatDialogRef<NewOnMarketPopupComponent>;
    @Input() userProperty;

    constructor(public mapsApiLoader: MapsAPILoader,
                public housesService: HousesService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService,
                public dialog: MatDialog) {
        this.housesArr = [];
    }

    ngOnInit() {
        const similarProperty = this.housesService.getSimilarProperties();
        this.housesService.getSimilarHouses(similarProperty).subscribe(houses => {
            this.housesArr = houses;
        })
    }

    openNewHousesMap() {
        this.newOnMarketPopup = this.dialog.open(NewOnMarketPopupComponent, {
            disableClose: false
        });
        this.newOnMarketPopup.componentInstance.userProperty = this.userProperty;

        this.newOnMarketPopup.afterClosed().subscribe(result => {
            this.newOnMarketPopup = null;
        });
    }
}
