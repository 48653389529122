'use strict';

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoginPageComponent} from './login-page/login-page.component'
import {ChecklistComponent} from './checklist/checklist.component';
import {MyPropertiesComponent} from './my-properties/my-properties.component';
import {PropertyDetailComponent} from './my-properties/property-detail/property-detail.component';
import {SellPropertyComponent} from './my-properties/sell-property/sell-property.component';
import {RegisterPageComponent} from './register-page/register-page.component';
import {TransactionChoiceComponent} from "./transaction-choice/transaction-choice.component";
import {ChangePasswordComponent} from './change-password/change-password.component';
import {MarketingStageControlPanelComponent} from "./marketing-stage-control-panel/marketing-stage-control-panel.component";
import {OfferStageComponent} from "./offer-stage/offer-stage.component";
import {UserProfileComponent} from "./users/user-profile/user-profile.component";
import {TransactionsComponent} from "./users/transactions/transactions.component";
import {UserPropertyComponent} from "./users/user-property/user-property.component";
import {PrepareStageComponent} from "./prepare-stage/prepare-stage.component";
import {EscrowStageComponent} from "./escrow-stage/escrow-stage.component";
import {CloseStageComponent} from "./close-stage/close-stage.component";
import {TaskComponent} from "./task/task.component";
import {AllListingsComponent} from "./all-listings/all-listings.component";
import {AllListingsGuard} from "./all-listings/all-listings.guard";
import {UserListComponent} from "./users/user-list/userlist.component";
import {UserlistGuard} from "./users/user-list/userlist.guard";
import {LoginGuard} from "./login-page/login.guard";
import {NavigationGuard} from "./main-page/navigation.guard";

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'restore-password',
        component: ChangePasswordComponent,
        children: [
            {
                path: ':token', component: ChangePasswordComponent
            }
        ]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'register',
        component: RegisterPageComponent,
    },
    {
        path: '', component: MainPageComponent,
        canActivate: [LoginGuard],
        data: {
            breadcrumb: 'Home',
        },
        children: [
            // {path: '', redirectTo: 'prepare-stage', pathMatch: 'full'},
            {path: 'todo', component: ChecklistComponent},
            {path: 'my-properties', component: MyPropertiesComponent},
            {path: 'my-properties/:id', component: PropertyDetailComponent},
            {
                path: 'mls',
                canActivate: [NavigationGuard],
                data: {
                    breadcrumb: 'MLS',
                },
                children: [
                    {
                        path: '',
                        component: SellPropertyComponent,
                    },
                    {
                        path: 'tasks/:id', component: TaskComponent,
                        data: {
                            breadcrumb: 'Task',
                        },
                    }
                ],
            },
            {path: 'profile', component: UserProfileComponent},
            {path: 'transactions', component: TransactionsComponent},
            {path: 'property', component: UserPropertyComponent},
            {path: 'transaction-choice', component: TransactionChoiceComponent},
            {
                path: 'prepare-stage',
                data: {
                    breadcrumb: 'Prepare',
                },
                component: PrepareStageComponent,
                canActivate: [NavigationGuard],
            },
            {
                path: 'marketing-stage-control-panel',
                component: MarketingStageControlPanelComponent,
                data: {
                    breadcrumb: 'Market',
                },
                canActivate: [NavigationGuard],
            },
            {
                path: 'offer-stage', component: OfferStageComponent,
                data: {
                    breadcrumb: 'Offer',
                },
                canActivate: [NavigationGuard],
            },
            {
                path: 'escrow-stage',
                data: {
                    breadcrumb: 'Escrow',
                },
                canActivate: [NavigationGuard],
                children: [
                    {
                        path: '',
                        component: EscrowStageComponent,
                    },
                    {
                        path: 'tasks/:id', component: TaskComponent,
                        data: {
                            breadcrumb: 'Task',
                        },
                    }
                ],
            },
            {
                path: 'close-stage', component: CloseStageComponent,
                data: {
                    breadcrumb: 'Close',
                },
                canActivate: [NavigationGuard],
            },
            {
                path: 'all-listings',
                children: [
                    {
                        path: '',
                        component: AllListingsComponent,
                        canActivate: [AllListingsGuard],
                    },
                    {
                        path: ':userId',
                        component: AllListingsComponent,
                    }
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        component: UserListComponent,
                        canActivate: [UserlistGuard]
                    },
                    {
                        path: ':userId',
                        component: UserProfileComponent
                    }
                ]
            }

        ]
    },
    {path: '**', component: LoginPageComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule {
}
