import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {OfferService} from "../../../services/offer.service";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'ms-popup-offer-edit',
    templateUrl: './popup-offer-edit.component.html',
    styleUrls: ['./popup-offer-edit.component.scss']
})
export class PopupOfferEditComponent implements OnInit {
    editOfferForm: FormGroup;
    @Input() personName = '';
    @Input() date = '';
    @Input() realtCompanyName = '';

    constructor(public dialogRef: MatDialogRef<PopupOfferEditComponent>,
                private offerService: OfferService) {
    }

    ngOnInit() {
        let value = this.offerService.getOfferEditData().getValue();

        if (value) {
            this.personName = value.data.personName;
            this.date = value.data.date;
            this.realtCompanyName = value.data.realtCompanyName;
            this.editOfferForm = new FormGroup({
                'personName': new FormControl(this.personName),
                'date': new FormControl(new Date(this.date)),
                'realtCompanyName': new FormControl(this.realtCompanyName)
            });
        }
    }

    formatDate(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        date = mm + '/' + dd + '/' + yy;
        return date
    }

    onEditOffer() {
        this.editOfferForm.value.date = this.formatDate(this.editOfferForm.value.date);
        this.offerService.sendOfferEditData(this.editOfferForm.value);
        this.dialogRef.close();
    }

}
