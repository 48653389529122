import {Component, Input, OnInit} from '@angular/core';
import {UsersService} from "../../../services/users.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../shared/CustomValidation";
import {UserModel, UserRole} from "../../../models/user.model";
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
    selector: 'ms-update-user-popup',
    templateUrl: './update-user-popup.component.html',
    styleUrls: ['./update-user-popup.component.scss']
})
export class UpdateUserPopupComponent implements OnInit {
    updateUserForm: FormGroup;
    user: UserModel;
    isAdmin = false;
    changeUserRole = false;
    userRole = '';
    role: string;
    roleData: UserRole;
    selectedRole = '';
    userRoles: UserRole[];
    changedRole = false;
    mask: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(public userService: UsersService,
                public router: Router,
                public cookieService: CookieService,
                public localStorage: LocalStorageService,
                public updatePopup: MatDialogRef<UpdateUserPopupComponent>) {
    }

    updateUser() {
        const updatedData = {
            first_name: this.updateUserForm.value.firstName,
            last_name: this.updateUserForm.value.lastName,
            email: this.updateUserForm.value.email,
            phone_number: this.updateUserForm.value.phone_number,
            membership_plan: this.updateUserForm.value.membershipPlan,
        }
        if (this.changedRole) {
            const userRole = this.userRoles.filter((uRole: UserRole) => this.role === uRole.short_name);
            updatedData['user_role_id'] = userRole[0].user_role_id;
        }
        this.userService.updateUser(this.user.user_id, updatedData).subscribe(user => {
            this.user = user;
            this.userService.sendUserEditedData(this.user);
        });
        this.updatePopup.close();
    }

    private initForm() {
        this.updateUserForm = new FormGroup({
            'firstName': new FormControl(this.user.first_name),
            'lastName': new FormControl(this.user.last_name),
            'email': new FormControl(this.user.email, Validators.compose([Validators.required, CustomValidators.emailValidator])),
            'phone_number': new FormControl(this.user.phone_number),
            'membershipPlan': new FormControl(this.user.membership_plan),
            'role': new FormControl(this.role)
        });
    }

    changeRole(role) {
        const userRole = this.userRoles.filter((uRole: UserRole) => role === uRole.name);
        this.userRole = userRole[0].name;
        this.role = userRole[0].short_name;
        this.changedRole = true;
        this.changeUserRole = false;
    }

    getRolesArray() {
        this.userService.getUserRoles().subscribe(roles => {
            this.userRoles = roles;
            this.userService.getUserRole(this.user.user_role_id).subscribe(roleData => {
                this.role = roleData.short_name;
                const selected = this.userRoles.filter((userRole: UserRole) => this.role === userRole.short_name);
                this.selectedRole = selected[0].name;
            })
        });
    }


    ngOnInit() {
        this.userService.getUserData().subscribe(user => {
            this.user = user.data;
        });
        if (this.router.url === '/profile') {
            this.isAdmin = false;
            this.roleData = this.localStorage.getRoleData();
            this.role = this.roleData.name;
        } else {
            this.getRolesArray();
        }
        this.isAdmin = this.router.url !== '/profile';
        this.initForm();
    }

}
