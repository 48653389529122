import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {ListingsService} from "../services/listings.service";

@Component({
  selector: 'ms-edit-listing',
  templateUrl: './edit-listing.component.html',
  styleUrls: ['./edit-listing.component.scss']
})

export class EditListingComponent implements OnInit {
  @Input() public field;
  @Input() public value;
  constructor(public dialogRef: MatDialogRef<EditListingComponent>,
              public listingService: ListingsService) { }

  ngOnInit() {
  }

  update(field, value) {
  this.value = value
    const updatedField = { field, value }
    this.listingService.sendPropertyData(updatedField);
    this.dialogRef.close();
  }

}
