import { Component, OnInit } from '@angular/core';
import {ListingsService} from "../services/listings.service";
import {LocalStorageService} from "../services/local-storage.service";
import {TodosModel} from "../checklist/todos.model";
import {TasksService} from "../services/tasks.service";

@Component({
  selector: 'ms-escrow-stage',
  templateUrl: './escrow-stage.component.html',
  styleUrls: ['./escrow-stage.component.scss']
})
export class EscrowStageComponent implements OnInit {
  todos: TodosModel[];
  completeTodos: TodosModel[];
  incompleteTodos: TodosModel[];
  alertValue = 'todos';
  loading = true;
  todosStatusComplete = 'complete todos'
  constructor(public listingService: ListingsService,
              public localStorage: LocalStorageService,
              public tasksService: TasksService) {
    this.todos = [];
  }

  onSendTaskIndex(index, todos) {
    this.tasksService.sendTaskIndex(index);
    this.tasksService.sendActiveTab(5);
  }

  filterTodos() {
    this.completeTodos = this.todos.filter(todo => todo.completed_at != null);
    this.incompleteTodos = this.todos.filter(todo => todo.completed_at === null);
  }

  ngOnInit() {
    const id = this.localStorage.getListingId();
    this.tasksService.getTodoByListingId(id).subscribe(todos => {
      this.loading = false;
      if (todos.todos.length === 0) {
        this.todos = todos.templates;
       this.filterTodos();
      } else {
        this.todos = todos.todos;
        this.filterTodos();
      }
    })
  }

}
