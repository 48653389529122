'use strict';

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpModule, Http} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule, MatCardModule, MatNativeDateModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {FlexLayoutModule} from '@angular/flex-layout';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {DragulaModule} from 'ng2-dragula/ng2-dragula';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SortablejsModule, SortablejsOptions} from 'angular-sortablejs';
import {FileUploadModule} from 'ng2-file-upload/ng2-file-upload';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TreeModule} from 'angular-tree-component';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {EasyPieChartModule} from 'ng2modules-easypiechart';
import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate/ng2-translate';
import {CKEditorModule} from 'ng2-ckeditor';
import 'hammerjs';

import {ForceAppComponent} from './app.component';
import {RoutingModule} from './app-routing.module';
import {MenuToggleModule} from './core/menu/menu-toggle.module';
import {BreadcrumbsComponent} from './core/breadcrumb/breadcrumb.component';
import {BreadcrumbService} from './core/breadcrumb/breadcrumb.service';
import {PageTitleService} from './core/page-title/page-title.service';
import {D3ChartService} from './core/nvD3/nvD3.service';
import {nvD3} from './core/nvD3/nvD3.component';

import {UserProfileComponent} from './users/user-profile/user-profile.component';
import {UserListComponent} from './users/user-list/userlist.component';

import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';

import {LoginPageComponent} from './login-page/login-page.component';
import {MainPageComponent} from './main-page/main-page.component';
import {ChecklistComponent} from './checklist/checklist.component';
import {MyPropertiesComponent} from './my-properties/my-properties.component';
import {PropertiesService} from './my-properties/properties.service';
import {PropertyComponent} from './my-properties/property/property.component';
import {PropertyDetailComponent} from './my-properties/property-detail/property-detail.component';
import {SellPropertyComponent} from './my-properties/sell-property/sell-property.component';
import {RegisterPageComponent} from './register-page/register-page.component';
import {HttpClientModule} from '@angular/common/http';
import {UsersService} from './services/users.service';
import {LoginService} from './services/login.service';
import {TransactionChoiceComponent} from './transaction-choice/transaction-choice.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {LocalStorageService} from './services/local-storage.service';
import { MarketingStageControlPanelComponent } from './marketing-stage-control-panel/marketing-stage-control-panel.component';
import { PopupTodoComponent } from './checklist/popup-todo/popup-todo.component';
import { ShowingFormComponent } from './marketing-stage-control-panel/showings-chart/showing-form/showing-form.component';
import {TextMaskModule} from "angular2-text-mask";
import {ListingsService} from "./services/listings.service";
import {NgbdSortableHeader, OfferStageComponent} from './offer-stage/offer-stage.component';
import { PopupOfferComponent } from './offer-stage/popup-offer/popup-offer.component';
import {OfferService} from "./services/offer.service";
import {ShowingService} from "./services/showing.service";
import { ShowingsHistoryComponent } from './marketing-stage-control-panel/showings-chart/showings-history/showings-history.component';
import { ViewsFormComponent } from './marketing-stage-control-panel/views-chart/views-form/views-form.component';
import { ViewsFormRealtorComponent } from './marketing-stage-control-panel/views-chart/views-form-realtor/views-form-realtor.component';
import { TransactionsComponent } from './users/transactions/transactions.component';
import { UserPropertyComponent } from './users/user-property/user-property.component';
import { PopupOfferEditComponent } from './offer-stage/popup-offer/popup-offer-edit/popup-offer-edit.component';
import {CommonModule} from "@angular/common";
import {CookieService} from "ngx-cookie-service/cookie-service/cookie.service";
import { CallsPopupComponent } from './marketing-stage-control-panel/calls-chart/calls-popup/calls-popup.component';
import { SimilarHousesMapComponent } from './marketing-stage-control-panel/similar-houses/similar-houses-map/similar-houses-map.component';
import { AcceptedOffersComponent } from './marketing-stage-control-panel/accepted-offers/accepted-offers.component';
import { AcceptedOffersPopupComponent } from './marketing-stage-control-panel/accepted-offers/accepted-offers-popup/accepted-offers-popup.component';
import { NewOnMarketComponent } from './marketing-stage-control-panel/new-on-market/new-on-market.component';
import { NewOnMarketPopupComponent } from './marketing-stage-control-panel/new-on-market/new-on-market-popup/new-on-market-popup.component';
import { SimilarHousesComponent } from './marketing-stage-control-panel/similar-houses/similar-houses.component';
import { MarketingStageWidgetComponent } from './marketing-stage-control-panel/marketing-stage-widget/marketing-stage-widget.component';
import { ShowingsChartComponent } from './marketing-stage-control-panel/showings-chart/showings-chart.component';
import { ViewsChartComponent } from './marketing-stage-control-panel/views-chart/views-chart.component';
import { CallsChartComponent } from './marketing-stage-control-panel/calls-chart/calls-chart.component';
import { WidgetPopupComponent } from './marketing-stage-control-panel/marketing-stage-widget/widget-popup/widget-popup.component';
import { WelcomePopupComponent } from './login-page/welcome-popup/welcome-popup.component';
import { TaskComponent } from './task/task.component';
import {WidgetService} from "./services/widget.service";
import { PrepareStageComponent } from './prepare-stage/prepare-stage.component';
import { EscrowStageComponent } from './escrow-stage/escrow-stage.component';
import { CloseStageComponent } from './close-stage/close-stage.component';
import { AllListingsComponent } from './all-listings/all-listings.component';
import { OfferDetailsComponent } from './offer-stage/offer-details/offer-details.component';
import { DeleteUserPopupComponent } from './users/user-profile/delete-user-popup/delete-user-popup.component';
import { UpdateUserPopupComponent } from './users/user-profile/update-user-popup/update-user-popup.component';
import {ReplaceNullPipe} from './shared/replaceNull.pipe';
import {NgxPaginationModule} from "ngx-pagination";
import { SpinnerComponent } from './spinner/spinner.component';
import { StageButtonsComponent } from './stage-buttons/stage-buttons.component';
import {BreadcrumbsModule} from "ng6-breadcrumbs";
import { EmptyAlertComponent } from './empty-alert/empty-alert.component';
import { EditListingComponent } from './edit-listing/edit-listing.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import {API_Key} from '../environments/environment';

export function createTranslateLoader(http: Http) {
    return new TranslateStaticLoader(http, 'assets/i18n', '.json');
}

const sortablejsConfig: SortablejsOptions = {
    animation: 300
};

@NgModule({
    imports: [
        NgbModule.forRoot(),
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        BreadcrumbsModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        RoutingModule,
        FlexLayoutModule,
        NgbModalModule.forRoot(),
        AgmCoreModule.forRoot({apiKey: API_Key.key,
            libraries: ['geometry']}),
        CKEditorModule,
        MatIconModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatTabsModule,
        MatListModule,
        MatInputModule,
        MatSelectModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        DragulaModule,
        SortablejsModule,
        FileUploadModule,
        NgxDatatableModule,
        MatProgressBarModule,
        NgxPaginationModule,
        TreeModule,
        ChartsModule,
        EasyPieChartModule,
        MatCheckboxModule,
        MatButtonModule,
        MenuToggleModule,
        HttpModule,
        HttpClientModule,
        TextMaskModule,
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [Http]
        }),
    ],
    declarations: [
        ForceAppComponent,
        UserListComponent,
        UserProfileComponent,
        ForgotPasswordComponent,
        BreadcrumbsComponent,
        nvD3,
        LoginPageComponent,
        MainPageComponent,
        ChecklistComponent,
        MyPropertiesComponent,
        PropertyComponent,
        PropertyDetailComponent,
        SellPropertyComponent,
        RegisterPageComponent,
        TransactionChoiceComponent,
        ChangePasswordComponent,
        MarketingStageControlPanelComponent,
        PopupTodoComponent,
        ShowingFormComponent,
        OfferStageComponent,
        PopupOfferComponent,
        ShowingsHistoryComponent,
        ViewsFormComponent,
        ViewsFormRealtorComponent,
        TransactionsComponent,
        UserPropertyComponent,
        PopupOfferEditComponent,
        CallsPopupComponent,
        SimilarHousesMapComponent,
        AcceptedOffersComponent,
        AcceptedOffersPopupComponent,
        NewOnMarketComponent,
        NewOnMarketPopupComponent,
        SimilarHousesComponent,
        MarketingStageWidgetComponent,
        ShowingsChartComponent,
        ViewsChartComponent,
        CallsChartComponent,
        WidgetPopupComponent,
        WelcomePopupComponent,
        PrepareStageComponent,
        EscrowStageComponent,
        CloseStageComponent,
        TaskComponent,
        AllListingsComponent,
        NgbdSortableHeader,
        OfferDetailsComponent,
        DeleteUserPopupComponent,
        UpdateUserPopupComponent,
        ReplaceNullPipe,
        SpinnerComponent,
        StageButtonsComponent,
        EmptyAlertComponent,
        EditListingComponent,
        CreateUserComponent
    ],
    entryComponents: [
        PopupTodoComponent,
        ShowingFormComponent,
        PopupOfferComponent,
        ViewsFormComponent,
        ViewsFormRealtorComponent,
        PopupOfferEditComponent,
        ShowingsHistoryComponent,
        CallsPopupComponent,
        SimilarHousesMapComponent,
        AcceptedOffersPopupComponent,
        NewOnMarketPopupComponent,
        WidgetPopupComponent,
        WelcomePopupComponent,
        TaskComponent,
        OfferDetailsComponent,
        DeleteUserPopupComponent,
        UpdateUserPopupComponent,
        TaskComponent,
        CreateUserComponent,
        EditListingComponent
    ],
    bootstrap: [ForceAppComponent],
    providers: [
        D3ChartService,
        BreadcrumbService,
        PageTitleService,
        PropertiesService,
        UsersService,
        LoginService,
		LocalStorageService,
        ListingsService,
        OfferService,
        ShowingService,
        GoogleMapsAPIWrapper,
        CookieService,
        WidgetService
	]
})
export class ForceAppModule {
}
