import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInAnimation} from "../core/route-animation/route.animation";
import {MatDialog, MatDialogRef} from "@angular/material";
import {PopupTodoComponent} from "./popup-todo/popup-todo.component";
import {TodosModel} from "./todos.model";
import {Subscription} from "rxjs";
import {TasksService} from "../services/tasks.service";

@Component({
    selector: 'ms-checklist',
    templateUrl: './checklist.component.html',
    styleUrls: ['./checklist.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        "[@fadeInAnimation]": 'true'
    },
    animations: [fadeInAnimation]
})
export class ChecklistComponent implements OnInit, OnDestroy {
    todos: TodosModel[];
    todoDialog: MatDialogRef<PopupTodoComponent>;
    private subscription: Subscription = new Subscription();

    constructor(
        public dialog: MatDialog,
        public taskService: TasksService) {
        this.todos = [];
    }

    ngOnInit() {
        this.getTodosTemplates();
    }

    getTodosTemplates() {
        this.taskService.getTemplates().subscribe(todos => {
            this.todos = todos;
        })
    }

    openDialog(todo) {
        this.todoDialog = this.dialog.open(PopupTodoComponent, {
            disableClose: false
        });
        this.todoDialog.componentInstance.todo = todo

        this.todoDialog.afterClosed().subscribe(result => {
                this.getTodosTemplates();
                this.todoDialog = null;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
