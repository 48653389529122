import {Injectable} from '@angular/core';
import {HouseModel} from "../models/house.model";
import {Observable, throwError} from "rxjs";
import {api_url} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {LocalStorageService} from "./local-storage.service";
import {ListingsService} from "./listings.service";

@Injectable({
    providedIn: 'root'
})
export class HousesService {
    similarProperty: object;
    private api_url = api_url.url;

    constructor(private httpClient: HttpClient,
                public localStorage: LocalStorageService,
                public listingService: ListingsService) {
    }

    getSimilarHouses(house): Observable<HouseModel[]> {
        return this.httpClient.get<HouseModel[]>
        (`${this.api_url}/rets/similar-sold?price=${house.price}&lat=${house.lat}&lon=${house.long}`,
            {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getNewHouses(house): Observable<HouseModel[]> {
        return this.httpClient.get<HouseModel[]>
        (`${this.api_url}/rets/new-on-market?price=${house.price}&lat=${house.lat}&lon=${house.long}`,
            {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getAcceptedOffers(house): Observable<HouseModel[]> {
        return this.httpClient.get<HouseModel[]>
        (`${this.api_url}/rets/accepted-offers?price=${house.price}&lat=${house.lat}&lon=${house.long}`,
            {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getSimilarProperties() {
        const userProperty = JSON.parse(localStorage.getItem('listing'));
           return this.similarProperty = {
                price: userProperty.listingprice,
                lat: userProperty.latitude,
                long: userProperty.longitude
            }
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
