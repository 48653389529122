export class TodosModel {
    constructor(
        public todo_id: string,
        public todo_detail: string,
        public time_limit: number,
        public start_at: string,
        public created_at: string,
        public name: string,
        public stage_id: number,
        public is_template?: boolean,
        public completed_at?: string,
        public detailed_video_url?: string,
        public documents?: Array<string>,
        public modified_at?: string,
        public order_id?: string,
        public smd_listing_id?: string,
        public summary_video_url?: string
    ) {
    }
}
