import {Component, OnDestroy, OnInit, EventEmitter, Output} from '@angular/core';
import {ListingsService} from "../services/listings.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "../services/local-storage.service";
import {Subscription} from "rxjs";
import {UsersService} from "../services/users.service";
import {SmdListingModel} from "../models/smd-listing.model";

@Component({
    selector: 'ms-all-listings',
    templateUrl: './all-listings.component.html',
    styleUrls: ['./all-listings.component.scss']
})
export class AllListingsComponent implements OnInit, OnDestroy {
    listingsForReview = [];
    role: string;
    userId: string;
    stage_id: number;
    noProperties: boolean;
    listings: SmdListingModel[];
    isLoading: boolean;
    alertValue = 'listings';
    unreviewedNumber = 0;
    private listingSubscription: Subscription = new Subscription();

    constructor(public listingsService: ListingsService,
                private router: Router,
                public usersService: UsersService,
                public localStorage: LocalStorageService) {
        this.noProperties = true;
        this.isLoading = true;
    }

    openPropertyInfo(id) {
            this.localStorage.setItem('listing_id', id);
            this.router.navigate(['/property'])
    }

    getUserProperties() {
        this.userId = this.usersService.getUserId();
        this.listingsService.getUserListings(this.userId).subscribe((listings: SmdListingModel[]) => {
            this.isLoading = false;
            this.listingsForReview = listings;
            this.noProperties = this.listingsForReview.length < 1;
        })
    }

    goToProperty(id) {
        this.listingsService.getStage(id).subscribe(list => {
            const stage = this.listingsService.getListingStage(list.stage_id);
            this.stage_id = list.stage_id;
            this.localStorage.setItem('stage', stage);
            this.localStorage.setItem('listing_id', id);
            this.localStorage.setItem('stage_id', this.stage_id);
            this.listingsService.getListing(id).subscribe(listing => {
                this.localStorage.setItem('listing', JSON.stringify(listing));
                this.router.navigate(['/' + stage], {queryParams: {stage: stage}});
            })
        })
    }

    getAllProperties() {
        this.listingSubscription.add(this.listingsService.getAllListings().subscribe((listings: SmdListingModel[]) => {
            this.isLoading = false;
            this.listingsForReview = listings;
            this.filterListings(listings);
            this.noProperties = this.listingsForReview.length < 1;
        }));
    }

    filterListings(array) {
        const newArr = array.filter(item => !item.enteredtomls);
        this.unreviewedNumber = newArr.length;
        this.listingsService.sendListingsNumber(this.unreviewedNumber);
    }

    addListing() {
        this.localStorage.setItem('stage', 'prepare-stage');
        this.router.navigate(['/prepare-stage']);
    }

    ngOnInit() {
        this.localStorage.removeItem('stage');
        this.localStorage.removeItem('listing_id');
        this.role = this.localStorage.getUserRole();
        if (this.role === 'super_admin') {
            this.getAllProperties();
        } else {
            this.getUserProperties();
        }
    }

    ngOnDestroy(): void {
        this.listingSubscription.unsubscribe();
    }
}
