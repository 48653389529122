import {
    Component,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {OfferModel} from "../models/offer.model";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OfferDetailsComponent} from "./offer-details/offer-details.component";
import {OfferService} from "../services/offer.service";
import {PopupOfferComponent} from "./popup-offer/popup-offer.component";
import {Subscription} from "rxjs";
import {ListingsService} from "../services/listings.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "../services/local-storage.service";

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {'asc': 'desc', 'desc': '', '': 'asc'};
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
    column: string;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate()'
    }
})

export class NgbdSortableHeader {

    @Input() sortable: string;
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({column: this.sortable, direction: this.direction});
    }
}

@Component({
    selector: 'ms-offer-stage',
    templateUrl: './offer-stage.component.html',
    styleUrls: ['./offer-stage.component.scss']
})

export class OfferStageComponent implements OnInit, OnDestroy {
    private offerSubscription: Subscription = new Subscription();
    listingOffers: OfferModel[] = [];
    offers = [];
    offerDetails: MatDialogRef<OfferDetailsComponent>;
    addOfferPopup: MatDialogRef<PopupOfferComponent>;
    offerIndex: number;
    alertValue = 'offers';
    hasActiveOffer = false;

    constructor(public dialog: MatDialog,
                public offerService: OfferService,
                private router: Router,
                public localStorage: LocalStorageService,
                public listingService: ListingsService) {
    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    onSort({column, direction}: SortEvent) {

        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        if (direction === '') {
            this.offers = this.listingOffers;
        } else {
            this.offers = [...this.listingOffers].sort((a, b) => {
                const res = compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }

    viewOfferDetails() {
        this.offerDetails = this.dialog.open(OfferDetailsComponent, {
            disableClose: false
        });

        this.offerDetails.afterClosed().subscribe(result => {
            this.offerSubscription.add(this.offerService.getUpdatedOfferDetail().subscribe(offer => {
                if (offer) {
                    this.getOffers();
                    this.checkActiveOffer();
                }
            }))
            this.offerDetails = null;
        });
    }

    sendOfferData(data, index) {
        this.offerIndex = index;
        this.offerService.sendOfferDetail(data);
        this.viewOfferDetails();
    }

    addOffer() {
        this.addOfferPopup = this.dialog.open(PopupOfferComponent, {
            disableClose: false
        });

        this.addOfferPopup.afterClosed().subscribe(result => {
            this.offerSubscription.add(this.offerService.getOfferData().subscribe(data => {
                if (data) {
                    this.getOffers();
                }
            }));
            this.addOfferPopup = null;
        });
    }

    checkActiveOffer() {
        this.hasActiveOffer = this.offers.find(offer => !!offer.accepted_at);
        if (this.hasActiveOffer) {
            this.localStorage.setItem('stage', 'escrow-stage');
            const listing = this.localStorage.returnListing();
            this.offerSubscription.add(this.listingService.goToTheNextStage(listing).subscribe(list => {
                this.offerSubscription.add(this.listingService.getStage(list.smd_listing_id).subscribe(stage => stage));
                this.router.navigate(['/escrow-stage'], { queryParams: { stage: 'escrow-stage' }});
            }))
        }
    }

    getOffers() {
        this.offerSubscription.add(this.offerService.getAllOffers().subscribe(offers => {
                this.listingOffers = offers;
                this.offers = offers;
                this.hasActiveOffer = this.offers.find(offer => !!offer.accepted_at);
            },
            error => console.log(error)));
    }

    ngOnInit() {
        this.getOffers();
    }

    ngOnDestroy(): void {
        this.offerSubscription.unsubscribe();
    }
}
