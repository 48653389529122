import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material";
import {ViewsFormRealtorComponent} from "./views-form-realtor/views-form-realtor.component";
import {ViewsFormComponent} from "./views-form/views-form.component";
import {ListingsService} from "../../services/listings.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {SmdListingModel} from "../../models/smd-listing.model";
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'ms-views-chart',
    templateUrl: './views-chart.component.html',
    styleUrls: ['./views-chart.component.scss']
})
export class ViewsChartComponent implements OnInit {
    public viewsOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true
    };
    viewsColors: Array<any> = [{
        backgroundColor: '#29BA9C',
        borderColor: '#29BA9C',
        pointBackgroundColor: 'rgba(41, 186, 156, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(41, 186, 156, 0.8)'
    }, {
        backgroundColor: '#E75F53',
        borderColor: 'rgba(231, 95, 83, 1)',
        pointBackgroundColor: 'rgba(231, 95, 83, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(231, 95, 83, 0.8)'
    }];
    public viewsType: string = 'bar';
    public viewsLegend: boolean = false;
    chartName = 'views';
    realtorViewsArray: Array<any>;
    zillowViewsArray: Array<any>;
    realtorViews: any;
    zillowViews: any;
    realtorDates: Array<any>;
    zillowDates: Array<any>;
    viewsLabels: Array<any>;
    viewsData: Array<any>;
    listing: SmdListingModel;
    zillowDialog: MatDialogRef<ViewsFormComponent>;
    realtorDialog: MatDialogRef<ViewsFormRealtorComponent>;
    @ViewChild(BaseChartDirective) private chart;

    constructor(public dialog: MatDialog, public listingService: ListingsService,
                public localStorage: LocalStorageService) {
        this.listing = JSON.parse(localStorage.getItem('listing'));
        this.viewsData = [
            {data: [], label: 'Zillow'},
            {data: [], label: 'Realtor'}
        ]
        this.viewsLabels = [];
        this.zillowViewsArray = [];
        this.realtorViewsArray = [];
    }

    ngOnInit() {
        this.viewsChart();
        this.viewsOptions = {
            scales: {
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true
                    },
                    ticks: {
                        min: 0,
                        callback: function (value) {
                            if (Math.floor(value) === value) {
                                return value;
                            }
                        }
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: true
                    }
                }]
            }
        }
    }
    forceChartRefresh() {
        this.viewsChart();
        setTimeout(() => {
            if (this.chart && this.chart.chart && this.chart.chart.config) {
                this.chart.chart.config.data.labels = this.viewsLabels;
                this.chart.chart.config.data.viewsData = this.viewsData;
                this.chart.chart.update();
            }
        });
    }

    openViewsDialog() {
        this.zillowDialog = this.dialog.open(ViewsFormComponent, {
            disableClose: false
        });

        this.zillowDialog.afterClosed().subscribe(result => {
            this.listing = JSON.parse(localStorage.getItem('listing'));
            this.forceChartRefresh();
            this.zillowDialog = null;
        });
    }

    openViewsRealtorDialog() {
        this.realtorDialog = this.dialog.open(ViewsFormRealtorComponent, {
            disableClose: false
        });

        this.realtorDialog.afterClosed().subscribe(result => {
            this.listing = JSON.parse(localStorage.getItem('listing'));
           this.forceChartRefresh();
            this.realtorDialog = null;
        });
    }

    getViews(comArr, dates, views) {
        const newViews = [];

        for (let i = 0; i < dates.length; i++) {
            for (let j = 0; j < comArr.length ; j++) {
                if (dates[i] === comArr[j]) {
                    newViews.push(views[i]);
                    i++;
                } else {
                    newViews.push(0);
                }
            }
        }
        return newViews;
    }

    getLablesArray(zillowArr, realtorArr) {
        const arr = [...zillowArr, ...realtorArr];
        const sortedArray = arr.sort((a, b) => a.localeCompare(b));
        const newArr = [];
        sortedArray.forEach(date => {
            const dateR = new Date(date);
            newArr.push(this.listingService.formatDate(dateR));
        });
        this.viewsLabels = newArr.filter((a, b) => newArr.indexOf(a) === b);
    }

    viewsChart() {
        let zillowArr = [];
        let realtArr = [];
        let zillViews = [];
        let realtViews = [];
        if (!this.listing.zillow_views) {
            this.zillowDates = [];
            this.zillowViewsArray = [];
        } else {
            const zilDates = Object.keys(this.listing.zillow_views);
            zillViews = (<any>Object).values(this.listing.zillow_views)
            zillowArr = zilDates;
            this.zillowDates = [];
            zilDates.forEach(date => {
                const dateZ = new Date(date);
                this.zillowDates.push(this.listingService.formatDate(dateZ));
            });
        }
        if (!this.listing.realtor_views) {
            this.realtorDates = [];
            this.realtorViewsArray = [];
        } else {
            const realtDates = Object.keys(this.listing.realtor_views);
            realtViews = (<any>Object).values(this.listing.realtor_views);
            realtArr = realtDates;
            this.realtorDates = [];
            realtDates.forEach(date => {
                const dateR = new Date(date);
                this.realtorDates.push(this.listingService.formatDate(dateR));
            });
        }

        this.getLablesArray(zillowArr, realtArr);
        this.zillowViewsArray = this.getViews(this.viewsLabels, this.zillowDates, zillViews);
        this.realtorViewsArray = this.getViews(this.viewsLabels, this.realtorDates, realtViews);
        this.viewsData = [
            {data: this.zillowViewsArray, label: 'Zillow'},
            {data: this.realtorViewsArray, label: 'Realtor'}
        ]
    }
}
