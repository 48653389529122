import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material";
import {LocalStorageService} from "../../../services/local-storage.service";
import {ListingsService} from "../../../services/listings.service";
import { DateTime } from "luxon";
import {SmdListingModel} from "../../../models/smd-listing.model";

@Component({
    selector: 'ms-views-form',
    templateUrl: './views-form.component.html',
    styleUrls: ['./views-form.component.scss']
})
export class ViewsFormComponent implements OnInit {

    viewFormZillow: FormGroup;
    user_id: any;
    error_message = true;
    listing: SmdListingModel;

    constructor(public zillowDialog: MatDialogRef<ViewsFormComponent>,
                public listingService: ListingsService,
                public localStorage: LocalStorageService) {
        this.listing = JSON.parse(localStorage.getItem('listing'));
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.viewFormZillow = new FormGroup({
            'date': new FormControl('', Validators.required),
            'views': new FormControl('', Validators.required),
        });
    }

    sendViewData(): void {
            const now = new Date(this.viewFormZillow.value.date).toISOString()
            const date = DateTime.fromISO(now).toISODate();
            const zilNum = this.viewFormZillow.value.views;
            const data = {[date]: zilNum}
            const endObj = {...this.listing.zillow_views, ...data};
            this.listing.zillow_views = endObj;
            this.listingService.updateListing(this.listing).subscribe(list => {
                this.localStorage.removeItem('listing');
                this.localStorage.setItem('listing', JSON.stringify(list));
                this.zillowDialog.close();
        })
    }

    addViewZillow() {
        if (this.viewFormZillow.valid) {
            this.sendViewData();
        } else {
            this.error_message = false;
        }
    }

}
