import {Component, OnDestroy, OnInit} from '@angular/core';
import {ListingsService} from "../../services/listings.service";
import {CookieService} from "ngx-cookie-service";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SmdListingModel} from "../../models/smd-listing.model";
import { MatDialogRef, MatDialog } from '@angular/material';
import { EditListingComponent } from '../../edit-listing/edit-listing.component';

export interface Status {
    viewValue: string;
}

@Component({
    selector: 'ms-user-property',
    templateUrl: './user-property.component.html',
    styleUrls: ['./user-property.component.scss']
})

export class UserPropertyComponent implements OnInit, OnDestroy {
    changeStatus: boolean = false;
    changePrice: boolean = false;
    property: any;
    listing: SmdListingModel;
    hasProperty: boolean = false;
    isAdmin: boolean = false;
    role: string;
    isLoading: boolean;
    requiredChanges = [];
    editedFields = [];
    updated: boolean;
    editListingPopup: MatDialogRef<EditListingComponent>;
    private propertySubscription: Subscription;
    status: Status[] = [
        {viewValue: 'For Sale'},
        {viewValue: 'Sold'}
    ];

    constructor(public listingService: ListingsService,
                private cookie: CookieService,
                public dialog: MatDialog,
                public localStorage: LocalStorageService,
                private router: Router) {
        this.isLoading = true;
        this.updated = false;
    }

    onChangeStatus(value) {
        this.changeStatus = false;
        this.listing.status = value;
        this.listingService.updateListing(this.listing).subscribe(res => res);
    }

    deleteListing(listing) {
        this.listingService.deleteListing(listing).subscribe(list => {
            this.router.navigate(['/all-listings']);
        });
    }

    editListing(field, value)  {
        this.editListingPopup = this.dialog.open(EditListingComponent, {
            disableClose: false
        });
        this.editListingPopup.componentInstance.field = field;
        this.editListingPopup.componentInstance.value = value;

        this.editListingPopup.afterClosed().subscribe(result => {
            this.listingService.getPropertyData().subscribe(data => {
                const getData = data.data;
                this.listing[getData.field] = getData.value;
                const requiredField = document.getElementById(getData.field);
                if (requiredField.classList.contains('required')) {
                    requiredField.classList.remove('required');
                    const filteredAry = this.listing.required_changes.filter(e => e !== getData.field);
                    this.listing.required_changes = filteredAry;
                        this.listing[getData.field] = getData.value;
                        this.editedFields.push(getData.field);
                        document.getElementById(getData.field).classList.add('edited');
                        this.updateRequired(getData);
                } else {
                    this.updateListing(getData);
                }
            });
            this.editListingPopup = null;
        });
    }

    keyDownFunction(event, val) {
        if (event.keyCode === 13) {
            this.listing.listingprice = val;
            this.changePrice = false;
            this.listingService.updateListing(this.listing).subscribe(res => res);
        }
    }

    enterToMLS(listing) {
        this.isLoading = true;
        listing.enteredtomls = true;
        this.propertySubscription = this.listingService.enterToMLS(listing).subscribe(list => {
            if (list) {
                this.isLoading = false;
                this.router.navigate(['/all-listings'])
            }
        })
    }

    requestChanges() {
        this.listing.required_changes = this.requiredChanges;
        this.listingService.updateListing(this.listing).subscribe(listing => {
            if (listing) {
                this.router.navigate(['/all-listings'])
            }
        })
    }

    handleClick(event, value) {
        const className = 'required';
        if (event.target.classList.contains(className)) {
            event.target.classList.remove(className);
            this.requiredChanges.splice(this.requiredChanges.indexOf(value), 1);
        } else {
            event.target.classList.add(className);
            this.requiredChanges.push(value);
        }
    }

    onGetPropertyData() {
        this.propertySubscription = this.listingService.getListing(this.localStorage.getItem('listing_id')).subscribe(listing => {
            if (listing) {
                this.hasProperty = true;
                this.listing = listing;
                if (this.listing.required_changes) {
                    this.requiredChanges = this.listing.required_changes;
                    setTimeout(() => {
                        for (let i = 0; i < this.listing.required_changes.length; i++) {
                            document.getElementById(this.listing.required_changes[i]).classList.add('required');
                        }
                    }, 1000);
                }
                if (this.listing.edited_fields) {
                    this.editedFields = this.listing.edited_fields;
                    setTimeout(() => {
                        for (let i = 0; i < this.listing.edited_fields.length; i++) {
                            document.getElementById(this.listing.edited_fields[i]).classList.add('edited');
                        }
                    }, 1000);
                }
                this.isLoading = false;
            } else {
                this.router.navigate(['/all-listings']);
            }
        })
    }

    updateRequired(data) {
        this.listing.edited_fields = this.editedFields;
        const fields = {
            [data.field]: data.value,
            edited_fields: this.listing.edited_fields,
            required_changes: this.listing.required_changes
        }
        this.requiredChanges = [];
        this.listingService.editRequired(this.listing, fields).subscribe(listing => listing)
    }

    updateListing(data) {
        this.listing[data.field] = data.value;
        this.listingService.updateListing(this.listing).subscribe(listing => {
            if (listing) {
                this.updated = true;
            }
        })
    }

    ngOnInit() {
        this.role = this.localStorage.getUserRole();
        if (this.role === 'super_admin') {
            this.isAdmin = true;
        }
        this.onGetPropertyData();
    }

    ngOnDestroy(): void {
        this.propertySubscription.unsubscribe();
    }
}
