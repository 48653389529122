import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../services/local-storage.service";
import {CookieService} from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(public localStorage: LocalStorageService,
                private cookieService: CookieService,
                private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const accessToken = this.cookieService.get('access_token');
        const refreshToken = this.cookieService.get('refresh_token');
        const roleData = JSON.parse(localStorage.getItem('role_data'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (!accessToken && !refreshToken || roleData === null || user === null) {
            this.router.navigate(['/login']);
        } else {
            return true;
        }
    }
}
