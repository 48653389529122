import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material";
import {LocalStorageService} from "../../../services/local-storage.service";
import {CustomValidators} from "../../../checklist/CustomValidation";
import {ShowingService} from "../../../services/showing.service";

@Component({
    selector: 'ms-showing-form',
    templateUrl: './showing-form.component.html',
    styleUrls: ['./showing-form.component.scss']
})
export class ShowingFormComponent implements OnInit {

    addShowingForm: FormGroup;
    user_id: any;
    error_message = true;
    mask: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(public dialogRef: MatDialogRef<ShowingFormComponent>,
                public localStorage: LocalStorageService,
                public showingService: ShowingService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.addShowingForm = new FormGroup({
            'showing_date': new FormControl('', Validators.required),
            'agentname': new FormControl('', Validators.required),
            'phone': new FormControl('', Validators.required),
            'comments': new FormControl('', Validators.required),
            'email': new FormControl('', Validators.compose([Validators.required, CustomValidators.emailValidator])),
            'top3': new FormControl(false),
            'offer': new FormControl(false),
            'possibleoffer': new FormControl(false),
            'smd_listing_id': new FormControl(this.localStorage.getItem('listing_id'))
        });
    }

    sendShowingData(): void {
        this.showingService.sendShowingData(this.addShowingForm.value);
        this.showingService.postListingShowing(this.addShowingForm.value).subscribe(showing => {
        })
    }

    addShowing() {
        if (this.addShowingForm.valid) {
            this.sendShowingData();
            this.dialogRef.close();
        } else {
            this.error_message = false;
        }
    }

}
