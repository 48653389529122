import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor() { }

  getHttpOptions() {
    return httpOptions;
  }
}
