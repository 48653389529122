import {Injectable} from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TodosModel} from "../checklist/todos.model";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {api_url} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    private taskId = new BehaviorSubject(undefined);
    stage = this.localStorage.getItem('stage');
    activeTab: number;
    private api_url = api_url.url;

    constructor(public localStorage: LocalStorageService,
                private httpClient: HttpClient) {
    }

    sendTaskIndex(data) {
        this.taskId.next({index: data});
    }

    getTaskIndex() {
        return this.taskId;
    }

    sendActiveTab(tab: number) {
        this.activeTab = tab;
    }

    getActiveTabTitle() {
        switch (this.activeTab) {
            case 2: return 'mls';
            case 3: return 'marketing-stage-control-panel';
            case 4: return 'offer-stage';
            case 5: return 'escrow-stage';
            case 6: return 'close-stage';
            default: return 'prepare-stage';
        }
    }

    getActiveTab() {
        return this.activeTab;
    }

    getTodoByListingId(id): Observable<any> {
        return this.httpClient.get<TodosModel[]>(`${this.api_url}/todo/listing/${id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    getTemplates() {
        return this.httpClient.get<TodosModel[]>(`${this.api_url}/todo/template`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    postTask(data: Observable<TodosModel>) {
        return this.httpClient.post<any>(`${this.api_url}/todo/template`,
            data, {withCredentials: true}
        )
    }

    deleteTemplate(id) {
        return this.httpClient
            .delete(`${this.api_url}/todo/template/${id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    getTodos() {
        const listing_id = this.localStorage.getItem('listing_id');
        return this.httpClient.get<TodosModel[]>(`${this.api_url}/todo/listing/${listing_id}`, {withCredentials: true})
            .pipe(
                map((todos: any) => {
                    if (todos.todos.length > 0) {
                        const todoArr = todos.todos.filter(todo => todo.stage_id === this.activeTab);
                        return todoArr;
                    } else {
                        const todoArr = todos.templates.filter(todo => todo.stage_id === this.activeTab);
                        return todoArr;
                    }
                }),
                catchError(this._handleError));
    }

    updateTemplate(todo): Observable<TodosModel> {
        return this.httpClient
            .put<TodosModel>(`${this.api_url}/todo/template/${todo.todo_id}`, todo, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    updateTodo(todo): Observable<TodosModel> {
        return this.httpClient
            .put<TodosModel>(`${this.api_url}/todo/${todo.todo_id}`, todo, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }
    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
