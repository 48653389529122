import {Injectable} from '@angular/core';
import {Http} from "@angular/http";

@Injectable()
export class PropertiesService {

    constructor(private http: Http) {}

    getProperties() {
        return this.http.get("/assets/properties.json")
            .map(res => res.json());

    }
}
