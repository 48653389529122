import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material";
import {WidgetPopupComponent} from "./widget-popup/widget-popup.component";
import {WidgetService} from "../../services/widget.service";
import {CookieService} from "ngx-cookie-service";
import {ListingsService} from "../../services/listings.service";

export interface Inventory {
    activeCount: number,
    soldCount: number
}

export interface MedianPrice {
    medianPrice: number,
    soldCount: number
}

@Component({
    selector: 'ms-marketing-stage-widget',
    templateUrl: './marketing-stage-widget.component.html',
    styleUrls: ['./marketing-stage-widget.component.scss']
})
export class MarketingStageWidgetComponent implements OnInit {
    title: string;
    text: string;
    dom: number = 0;
    inventory: Inventory;
    medianPriceData: MedianPrice;
    widgetPopup: MatDialogRef<WidgetPopupComponent>;

    constructor(public dialog: MatDialog,
                public widgetService: WidgetService,
                private cookie: CookieService,
                public listingService: ListingsService) {
    }

    getInventoryData() {
        this.widgetService.getInventory().subscribe((inventoryData: Inventory) => {
            this.inventory = inventoryData;
        }, error => {
            this.inventory = {
                activeCount: 0,
                soldCount: 0
            }
        });
    }

    getMedianPriceData() {
        this.widgetService.getMedianPrice().subscribe((mp: MedianPrice) => {
            this.medianPriceData = mp;
        }, error => {
            this.medianPriceData = {
                medianPrice: 0,
                soldCount: 0
            }
        });
    }

    getDOM() {
        this.widgetService.getDOM().subscribe(dom => {
            this.dom = dom;
        }, error => {
            this.dom = 0;
        });
    }

    ngOnInit() {
        this.getInventoryData();
        this.getMedianPriceData();
        this.getDOM();
    }

    openWidgetPopup(value) {
        const v = value.srcElement.className.value;
        const v_parent = value.srcElement.offsetParent.className;
        const widgetInfo = {
            title: this.title,
            text: this.text
        };
        if (v === 'widget col-md-2 countdown' || v_parent === 'widget col-md-2 countdown') {
            widgetInfo.title = 'Countdown';
            widgetInfo.text = 'Countdown widget text here...';
        } else if (v === 'widget col-md-2 dom' || v_parent === 'widget col-md-2 dom') {
            widgetInfo.title = 'DOM';
            widgetInfo.text = `Your property is ${this.dom} day(s) on market`;
        } else if (v === 'widget col-md-2 inventory' || v_parent === 'widget col-md-2 inventory') {
            widgetInfo.title = 'Inventory';
            widgetInfo.text = `Active count: ${this.inventory.activeCount}; Sold Count: ${this.inventory.soldCount}`;
        } else if (v === 'widget col-md-2 avg_dom' || v_parent === 'widget col-md-2 avg_dom') {
            widgetInfo.title = 'Avg Dom';
            widgetInfo.text = 'Avg Dom widget text here...';
        } else if (v === 'widget col-md-2 median_price' || v_parent === 'widget col-md-2 median_price') {
            widgetInfo.title = 'Median Price';
            widgetInfo.text = `${this.medianPriceData.medianPrice}`;
        } else if (v === 'widget col-md-2 sold_in_area' || v_parent === 'widget col-md-2 sold_in_area') {
            widgetInfo.title = 'Sold in Area';
            widgetInfo.text = 'Sold in Area widget text here...';
        }
        this.widgetService.sendData(widgetInfo);
        this.widgetPopup = this.dialog.open(WidgetPopupComponent, {
            disableClose: false
        });

        this.widgetPopup.afterClosed().subscribe(result => {
            this.widgetPopup = null;
        });
    }
}
