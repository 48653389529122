import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ms-marketing-stage-control-panel',
    templateUrl: './marketing-stage-control-panel.component.html',
    styleUrls: ['./marketing-stage-control-panel.component.scss']
})
export class MarketingStageControlPanelComponent implements OnInit {
    userProperty: object;

    constructor() {
        this.userProperty = JSON.parse(localStorage.getItem('listing'));
    }

    ngOnInit() {
    }
}
