import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../services/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class AllListingsGuard implements CanActivate {
    constructor(public localStorage: LocalStorageService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const role = this.localStorage.getUserRole();
        if (role === 'admin' ||
            role === 'super_admin') {
            return true;
        }
    }
}
