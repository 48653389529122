import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../services/users.service';
import {LoginService} from '../services/login.service';
import {CustomValidators} from '../shared/CustomValidation';
import {LocalStorageService} from '../services/local-storage.service';
import {CookieService} from "ngx-cookie-service/cookie-service/cookie.service";
import {UserModel, UserRole} from "../models/user.model";


@Component({
    selector: 'ms-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
    registerForm: FormGroup;
    type = 'password';
    mask: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    show = false;
    users = [];
    user: UserModel;
    roleData: UserRole;
    isEmailExists = false;
    currYear: number;
    fingerprint: string;

    constructor(private router: Router,
                private usersService: UsersService,
                private loginService: LoginService,
                public localStorage: LocalStorageService,
                private cookieService: CookieService) {

    }

    toggleShowHidePassword() {
        this.show = !this.show;
        if (this.show) {
            this.type = 'text';
        } else {
            this.type = 'password';
        }
    }

    ngOnInit() {
        this.loginService.generateHashedFingerprint().then(result => {
            this.fingerprint = result;
        });
        this.currYear = this.loginService.getCurrentYear();
        this.initForm();
    }

    private initForm() {
        this.registerForm = new FormGroup({
            'email': new FormControl('', Validators.compose([Validators.required, CustomValidators.emailValidator])),
            'first_name': new FormControl('', Validators.required),
            'last_name': new FormControl('', Validators.required),
            'password': new FormControl('', Validators.required),
        });
    }

    getUserRole() {
        this.usersService.getUserRole(this.user.user_role_id).subscribe(data => {
            this.roleData = data;
            localStorage.setItem('role_data', JSON.stringify(this.roleData));
            this.router.navigate(['/'], {queryParams: {firstVisit: true}});
        });
    }

    register() {
        const formData = {
            email: this.registerForm.value.email,
            password: this.registerForm.value.password,
            first_name: this.registerForm.value.first_name,
            last_name: this.registerForm.value.last_name,
            fingerprint: this.fingerprint
        }
        this.usersService.postUser(formData).subscribe(
            user => {
                this.user = user;
                this.localStorage.setItem('user', JSON.stringify(this.user));
                this.cookieService.set('remember', 'true');
               this.getUserRole();
            },
            error => {
                if (error.status === 403) {
                    this.isEmailExists = true;
                }
            }
        );
    }
}


