import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {LocalStorageService} from "../../../services/local-storage.service";
import {ShowingModel} from "../../../models/showing.model";
import {ShowingService} from "../../../services/showing.service";

@Component({
  selector: 'ms-showings-history',
  templateUrl: './showings-history.component.html',
  styleUrls: ['./showings-history.component.scss']
})
export class ShowingsHistoryComponent implements OnInit {
  showingData: ShowingModel[];

  constructor(public dialogRef2: MatDialogRef<ShowingsHistoryComponent>,
              public showingService: ShowingService,
              public localStorage: LocalStorageService) {
      this.showingData = [];
  }
    deleteShowing(id, i) {
        this.showingService.deleteShowing(id).subscribe(result => {
          this.showingData.splice(i, 1);
        })
    }

  ngOnInit() {
    this.showingService.getListingShowings(this.localStorage.getItem('listing_id')).subscribe(showing => {
      this.showingData = showing;
    })
  }

}
