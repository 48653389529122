
import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {catchError, map} from "rxjs/operators";
import {api_url} from "../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";
import {CallModel} from "../models/call.model";
import {HttpService} from "./http.service";
import {ShowingModel} from "../models/showing.model";

@Injectable()
export class ShowingService {
    private api_url = api_url.url;
    private showingData = new BehaviorSubject(undefined);

    constructor(private httpClient: HttpClient, public httpService: HttpService) { }

    getListingShowings(listing_id: string) {
        return this.httpClient.get<any>(`${this.api_url}/showing/listing/${listing_id}`, {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    postListingShowing(data: ShowingModel) {
        const httpOptions = this.httpService.getHttpOptions();
        return this.httpClient.post<CallModel>(`${this.api_url}/showing`,
            JSON.stringify(data), httpOptions
        )
    }

    deleteShowing(id) {
        return this.httpClient
            .delete(`${this.api_url}/showing/${id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    sendShowingData(data: Array<any>) {
        this.showingData.next({data: data});
    }

    getShowingData() {
        return this.showingData;
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
