import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import Fingerprint2 from 'fingerprintjs2';

@Injectable()
export class LoginService {

    private user = new BehaviorSubject(undefined);

    constructor() {
    }

    sendUser(email: string) {
        this.user.next({email: email});
    }

    getUser() {
        return this.user;
    }

    getCurrentYear() {
        return new Date().getFullYear();
    }

    generateHashedFingerprint() {
        const options = {
            excludes: {
                plugins: true,
                adBlock: true,
                screenResolution: true,
                availableScreenResolution: true,
            },
        };
        return Fingerprint2.getPromise(options)
            .then(components => {
                const values = components.map((component) => component.value);
                return Fingerprint2.x64hash128(values.join(''), 31);
            });
    };

    getCookie(name) {
        const cookieArr = document.cookie.split(';');

        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split('=');
            if (name === cookiePair[0].trim()) {
                const cookieDecode = decodeURIComponent(cookiePair[1]);
                return cookieDecode;
            }
        }
        return null;
    }
}
