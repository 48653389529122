import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChartsService {

    constructor() {
    }

    getChartColors() {
        return [{
            backgroundColor: 'rgba(41, 186, 156, 0.4)',
            borderColor: 'rgba(41, 186, 156, 0.2)',
            pointBackgroundColor: 'rgba(41, 186, 156, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(41, 186, 156, 0.8)'
        }]
    }

    getChartOptions() {
        return {
            responsive: true,
            scales: {
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true
                    },
                    ticks: {
                        min: 0,
                        callback: function (value) {
                            if (Math.floor(value) === value) {
                                return value;
                            }
                        }
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: true
                    }
                }]
            },
            tooltips: this.getTooltips()
        };
    }

    getTooltips() {
        const tooltips = {
                mode: 'label',
                callbacks: {
                    label: function (tooltipItem, data) {

                        const limit = 7;
                        const ind = tooltipItem.index;
                        if (data.datasets[0].arr.length <= limit) {

                            const newArr = data.datasets[0].arr[ind];
                            const i = newArr.length;
                            const oneDayTooltip = [];

                            for (let int = 0; int < i; int++) {
                                oneDayTooltip[int] = newArr[int].agentname + ' phone: ' + newArr[int].phone;
                            }
                            return oneDayTooltip;
                        } else {
                            let ind = tooltipItem.index;
                            const newDataArr = [];
                            let newData = [];
                            const dataArr = data.datasets[0].arr;
                            let i, j, temparray, chunk = 7;

                            const oneDayTooltip = [];
                            for (i = 0, j = dataArr.length; i < j; i += chunk) {
                                temparray = dataArr.slice(i, i + chunk);
                                newDataArr.push(temparray);
                            }
                            const indexNewArr = newDataArr[ind].length;
                            const arr = [];
                            for (let int = 0; int < indexNewArr; int++) {
                                newData = newDataArr[ind][int];
                                newData.forEach(person => {
                                    arr.push(person);
                                });
                            }
                            for (let a = 0; a < arr.length; a++) {
                                oneDayTooltip[a] = arr[a].agentname + ' phone: ' + arr[a].phone;
                            }
                            return oneDayTooltip;
                        }
                    }
                },
            }
        return tooltips;
    }
}
