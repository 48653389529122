import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router'
import {UsersService} from '../services/users.service';
import {LocalStorageService} from '../services/local-storage.service';
import {CookieService} from "ngx-cookie-service/cookie-service/cookie.service";
import {ListingsService} from "../services/listings.service";
import {UserModel, UserRole} from "../models/user.model";
import {LoginService} from "../services/login.service";

@Component({
    selector: 'ms-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {

    email: string;
    password = '';
    users: any;
    user: UserModel;
    remember = false;
    warning = false;
    currYear: number;
    fingerprint: string;
    roleData: UserRole;

    constructor(private router: Router,
                public service: UsersService,
                public loginService: LoginService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService,
                private cookieService: CookieService) {
    }

    getUserRole() {
        this.service.getUserRole(this.user.user_role_id).subscribe(data => {
            this.roleData = data;
            this.localStorage.setItem('role_data', JSON.stringify(this.roleData));
            this.goToTheMainPage();
        });
    }

    login(email, password) {
        const data = {
            email,
            password,
            fingerprint: this.fingerprint
        };
        this.service.login(data).subscribe(user => {
                this.user = user;
                this.getUserRole();
                const access_token = this.loginService.getCookie('access_token');
                const refresh_token = this.loginService.getCookie('refresh_token');
                this.cookieService.set('access_token', access_token);
                this.cookieService.set('refresh_token', refresh_token);
                this.cookieService.set('fingerprint', this.fingerprint);
                this.localStorage.setItem('user', JSON.stringify(this.user));
            },
            error => {
                this.warning = true;
            }
        );
    }

    goToTheMainPage() {
        if (this.user.email && this.remember === true) {
            this.cookieService.set('remember', 'true');
            this.router.navigate(['/']);
        } else if (this.user.email && this.remember === false) {
            this.cookieService.set('remember', 'false');
            this.router.navigate(['/']);
        } else {
            this.warning = true;
        }
    }

    ngOnInit() {
        this.currYear = this.loginService.getCurrentYear();
        if (this.cookieService.get('remember') === 'false') {
            this.email = '';
            this.password = '';
        } else {
            this.email = this.cookieService.get('email');
            this.password = this.cookieService.get('password');
        }
        this.loginService.generateHashedFingerprint().then(result => {
            this.fingerprint = result;
        });
    }
}
