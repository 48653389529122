import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {TasksService} from "../../services/tasks.service";

@Component({
    selector: 'ms-popup-todo',
    templateUrl: './popup-todo.component.html',
    styleUrls: ['./popup-todo.component.scss']
})
export class PopupTodoComponent implements OnInit, OnDestroy {

    addTodoForm: FormGroup;
    id: string;
    @Input() public todo;
    stages = [
        {'id': 1, 'value': 'Prepare Stage'},
        {'id': 2, 'value': 'MLS'},
        {'id': 3, 'value': 'Market Stage'},
        {'id': 4, 'value': 'Offer Stage'},
        {'id': 5, 'value': 'Escrow Stage'}
    ];
    private subscription: Subscription = new Subscription();

    constructor(public dialogRef: MatDialogRef<PopupTodoComponent>,
                public taskService: TasksService) {
    }

    ngOnInit() {
        this.initForm()
    }

    private initForm() {
        this.addTodoForm = new FormGroup({
            'name': new FormControl(this.todo.name),
            'todo_detail': new FormControl(this.todo.todo_detail),
            'stage_id': new FormControl(this.todo.stage_id),
            'time_limit': new FormControl(this.todo.time_limit),
            'summary_video_url': new FormControl(this.todo.summary_video_url)
        });
    }

    deleteTemplate(id) {
        this.taskService.deleteTemplate(id).subscribe(todo => {
            this.dialogRef.close();
        })
    }

    addTodo(todo) {
        const form = this.addTodoForm.value;
        this.todo.name = form.name;
        this.todo.todo_detail = form.todo_detail;
        this.todo.stage_id = form.stage_id;
        this.todo.time_limit = form.time_limit;
        this.todo.summary_video_url = form.summary_video_url;
        if (todo.todo_id) {
            this.taskService.updateTemplate(this.todo).subscribe(data => {
                this.dialogRef.close();
            });
        } else {
            this.todo.is_template = true;
            this.taskService.postTask(this.todo).subscribe(data => {
                this.dialogRef.close();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
