import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FileUploader} from 'ng2-file-upload/ng2-file-upload';
import {ListingsService} from "../../services/listings.service";
import {upload_url} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service/cookie-service/cookie.service";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {TasksService} from "../../services/tasks.service";
import {UsersService} from "../../services/users.service";
import {SmdListingModel} from "../../models/smd-listing.model";
import {TodosModel} from "../../checklist/todos.model";

@Component({
    selector: 'ms-sell-property',
    templateUrl: './sell-property.component.html',
    styleUrls: ['./sell-property.component.scss']
})

export class SellPropertyComponent implements OnInit {
    sellPropertyForm: FormGroup;
    smdListingObject: SmdListingModel;
    direction = [
        {name: 'North', control: 'north'},
        {name: 'South', control: 'south'},
        {name: 'East', control: 'east'},
        {name: 'West', control: 'west'},
    ];
    checked = false;
    isValid = true;
    todos: TodosModel[] = [];
    activeTab: number;
    currentYear = new Date().getFullYear();
    user_id: string;
    errorMessage = false;
    PropertySubtype1 = [
        {value: 'SFRS', viewValue: 'Single Family'},
        {value: 'COND', viewValue: 'Condo'},
        {value: 'PUDC', viewValue: 'PUD'},
        {value: 'MOBL', viewValue: 'Mobile Home'},
        {value: 'MNFG', viewValue: 'Manufactured Home'}
    ];
    PropertySubtype2 = [
        {value: 'SFRS', viewValue: 'Single Family'},
        {value: 'COND', viewValue: 'Condo'},
        {value: 'PUDC', viewValue: 'PUD'},
        {value: 'MOBL', viewValue: 'Mobile Home'},
        {value: 'MNFG', viewValue: 'Manufactured Home'}
    ];
    PropertySubtype3 = [
        {value: 'SFRS', viewValue: 'Single Family'},
        {value: 'COND', viewValue: 'Condo'},
        {value: 'PUDC', viewValue: 'PUD'},
        {value: 'MOBL', viewValue: 'Mobile Home'},
        {value: 'MNFG', viewValue: 'Manufactured Home'}
    ];
    transactions = [
        {value: 'Sale', viewValue: 'Sale'},
        {value: 'Lease', viewValue: 'Lease'}
    ];
    sublease = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    additionalAPN = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    dualAgency = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    scopeOfService = [
        {value: 'Entry Only', viewValue: 'Entry Only'},
        {value: 'Limited Service', viewValue: 'Limited Service'}
    ];
    publicViewable = [
        {value: true, viewValue: 'Yes'},
        {value: false, viewValue: 'No'}
    ];
    spa = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    ShowAddressToPublic = [
        {value: true, viewValue: 'Yes'},
        {value: false, viewValue: 'No'}
    ];
    ShowAVMonVOW = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    emailOffers = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    ShowCmntsonVOW = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    ageRestrictions = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    pool = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    supraLockbox = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    commissionType = [
        {value: 'Dollar', viewValue: 'Dollar'},
        {value: 'Percent', viewValue: 'Percent'}
    ];
    bedroomLevel = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'}
    ];
    stories = [
        {value: 1, viewValue: 'Single Story'},
        {value: 2, viewValue: 'Two Story'},
        {value: 3, viewValue: 'Split Level'}
    ];
    LivingRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    FormalDiningRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    EatingAreaDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    FamilyRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    GreatRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    MainBedroomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    sb = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    thb = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    fthb = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    ExtraRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    UtilityRoomDim = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    kitchen = [
        {value: 'Upper', viewValue: 'Upper'},
        {value: 'Lower', viewValue: 'Lower'},
        {value: 'Main', viewValue: 'Main'}
    ];
    shortSale = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    auction = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    reo = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    hardship = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    Association = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    mobileHome = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];
    pf = [
        {value: 'Yes', viewValue: 'Yes'},
        {value: 'No', viewValue: 'No'}
    ];

    public uploader: FileUploader = new FileUploader({url: upload_url.url});
    public hasBaseDropZoneOver: boolean = false;
    zoom: number;
    lat: number;
    long: number;

    constructor(public listingService: ListingsService,
                public localStorage: LocalStorageService,
                public cookieService: CookieService,
                public tasksService: TasksService,
                private router: Router,
                public userService: UsersService) {
        this.zoom = 10;
        this.lat = 36.735770243777175;
        this.long = -119.78563587636472;
    }

    ngOnInit() {
        this.activeTab = 2;
        this.tasksService.sendActiveTab(this.activeTab);
        this.tasksService.getTodos().subscribe(tasks => {
            this.todos = tasks;
        });
        this.initSellPropertyForm();
    }

    mapClicked($event) {
        this.lat = $event.coords.lat;
        this.long = $event.coords.lng;
        this.sellPropertyForm.addControl('latitude', new FormControl(this.lat));
        this.sellPropertyForm.addControl('longitude', new FormControl(this.long));
    }

    onSendTaskIndex(index) {
        this.tasksService.sendTaskIndex(index);
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    private initSellPropertyForm() {
        this.sellPropertyForm = new FormGroup({
            country: new FormControl('', Validators.required),
            streetnumber: new FormControl(null, Validators.required),
            streetname: new FormControl('', Validators.required),
            unit: new FormControl(''),
            city: new FormControl('', Validators.required),
            listingofficemlsid: new FormControl('', Validators.required),
            listingcoofficemlsid: new FormControl(''),
            streetsuffix: new FormControl(''),
            streetpostdirection: new FormControl(''),
            zipcode: new FormControl('', Validators.required),
            listingagentmlsid: new FormControl('', Validators.required),
            listingcoagentmlsid: new FormControl(''),

            // APN
            apn: new FormControl(''),
            apn_u_c: new FormControl(false),
            apn_n_a: new FormControl(false),
            apn_new: new FormControl(false),
            // DIRECTIONS
            directions: new FormControl(''),
            north: new FormControl(false),
            south: new FormControl(false),
            east: new FormControl(false),
            west: new FormControl(false),

            propertysubtype1: new FormControl('', Validators.required),
            propertysubtype2: new FormControl('', Validators.required),
            propertysubtype3: new FormControl('', Validators.required),

            transactiontype: new FormControl('', Validators.required),
            leasetypemonthly: new FormControl(''),
            availabledate: new FormControl(null),
            sublease: new FormControl('', Validators.required),
            // LEASE TYPE
            gross: new FormControl(false),
            net: new FormControl(false),
            otherLeasetype: new FormControl(false),
            subleaseexpiration: new FormControl(null),
            // ADDITIONAL APN
            additionalapn: new FormControl(''),
            apn2_1: new FormControl(''),
            apn2_2: new FormControl(''),
            apn2_3: new FormControl(''),
            apn3_1: new FormControl(''),
            apn3_2: new FormControl(''),
            apn3_3: new FormControl(''),
            apn4_1: new FormControl(''),
            apn4_2: new FormControl(''),
            apn4_3: new FormControl(''),
            apn5_1: new FormControl(''),
            apn5_2: new FormControl(''),
            apn5_3: new FormControl(''),

            crossstreetaddress: new FormControl('', Validators.required),
            listingprice: new FormControl(null, Validators.required),
            residual: new FormControl(''),
            resiscop: new FormControl(''),
            publishtointernet: new FormControl(null),
            showaddresstopublic: new FormControl(null, Validators.required),
            showavmonvow: new FormControl('', Validators.required),
            // AGREEMENT TYPE
            exclrighttosell: new FormControl(false),
            exclagency: new FormControl(false),
            open: new FormControl(false),

            showcmntsonvow: new FormControl('', Validators.required),
            commissionamount: new FormControl(null, Validators.required),
            commissiontype: new FormControl(''),
            commissioncomments: new FormControl(''),
            emailoffers: new FormControl('', Validators.required),
            email: new FormControl(''),
            // PROPERTY INFORMATION
            bedrooms: new FormControl(null, Validators.required),
            bathrooms: new FormControl(null, Validators.required),
            squarefootage: new FormControl(null, Validators.required),

            sftaxrecords: new FormControl(false),
            sfseller: new FormControl(false),
            sfappraiser: new FormControl(false),
            sfother: new FormControl(false),
            lstaxrecords: new FormControl(false),
            lseller: new FormControl(false),
            lsappraiser: new FormControl(false),
            lsother: new FormControl(false),
            lotmeasurement: new FormControl(''),

            lotsquarefootage: new FormControl(null),
            lotsizesqft: new FormControl(false),
            lotsizeacres: new FormControl(false),

            yearbuilt: new FormControl(null, Validators.required),
            newyb: new FormControl(false),
            underconstructionyb: new FormControl(false),
            unknownyb: new FormControl(false),

            listingdate: new FormControl(null, Validators.required),
            ownername: new FormControl(''),
            contingentexpirationdate: new FormControl(null, Validators.required),
            ownerphone: new FormControl(''),

            otowner: new FormControl(false),
            otvacant: new FormControl(false),
            otcaretaker: new FormControl(false),
            otenant: new FormControl(false),
            occupantname: new FormControl(''),
            phonetoshow: new FormControl(''),
            marketingremarks: new FormControl(''),
            agentremarks: new FormControl(''),
            excludedfromsale: new FormControl(''),
            directionstoproperty: new FormControl('', Validators.required),

            schooldistrict: new FormControl('', Validators.required),
            schoolname1: new FormControl('', Validators.required),
            schoolname2: new FormControl('', Validators.required),
            schoolname3: new FormControl('', Validators.required),
            schoolscomment: new FormControl(''),
            // PropertyType
            propertytyperanch: new FormControl(false),
            propertytypebungalow: new FormControl(false),
            propertytypecolonial: new FormControl(false),
            propertytypespanish: new FormControl(false),
            propertytypecontemporary: new FormControl(false),
            propertytypetudor: new FormControl(false),
            propertytypevictorian: new FormControl(false),
            propertytypemediterranean: new FormControl(false),
            propertytypetownhouse: new FormControl(false),
            propertytypecottage: new FormControl(false),
            propertytypecabin: new FormControl(false),
            propertytypemcm: new FormControl(false),

            resicnss: new FormControl(null),
            // REGION
            regionurban: new FormControl(false),
            regionrural: new FormControl(false),
            regionfoothill: new FormControl(false),
            regionmountain: new FormControl(false),

            area: new FormControl(null, Validators.required),
            resizone: new FormControl(''),
            tractbuilder: new FormControl(''),
            bedroomlevel: new FormControl('', Validators.required),
            resiltdm: new FormControl(''),
            resistor: new FormControl(null, Validators.required),

            publicwater: new FormControl(false),
            publicsewer: new FormControl(false),
            septictank: new FormControl(false),
            sharedwell: new FormControl(false),
            communitywell: new FormControl(false),
            waterwell: new FormControl(false),
            privatewell: new FormControl(false),

            publicutilities: new FormControl(false),
            propane: new FormControl(false),
            nogas: new FormControl(false),
            allelectric: new FormControl(false),
            generator: new FormControl(false),
            noelectric: new FormControl(false),

            groundfloor: new FormControl(false),
            secondfloor: new FormControl(false),
            splitLevel: new FormControl(false),
            endunit: new FormControl(false),
            inside: new FormControl(false),
            front: new FormControl(false),
            rear: new FormControl(false),

            // SPECIAL FEATURES
            isolatedbedroom: new FormControl(false),
            isolatedbathroom: new FormControl(false),
            studio: new FormControl(false),
            securitysystem: new FormControl(false),
            securitygate: new FormControl(false),
            builtincabinets: new FormControl(false),
            centralvac: new FormControl(false),

            skylight: new FormControl(false),
            watersoftener: new FormControl(false),
            waterfilter: new FormControl(false),
            wetdrybar: new FormControl(false),
            satellitedish: new FormControl(false),
            intercom: new FormControl(false),

            hightechcable: new FormControl(false),
            multiphoneline: new FormControl(false),
            privatetenniscourt: new FormControl(false),
            otherfeatures: new FormControl(false),
            horsesallowed: new FormControl(false),
            horsesetup: new FormControl(false),

            // DISABLED Features
            wheelchairaccess: new FormControl(false),
            limitedwheelchairaccess: new FormControl(false),
            ramp: new FormControl(false),
            widedoors: new FormControl(false),
            handrails: new FormControl(false),
            bathroomfixtures: new FormControl(false),
            wheelchairaccessibleshower: new FormControl(false),
            onelevelfloor: new FormControl(false),
            elevator: new FormControl(false),

            agerestrictions: new FormControl(''),
            supralockbox: new FormControl('', Validators.required),

            // Energy Efficiency
            dualpanewindows: new FormControl(false),
            triplepanewindows: new FormControl(false),
            solarelectricity: new FormControl(false),
            solarpoolheater: new FormControl(false),
            solarwaterheater: new FormControl(false),
            seer: new FormControl(false),
            tanklesswaterheater: new FormControl(false),
            otherenergyefficiency: new FormControl(false),
            // LeasedEquipment
            securitysystemee: new FormControl(false),
            propanetankee: new FormControl(false),
            watersofteneree: new FormControl(false),
            satellitedishee: new FormControl(false),
            solarpanelsee: new FormControl(false),

            // Leased Equipment
            resilbox: new FormControl(''),

            // Showing
            lockbox: new FormControl(false),
            keyinoffice: new FormControl(false),
            gooccupied: new FormControl(false),
            callfirst: new FormControl(false),
            leavemessage: new FormControl(false),
            go: new FormControl(false),
            confirmedappointmentonly: new FormControl(false),
            notice24hour: new FormControl(false),
            notsunday: new FormControl(false),
            sunset: new FormControl(false),
            callagent: new FormControl(false),
            doginside: new FormControl(false),
            dogoutside: new FormControl(false),
            restrictedhours: new FormControl(false),
            lockedgate: new FormControl(false),
            seasonalaccess: new FormControl(false),
            fourwheeldrive: new FormControl(false),
            cbscoderequired: new FormControl(false),
            vacantgoshow: new FormControl(false),
            alarmcheckbox: new FormControl(false),

            alarm: new FormControl(''),

            gatecode: new FormControl(''),
            showinginstr: new FormControl(''),
            resigpm: new FormControl(null),
            resiwlhp: new FormControl(null),

            // HeatingAndCooling
            centralheatandcool: new FormControl(false),
            centralheating: new FormControl(false),
            centralcooling: new FormControl(false),
            evaporativecool: new FormControl(false),
            floororwallunit: new FormControl(false),
            windoworwallac: new FormControl(false),
            wholehousefan: new FormControl(false),
            nonehc: new FormControl(false),

            // Foundation
            concretefoundation: new FormControl(false),
            woodsubfloor: new FormControl(false),
            concreteandwoodfoundation: new FormControl(false),

            // Flooring
            carpet: new FormControl(false),
            tile: new FormControl(false),
            vinyl: new FormControl(false),
            hardwood: new FormControl(false),
            otherflooring: new FormControl(false),

            numberoffireplaces: new FormControl(null),
            fireplacelocation: new FormControl(''),

            // Fireplace
            masonry: new FormControl(false),
            gasinsert: new FormControl(false),
            woodinsert: new FormControl(false),
            zeroclearance: new FormControl(false),
            freestanding: new FormControl(false),
            pellet: new FormControl(false),

            // Laundry
            insidelaundry: new FormControl(false),
            ingaragelaundry: new FormControl(false),
            inclosetlaundry: new FormControl(false),
            utilityroomlaundry: new FormControl(false),
            onporchlaundry: new FormControl(false),
            onupperlevellaundry: new FormControl(false),
            onlowerlevellaundry: new FormControl(false),
            gashookuplaundry: new FormControl(false),
            electrichookuplaundry: new FormControl(false),
            commonlaundry: new FormControl(false),
            coinoplaundry: new FormControl(false),
            nonelaundry: new FormControl(false),


            // BATH FEATURES
            resiwtrl: new FormControl(null),
            tubandshower: new FormControl(false),
            shower: new FormControl(false),
            tub: new FormControl(false),
            otherbathfeature: new FormControl(false),
            jettedtub: new FormControl(false),
            ovaltub: new FormControl(false),
            romantub: new FormControl(false),

            // KITCHEN
            fsrangeoven: new FormControl(false),
            builtinrangeoven: new FormControl(false),
            gasappliances: new FormControl(false),
            electricappliances: new FormControl(false),
            disposal: new FormControl(false),
            dishwasher: new FormControl(false),
            microwave: new FormControl(false),
            trashcompactor: new FormControl(false),
            eatingarea: new FormControl(false),
            breakfastbar: new FormControl(false),
            pantry: new FormControl(false),
            refrigerator: new FormControl(false),
            winerefrigerator: new FormControl(false),
            // DINING ROOM FEATURES
            formaldr: new FormControl(false),
            livingroomorarea: new FormControl(false),
            familyroomorarea: new FormControl(false),
            resibssq: new FormControl(null),
            // BASEMENT
            fullbasement: new FormControl(false),
            partialbasement: new FormControl(false),
            finishedbasement: new FormControl(false),
            unfinishedbasement: new FormControl(false),
            nonebasement: new FormControl(false),

            // EXTRA ROOMS
            greatroom: new FormControl(false),
            office: new FormControl(false),
            familyroom: new FormControl(false),
            denstudy: new FormControl(false),
            jettedtubextra: new FormControl(false),
            loft: new FormControl(false),
            gameroom: new FormControl(false),
            convertedgarage: new FormControl(false),
            wineroom: new FormControl(false),
            // ROOMS
            livingroomarea: new FormControl(null),
            livingroomwid: new FormControl(null),
            livingroomlen: new FormControl(null),
            livingroomdim: new FormControl(''),
            formaldiningroomarea: new FormControl(null),
            formaldiningroomwid: new FormControl(null),
            formaldiningroomlen: new FormControl(null),
            formaldiningroomdim: new FormControl(''),
            kitchenarea: new FormControl(null),
            kitchenwid: new FormControl(null),
            kitchenlen: new FormControl(null),
            kitchendim: new FormControl(''),
            eatingareaarea: new FormControl(null),
            familyroomarea: new FormControl(null),
            greatroomarea: new FormControl(null),
            mainbedroomarea: new FormControl(null),

            // Second Bedroom
            secondbedroomarea: new FormControl(null),
            secondbedroomwid: new FormControl(null),
            secondbedroomlen: new FormControl(null),
            secondbedroomdim: new FormControl(''),

            // Third Bedroom
            thirdbedroomarea: new FormControl(null),
            thirdbedroomwid: new FormControl(null),
            thirdbedroomlen: new FormControl(null),
            thirdbedroomdim: new FormControl(''),

            // Fourth Bedroom
            fourthbedroomarea: new FormControl(null),
            fourthbedroomdim: new FormControl(''),
            fourthbedroomwid: new FormControl(null),
            fourthbedroomlen: new FormControl(null),

            extraroomarea: new FormControl(null),
            utilityroomarea: new FormControl(null),
            eatingareawid: new FormControl(null),
            eatingarealen: new FormControl(null),
            eatingareadim: new FormControl(''),

            familyroomwid: new FormControl(null),
            familyroomlen: new FormControl(null),
            familyroomdim: new FormControl(''),

            greatroomwid: new FormControl(null),
            greatroomlen: new FormControl(null),
            greatroomdim: new FormControl(''),

            mainbedroomwid: new FormControl(null),
            mainbedroomlen: new FormControl(null),
            mainbedroomdim: new FormControl(''),

            extraroomwid: new FormControl(null),
            extraroomlen: new FormControl(null),
            extraroomdim: new FormControl(''),
            utilityroomwid: new FormControl(null),
            utilityroomlen: new FormControl(null),
            utilityroomdim: new FormControl(''),

            // Site Amenities
            corner: new FormControl(false),
            culdesac: new FormControl(false),
            bluffview: new FormControl(false),
            parkview: new FormControl(false),
            riverview: new FormControl(false),
            lakeview: new FormControl(false),
            waterfront: new FormControl(false),
            crossfenced: new FormControl(false),
            pasture: new FormControl(false),
            golfcourse: new FormControl(false),
            garagespaces: new FormControl(null, Validators.required),
            garageattached: new FormControl(false),
            garagedetached: new FormControl(false),

            // Parking Features
            existingrvparking: new FormControl(false),
            potentialrvparking: new FormControl(false),
            carport: new FormControl(false),
            uncovered: new FormControl(false),
            workshoparea: new FormControl(false),
            drivethru: new FormControl(false),
            autoopener: new FormControl(false),
            street: new FormControl(false),
            circulardriveway: new FormControl(false),
            tandemgarage: new FormControl(false),
            otherparkingfeatures: new FormControl(false),
            pool: new FormControl('', Validators.required),

            // PoolFeatures
            poolinground: new FormControl(false),
            poolaboveground: new FormControl(false),
            poolfenced: new FormControl(false),
            poolcommunitypool: new FormControl(false),
            poolgunite: new FormControl(false),
            poolvinyl: new FormControl(false),
            poolfiberglass: new FormControl(false),
            poolheated: new FormControl(false),
            poolgrassyarea: new FormControl(false),
            poolfiberopticlighting: new FormControl(false),
            poolpebble: new FormControl(false),
            poolwaterfeature: new FormControl(false),

            // Roofing
            roofcompsingle: new FormControl(false),
            rooftile: new FormControl(false),
            roofwood: new FormControl(false),
            roofmetal: new FormControl(false),
            rooftargravel: new FormControl(false),
            roofrolled: new FormControl(false),
            rooffiberglass: new FormControl(false),
            roofother: new FormControl(false),

            // Exterior
            exteriorstucco: new FormControl(false),
            exteriorwood: new FormControl(false),
            exteriorbrick: new FormControl(false),
            exteriorblock: new FormControl(false),
            exteriorstone: new FormControl(false),
            exteriormetal: new FormControl(false),
            exteriorshingle: new FormControl(false),
            exteriorlog: new FormControl(false),
            exteriorvinyl: new FormControl(false),
            exteriorother: new FormControl(false),

            // Yard
            sprinklerfront: new FormControl(false),
            sprinklerback: new FormControl(false),
            sprinklerauto: new FormControl(false),
            sprinklermanual: new FormControl(false),
            matureLandscape: new FormControl(false),
            yardfenced: new FormControl(false),
            fruitnuttrees: new FormControl(false),
            gardenarea: new FormControl(false),
            syntheticlawn: new FormControl(false),
            dripsystem: new FormControl(false),

            resispay: new FormControl(''),

            // SpaType
            spainground: new FormControl(false),
            spaaboveground: new FormControl(false),
            communityspa: new FormControl(false),

            // Patio
            patiocovered: new FormControl(false),
            patiouncovered: new FormControl(false),
            patioenclosed: new FormControl(false),
            patiobrick: new FormControl(false),
            patioconcrete: new FormControl(false),
            patiowooddeck: new FormControl(false),
            patiostone: new FormControl(false),
            patioscreened: new FormControl(false),

            // Detached Structures
            detachedshop: new FormControl(false),
            detachedguesthouse: new FormControl(false),
            detachedbarn: new FormControl(false),
            detachedkennel: new FormControl(false),
            detachedshed: new FormControl(false),
            detachedtwohomesonlot: new FormControl(false),
            detachedgreenhouse: new FormControl(false),
            detachedother: new FormControl(false),

            // SpecialDisclosure
            floodzone: new FormControl(false),
            agentownerrelated: new FormControl(false),
            deathonproperty: new FormControl(false),
            seeremarksattachments: new FormControl(false),

            // ReportsAvailable
            homeinspection: new FormControl(false),
            reportsavailableroof: new FormControl(false),
            reportsavailablepest: new FormControl(false),
            reportsavailableseptic: new FormControl(false),
            reportsavailablewell: new FormControl(false),
            waterpotability: new FormControl(false),
            reportsavailableccr: new FormControl(false),
            ruleregulations: new FormControl(false),
            reportsavailablebylaws: new FormControl(false),
            reportsavailablebudget: new FormControl(false),
            financialstatement: new FormControl(false),
            otherhoadocuments: new FormControl(false),
            reportsavailablenhd: new FormControl(false),
            preliminarytitlereport: new FormControl(false),

            // FirstLoanType
            fltassumable: new FormControl(false),
            fltnonassumable: new FormControl(false),
            fltprivate: new FormControl(false),
            fltfreeandclear: new FormControl(false),

            sltassumable: new FormControl(false),
            sltprivate: new FormControl(false),
            sltnonassumable: new FormControl(false),

            // SellerWillConsider
            government: new FormControl(false),
            conventional: new FormControl(false),
            leaseoption: new FormControl(false),
            cash: new FormControl(false),
            ownercarry: new FormControl(false),
            limitedfinancing: new FormControl(false),
            cashonly: new FormControl(false),

            shortsale: new FormControl('', Validators.required),
            auction: new FormControl('', Validators.required),
            resirebk: new FormControl('', Validators.required),
            hardship: new FormControl(''),
            ftrustdeedamt: new FormControl('$' + ''),
            strustdeedamt: new FormControl('$' + ''),
            notes: new FormControl(''),
            additionaldebt: new FormControl(''),
            hoassociation: new FormControl('', Validators.required),
            hoadues: new FormControl('$' + ''),

            // AssociationParkAmenities
            associationparkamenitiespool: new FormControl(false),
            associationparkspa: new FormControl(false),
            associationparksauna: new FormControl(false),
            associationparkclubhouse: new FormControl(false),
            associationparkexerciseroom: new FormControl(false),
            associationparkgreenarea: new FormControl(false),
            associationparkrvstorage: new FormControl(false),
            associationparktennis: new FormControl(false),
            associationparkracquetball: new FormControl(false),
            associationparksecurityguard: new FormControl(false),
            associationparklakepond: new FormControl(false),
            associationparkplayground: new FormControl(false),
            associationparklandscapemaint: new FormControl(false),
            associationparkgatedcommunity: new FormControl(false),
            atherassociationpark: new FormControl(false),

            otheramenities: new FormControl(''),

            // UniqueConditions

            probate: new FormControl(false),
            estatesale: new FormControl(false),
            bankruptcy: new FormControl(false),
            courtapprovalreq: new FormControl(false),
            relocompany: new FormControl(false),
            exchange: new FormControl(false),

            mobilehome: new FormControl(''),
            resirent: new FormControl(null),
            resifreq: new FormControl('$' + ''),

            // ParkDescription
            parkspacerented: new FormControl(false),
            parkspaceowned: new FormControl(false),
            privatespacerented: new FormControl(false),
            privatespaceowned: new FormControl(false),
            oncountytaxroll: new FormControl(false),

            // Mobile Home Type
            singlewide: new FormControl(false),
            doublewide: new FormControl(false),
            triplewide: new FormControl(false),
            expando: new FormControl(false),
            othermht: new FormControl(false),

            permanentfoundation: new FormControl(''),
            resihudn: new FormControl(''),
            resilics: new FormControl(''),
            resisnum: new FormControl(''),
            resipknm: new FormControl(''),
            resimodl: new FormControl('')
        });

    }

    getDataFromForm() {
        const formValue = this.sellPropertyForm.value;

        // DIRECTIONS
        let direction = '';
        if (formValue.north) {
            direction = 'north';
        } else if (formValue.east) {
            direction = 'east';
        } else if (formValue.west) {
            direction = 'west';
        } else if (formValue.south) {
            direction = 'south';
        }
        this.sellPropertyForm.patchValue({directions: direction});

        // APN
        let apn = '';
        if (formValue.apn_new) {
            apn = 'New';
        } else if (formValue.apn_u_c) {
            apn = 'Under Construction'
        } else if (formValue.apn_n_a) {
            apn = 'N/A'
        }
        this.sellPropertyForm.patchValue({apn: apn});

        // LEASE TYPE
        let leaseType = '';
        if (formValue.gross) {
            leaseType = 'Gross';
        } else if (formValue.net) {
            leaseType = 'Net'
            formValue.LeaseType = 'Net';
        } else if (formValue.otherLeaseType) {
            leaseType = 'Other'
        }
        this.sellPropertyForm.addControl('leasetype', new FormControl(leaseType));

        // ADDITIONAL APNs
        const apn2 = formValue.apn2_1 + ' - ' + formValue.apn2_2 + ' - ' + formValue.apn2_3;
        this.sellPropertyForm.addControl('additionalapn2', new FormControl(apn2));

        const apn3 = formValue.apn3_1 + ' - ' + formValue.apn3_2 + ' - ' + formValue.apn3_3;
        this.sellPropertyForm.addControl('additionalapn3', new FormControl(apn3));

        const apn4 = formValue.apn4_1 + ' - ' + formValue.apn4_2 + ' - ' + formValue.apn4_3;
        this.sellPropertyForm.addControl('additionalapn4', new FormControl(apn4));

        const apn5 = formValue.apn5_1 + ' - ' + formValue.apn5_2 + ' - ' + formValue.apn5_3;
        this.sellPropertyForm.addControl('additionalapn5', new FormControl(apn5));

        // AGREEMENT TYPE
        let agreementType = '';
        if (formValue.exclrighttosell) {
            agreementType = 'Excl. Right to Sell';
        } else if (formValue.exclagency) {
            agreementType = 'Excl. Agency';
        } else if (formValue.open) {
            agreementType = 'Open';
        }
        this.sellPropertyForm.addControl('agreementtype', new FormControl(agreementType));

        // YEAR BUILT
        let YearBuiltException = '';
        if (formValue.newyb) {
            YearBuiltException = 'New';
        } else if (formValue.underconstructionyb) {
            YearBuiltException = 'Under Construction';
        } else if (formValue.unknownyb) {
            YearBuiltException = 'Unknown';
        }
        this.sellPropertyForm.addControl('yearbuiltexception', new FormControl(YearBuiltException));

        // Bathrooms
        let TubAndShower = '';
        let Shower = '';
        let Tub = '';
        let JettedTub = '';
        let OvalTub = '';
        let RomanTub = '';
        let OtherBathFeature = '';

        if (formValue.tubandshower) {
            TubAndShower = 'Tub and Shower '
        }
        if (formValue.shower) {
            Shower = 'Shower '
        }
        if (formValue.tub) {
            Tub = 'Tub '
        }
        if (formValue.jettedtub) {
            JettedTub = 'Jetted Tub '
        }
        if (formValue.ovaltub) {
            OvalTub = 'Oval Tub '
        }
        if (formValue.romantub) {
            RomanTub = 'Roman Tub '
        }
        if (formValue.otherbathfeature) {
            OtherBathFeature = 'Other '
        }

        let RESIBTHF = '';
        if (TubAndShower || Shower || Tub || JettedTub || OvalTub || RomanTub || OtherBathFeature) {
            RESIBTHF = TubAndShower + Shower + Tub + JettedTub + OvalTub + RomanTub + OtherBathFeature;
        }
        this.sellPropertyForm.addControl('resibthf', new FormControl(RESIBTHF));

        // SquareFootageSource
        let SquareFootageSource = '';
        if (formValue.sfseller) {
            SquareFootageSource = 'Seller';
        } else if (formValue.sfappraiser) {
            SquareFootageSource = 'Appraiser';
        } else if (formValue.sftaxrecords) {
            SquareFootageSource = 'Tax Records';
        } else if (formValue.sfother) {
            SquareFootageSource = 'Other';
        }

        this.sellPropertyForm.addControl('squarefootagesource', new FormControl(SquareFootageSource, Validators.required));

        // LOT SIZE
        let LotMeasurement = '';
        if (formValue.lotsizesqft) {
            LotMeasurement = ' SqFt';
        } else if (formValue.lotsizeacres) {
            LotMeasurement = ' Acres';
        }

        this.sellPropertyForm.patchValue({lotmeasurement: LotMeasurement});
        // LOTSIZESource
        let LotSizeSource = '';
        if (formValue.lsseller) {
            LotSizeSource = 'Seller';
        } else if (formValue.lsappraiser) {
            LotSizeSource = 'Appraiser';
        } else if (formValue.lstaxrecords) {
            LotSizeSource = 'Tax Records';
        } else if (formValue.lsother) {
            LotSizeSource = 'Other';
        }

        this.sellPropertyForm.addControl('lotsizesource', new FormControl(LotSizeSource));

        // Occupant Type
        let occupantType = '';
        if (formValue.otowner) {
            occupantType = 'Owner';
        } else if (formValue.otvacant) {
            occupantType = 'Vacant';
        } else if (formValue.otcaretaker) {
            occupantType = 'Tax Caretaker';
        } else if (formValue.ottenant) {
            occupantType = 'Tenant';
        }

        this.sellPropertyForm.addControl('occupanttype', new FormControl(occupantType, Validators.required));

        // PROPERTY TYPE
        let resitype = '';
        if (formValue.propertytyperanch) {
            resitype = 'Ranch';
        } else if (formValue.propertytypebungalow) {
            resitype = 'Bungalo';
        } else if (formValue.propertytypecolonial) {
            resitype = 'Colonial';
        } else if (formValue.propertytypespanish) {
            resitype = 'Spanish';
        } else if (formValue.propertytypecontemporary) {
            resitype = 'Contemporary';
        } else if (formValue.propertytypetudor) {
            resitype = 'Tudor';
        } else if (formValue.propertytypevictorian) {
            resitype = 'Victorian';
        } else if (formValue.propertytypemediterranean) {
            resitype = 'Mediterranean';
        } else if (formValue.propertytypetownhouse) {
            resitype = 'Townhouse';
        } else if (formValue.propertytypecottage) {
            resitype = 'Cottage';
        } else if (formValue.propertytypecabin) {
            resitype = 'Cabin';
        } else if (formValue.propertytypemcm) {
            resitype = 'Mid-Century Modern';
        }

        this.sellPropertyForm.addControl('resitype', new FormControl(resitype));

        // REGION
        let region = '';
        if (formValue.regionurban) {
            region = 'Urban';
        } else if (formValue.regionrural) {
            region = 'Rural';
        } else if (formValue.regionfoothill) {
            region = 'Foothill';
        } else if (formValue.regionmountain) {
            region = 'Mountain';
        }

        this.sellPropertyForm.addControl('region', new FormControl(region, Validators.required));

        // SewerAndWater
        let PublicSewer = '';
        let PublicWater = '';
        let SepticTank = '';
        let SharedWell = '';
        let CommunityWell = '';
        let WaterWell = '';
        let PrivateWell = '';

        if (formValue.publicsewer) {
            PublicSewer = 'Public Sewer ';
        }
        if (formValue.publicwater) {
            PublicWater = 'Public Water ';
        }
        if (formValue.septictank) {
            SepticTank = 'Septic Tank ';
        }
        if (formValue.sharedwell) {
            SharedWell = 'Shared Well ';
        }
        if (formValue.communitywell) {
            CommunityWell = 'Community Well ';
        }
        if (formValue.waterwell) {
            WaterWell = 'Water Well ';
        }
        if (formValue.privatewell) {
            PrivateWell = 'Private Well ';
        }
        let RESIWATR = '';
        if (PublicSewer || PublicWater || SepticTank || SharedWell || CommunityWell || WaterWell || PrivateWell) {
            RESIWATR = PublicSewer + PublicWater + SepticTank + SharedWell + CommunityWell + WaterWell + PrivateWell;
        }

        this.sellPropertyForm.addControl('resiwatr', new FormControl(RESIWATR, Validators.required));

        // Gas & Electric
        let PublicUtilities = '';
        let Propane = '';
        let NoGas = '';
        let AllElectric = '';
        let Generator = '';
        let NoElectric = '';

        if (formValue.publicutilities) {
            PublicUtilities = 'Public Utilities ';
        }
        if (formValue.propane) {
            Propane = 'Propane ';
        }
        if (formValue.nogas) {
            NoGas = 'No Gas ';
        }
        if (formValue.allelectric) {
            AllElectric = 'All Electric ';
        }
        if (formValue.generator) {
            Generator = 'Generator ';
        }
        if (formValue.noelectric) {
            NoElectric = 'No Electric ';
        }

        let RESIGSEL = '';
        if (PublicUtilities || Propane || NoGas || AllElectric || Generator || NoElectric) {
            RESIGSEL = PublicUtilities + Propane + NoGas + AllElectric + Generator + NoElectric;
        }

        this.sellPropertyForm.addControl('resigsel', new FormControl(RESIGSEL, Validators.required));
        // UnitDescription
        let RESIUNDS = '';
        if (formValue.groundfloor) {
            RESIUNDS = 'Ground Floor';
        } else if (formValue.secondfloor) {
            RESIUNDS = 'Second Floor';
        } else if (formValue.splitlevel) {
            RESIUNDS = 'Split Level';
        } else if (formValue.endunit) {
            RESIUNDS = 'End Unit';
        } else if (formValue.inside) {
            RESIUNDS = 'Inside';
        } else if (formValue.front) {
            RESIUNDS = 'Front';
        } else if (formValue.rear) {
            RESIUNDS = 'Rear';
        }

        this.sellPropertyForm.addControl('resiunds', new FormControl(RESIUNDS));

        // SpecialFeatures
        let IsolatedBedroom = '';
        let IsolatedBathroom = '';
        let Studio = '';
        let SecuritySystem = '';
        let SecurityGate = '';
        let BuiltInCabinets = '';
        let CentralVac = '';
        let Skylight = '';
        let WaterSoftener = '';
        let WaterFilter = '';
        let WetDryBar = '';
        let SatelliteDish = '';
        let Intercom = '';
        let HighTechCable = '';
        let MultiPhoneLine = '';
        let PrivateTennisCourt = '';
        let OtherFeatures = '';
        let HorsesAllowed = '';
        let HorseSetup = '';

        if (formValue.isolatedbedroom) {
            IsolatedBedroom = 'Isolated Bedroom ';
        }
        if (formValue.isolatedbathroom) {
            IsolatedBathroom = 'Isolated Bathroom ';
        }
        if (formValue.studio) {
            Studio = 'Studio ';
        }
        if (formValue.securitysystem) {
            SecuritySystem = 'Security System ';
        }
        if (formValue.securitygate) {
            SecurityGate = 'Security Gate ';
        }
        if (formValue.builtincabinets) {
            BuiltInCabinets = 'Built-in Cabinets ';
        }
        if (formValue.centralvac) {
            CentralVac = 'Central Vac ';
        }
        if (formValue.skylight) {
            Skylight = 'Skylight ';
        }
        if (formValue.watersoftener) {
            WaterSoftener = 'Water Softener ';
        }
        if (formValue.waterfilter) {
            WaterFilter = 'Water Filter ';
        }
        if (formValue.wetdrybar) {
            WetDryBar = 'Wet Dry Bar ';
        }
        if (formValue.satellitedish) {
            SatelliteDish = 'Satellite Dish ';
        }
        if (formValue.intercom) {
            Intercom = 'Intercom ';
        }
        if (formValue.hightechcable) {
            HighTechCable = 'High Tech Cable ';
        }
        if (formValue.multiphoneline) {
            MultiPhoneLine = 'Multi Phone Line ';
        }
        if (formValue.privatetenniscourt) {
            PrivateTennisCourt = 'Private Tennis Court ';
        }
        if (formValue.otherfeatures) {
            OtherFeatures = 'Other Features ';
        }
        if (formValue.horsesallowed) {
            HorsesAllowed = 'Horses Allowed ';
        }
        if (formValue.horsesetup) {
            HorseSetup = 'Horse Setup ';
        }
        let RESISPFT = '';
        if (IsolatedBedroom || IsolatedBathroom || Studio || SecuritySystem || SecurityGate || BuiltInCabinets
            || CentralVac || Skylight || WaterSoftener || WaterFilter || WetDryBar || SatelliteDish || Intercom
            || HighTechCable || MultiPhoneLine
            || PrivateTennisCourt || OtherFeatures || HorsesAllowed || HorseSetup) {
            RESISPFT = IsolatedBedroom + IsolatedBathroom + Studio + SecuritySystem + SecurityGate + BuiltInCabinets
                + CentralVac + Skylight + WaterSoftener + WaterFilter + WetDryBar + SatelliteDish + Intercom
                + HighTechCable + MultiPhoneLine
                + PrivateTennisCourt + OtherFeatures + HorsesAllowed + HorseSetup;
        }

        this.sellPropertyForm.addControl('resispft', new FormControl(RESISPFT));

        // Disabled Features
        let WheelchairAccess = '';
        let LimitedWheelchairAccess = '';
        let Ramp = '';
        let WideDoors = '';
        let HandRails = '';
        let BathroomFixtures = '';
        let WheelChairAccessibleShower = '';
        let OneLevelFloor = '';
        let Elevator = '';

        if (formValue.wheelchairaccess) {
            WheelchairAccess = 'Wheelchair Access ';
        }
        if (formValue.limitedwheelchairaccess) {
            LimitedWheelchairAccess = 'Limited Wheelchair Access ';
        }
        if (formValue.ramp) {
            Ramp = 'Ramp ';
        }
        if (formValue.widedoors) {
            WideDoors = 'Wide Doors ';
        }
        if (formValue.handrails) {
            HandRails = 'Hand Rails ';
        }
        if (formValue.bathroomfixtures) {
            BathroomFixtures = 'Bathroom Fixtures ';
        }
        if (formValue.wheelchairaccessibleshower) {
            WheelChairAccessibleShower = 'Wheel Chair Accessible Shower ';
        }
        if (formValue.onelevelfloor) {
            OneLevelFloor = 'One Level Floor ';
        }
        if (formValue.elevator) {
            Elevator = 'Elevator ';
        }
        let RESIDSFT = '';
        if (WheelchairAccess || LimitedWheelchairAccess || Ramp || WideDoors || HandRails
            || BathroomFixtures || WheelChairAccessibleShower || OneLevelFloor || Elevator) {
            RESIDSFT = WheelchairAccess + LimitedWheelchairAccess + Ramp + WideDoors + HandRails
                + BathroomFixtures + WheelChairAccessibleShower + OneLevelFloor + Elevator;
        }

        this.sellPropertyForm.addControl('residsft', new FormControl(RESIDSFT));

        // Energy Efficiency
        let RESIENER = '';
        if (formValue.dualpanewindows) {
            RESIENER = 'Dual Pane Windows';
        } else if (formValue.triplepanewindows) {
            RESIENER = 'Triple Pane Windows';
        } else if (formValue.solarelectricity) {
            RESIENER = 'Solar Electricity';
        } else if (formValue.solarpoolheater) {
            RESIENER = 'Solar Pool Heater';
        } else if (formValue.solarwaterheater) {
            RESIENER = 'Solar Water Heater';
        } else if (formValue.seer) {
            RESIENER = '13+ SEER A/C';
        } else if (formValue.tanklesswaterheater) {
            RESIENER = 'Tankless Water Heater';
        } else if (formValue.otherenergyefficiency) {
            RESIENER = 'Other Energy Efficiency';
        }
        this.sellPropertyForm.addControl('resiener', new FormControl(RESIENER));

        // LeasedEquipment
        let RESILSDE = '';
        if (formValue.securitysystemee) {
            RESILSDE = 'Security System';
        } else if (formValue.propanetankee) {
            RESILSDE = 'Propane Tank';
        } else if (formValue.watersofteneree) {
            RESILSDE = 'Water Softener';
        } else if (formValue.satellitedishee) {
            RESILSDE = 'Satellite Dish';
        } else if (formValue.solarpanelsee) {
            RESILSDE = 'Solar Panels';
        }

        this.sellPropertyForm.addControl('resilsde', new FormControl(RESILSDE));
        // Showing

        let lockbox = '';
        let keyinoffice = '';
        let gooccupied = '';
        let callfirst = '';
        let leavemessage = '';
        let go = '';
        let confirmedappointmentonly = '';
        let notice24hour = '';
        let notsunday = '';
        let sunset = '';
        let callagent = '';
        let doginside = '';
        let dogoutside = '';
        let restrictedhours = '';
        let lockedgate = '';
        let seasonalaccess = '';
        let fourwheeldrive = '';
        let cbscoderequired = '';
        let vacantgoshow = '';
        if (formValue.lockbox) {
            lockbox = 'Lock Box'
        } else if (formValue.keyinoffice) {
            keyinoffice = 'Key In Office'
        } else if (formValue.gooccupied) {
            gooccupied = 'Go Occupied'
        } else if (formValue.callfirst) {
            callfirst = 'Call First'
        } else if (formValue.leavemessage) {
            leavemessage = 'Leave Message'
        } else if (formValue.go) {
            go = 'Go'
        } else if (formValue.confirmedappointmentonly) {
            confirmedappointmentonly = 'Confirmed Appointment Only'
        } else if (formValue.notice24hour) {
            notice24hour = 'Notice 24 Hour'
        } else if (formValue.notsunday) {
            notsunday = 'Not Sunday'
        } else if (formValue.sunset) {
            sunset = 'Sunset Fri/Sat'
        } else if (formValue.callagent) {
            callagent = 'Call Agent'
        } else if (formValue.doginside) {
            doginside = 'Dog Inside'
        } else if (formValue.dogoutside) {
            dogoutside = 'Dog Outside'
        } else if (formValue.restrictedhours) {
            restrictedhours = 'Restricted Hours'
        } else if (formValue.lockedgate) {
            lockedgate = 'Locked Gate'
        } else if (formValue.seasonalaccess) {
            seasonalaccess = 'Seasonal Access'
        } else if (formValue.fourwheeldrive) {
            fourwheeldrive = 'Four Wheel Drive'
        } else if (formValue.cbscoderequired) {
            cbscoderequired = 'CBS Code Required'
        } else if (formValue.vacantgoshow) {
            vacantgoshow = 'Vacant Go Show'
        }
        let showing = '';
        if (lockbox || keyinoffice || gooccupied || callfirst || leavemessage || go || confirmedappointmentonly ||
            notice24hour || notsunday || sunset || callagent || doginside || dogoutside || restrictedhours || lockedgate ||
            seasonalaccess || fourwheeldrive || cbscoderequired || vacantgoshow) {
            showing = lockbox + keyinoffice + gooccupied + callfirst + leavemessage + go + confirmedappointmentonly +
                notice24hour + notsunday + sunset + callagent + doginside + dogoutside + restrictedhours + lockedgate +
                seasonalaccess + fourwheeldrive + cbscoderequired + vacantgoshow;
        }
        this.sellPropertyForm.addControl('showing', new FormControl(showing, Validators.required));


        // HeatingAndCooling
        let centralheatandcool = '';
        let centralheating = '';
        let centralcooling = '';
        let evaporativecool = '';
        let floororwallunit = '';
        let windoworwallac = '';
        let wholehousefan = '';
        let nonehc = '';
        if (formValue.centralheatandcool) {
            centralheatandcool = 'Central Heat And Cool';
        } else if (formValue.centralheating) {
            centralheating = 'Central Heating';
        } else if (formValue.centralcooling) {
            centralcooling = 'Central Cooling';
        } else if (formValue.evaporativecool) {
            evaporativecool = 'Evaporative Cool';
        } else if (formValue.floororwallunit) {
            floororwallunit = 'Floor Or Wall Unit';
        } else if (formValue.windoworwallac) {
            windoworwallac = 'Window Or Wall A/C';
        } else if (formValue.wholehousefan) {
            wholehousefan = 'Whole House Fan';
        } else if (formValue.nonehc) {
            nonehc = 'None';
        }
        let RESIHTCL = '';
        if (centralheatandcool || centralheating || centralcooling || evaporativecool || floororwallunit ||
            windoworwallac || wholehousefan || nonehc) {
            RESIHTCL = centralheatandcool + centralheating + centralcooling + evaporativecool + floororwallunit +
                windoworwallac + wholehousefan + nonehc;
        }

        this.sellPropertyForm.addControl('resihtcl', new FormControl(RESIHTCL, Validators.required));

        // Foundation
        let foundation = '';
        if (formValue.concretefoundation) {
            foundation = 'Concrete'
        } else if (formValue.woodsubfloor) {
            foundation = 'Wood Subfloor'
        } else if (formValue.concreteandwoodfoundation) {
            foundation = 'Both'
        }

        this.sellPropertyForm.addControl('foundation', new FormControl(foundation, Validators.required));
        // Floring
        let RESIFLOR = '';
        if (formValue.carpet) {
            RESIFLOR = 'Carpet';
        } else if (formValue.tile) {
            RESIFLOR = 'Tile';
        } else if (formValue.vinyl) {
            RESIFLOR = 'Vinyl';
        } else if (formValue.hardwood) {
            RESIFLOR = 'Hardwood';
        } else if (formValue.otherflooring) {
            RESIFLOR = 'Other';
        }

        this.sellPropertyForm.addControl('resiflor', new FormControl(RESIFLOR));
        // Fireplace
        let fireplace = '';
        if (formValue.masonry) {
            fireplace = 'Masonry'
        } else if (formValue.gasinsert) {
            fireplace = 'Gas Insert'
        } else if (formValue.woodinsert) {
            fireplace = 'Wood Insert'
        } else if (formValue.zeroclearance) {
            fireplace = 'Zero Clearance'
        } else if (formValue.freestanding) {
            fireplace = 'Free Standing'
        } else if (formValue.pellet) {
            fireplace = 'Pellet'
        }

        this.sellPropertyForm.addControl('fireplace', new FormControl(fireplace));

        // Laundry
        let RESILNDR = '';
        if (formValue.insidelaundry) {
            RESILNDR = 'Inside';
        } else if (formValue.ingaragelaundry) {
            RESILNDR = 'In Garage';
        } else if (formValue.inclosetlaundry) {
            RESILNDR = 'In Closet';
        } else if (formValue.utilityroomlaundry) {
            RESILNDR = 'Utility room';
        } else if (formValue.onporchlaundry) {
            RESILNDR = 'On Porch';
        } else if (formValue.onupperlevellaundry) {
            RESILNDR = 'On Upper Level';
        } else if (formValue.onlowerlevellaundry) {
            RESILNDR = 'On Lower Level';
        } else if (formValue.gashookuplaundry) {
            RESILNDR = 'Gas Hook-up';
        } else if (formValue.electrichookuplaundry) {
            RESILNDR = 'Electric Hook-up';
        } else if (formValue.commonlaundry) {
            RESILNDR = 'Common';
        } else if (formValue.coinoplaundry) {
            RESILNDR = 'Coin-Op';
        } else if (formValue.nonelaundry) {
            RESILNDR = 'None';
        }

        this.sellPropertyForm.addControl('resilndr', new FormControl(RESILNDR, Validators.required));

        // Kitchen
        let fsrangeoven = '';
        let builtinrangeoven = '';
        let gasappliances = '';
        let electricappliances = '';
        let disposal = '';
        let dishwasher = '';
        let microwave = '';
        let trashcompactor = '';
        let eatingarea = '';
        let breakfastbar = '';
        let refrigerator = '';
        let pantry = '';
        let winerefrigerator = '';
        if (formValue.fsrangeoven) {
            fsrangeoven = 'F/S Range/Oven';
        } else if (formValue.builtinrangeoven) {
            builtinrangeoven = 'Built-in Range/Oven';
        } else if (formValue.gasappliances) {
            gasappliances = 'Gas Appliances';
        } else if (formValue.electricappliances) {
            electricappliances = 'Electric Appliances';
        } else if (formValue.disposal) {
            disposal = 'Disposal';
        } else if (formValue.dishwasher) {
            dishwasher = 'Dishwasher';
        } else if (formValue.microwave) {
            microwave = 'Microwave';
        } else if (formValue.trashcompactor) {
            trashcompactor = 'Trash Compactor';
        } else if (formValue.eatingarea) {
            eatingarea = 'Eating Area';
        } else if (formValue.breakfastbar) {
            breakfastbar = 'Breakfast Bar';
        } else if (formValue.pantry) {
            pantry = 'Pantry';
        } else if (formValue.refrigerator) {
            refrigerator = 'Refrigerator';
        } else if (formValue.winerefrigerator) {
            winerefrigerator = 'Wine Refrigerator';
        }
        let RESIKTCN = '';
        if (fsrangeoven || builtinrangeoven || gasappliances || electricappliances || disposal || dishwasher ||
            microwave || trashcompactor || eatingarea || breakfastbar || pantry || refrigerator || winerefrigerator) {
            RESIKTCN = fsrangeoven + builtinrangeoven + gasappliances + electricappliances + disposal + dishwasher +
                microwave + trashcompactor + eatingarea + breakfastbar + pantry + refrigerator + winerefrigerator
        }
        this.sellPropertyForm.addControl('resiktcn', new FormControl(RESIKTCN));

        // Dining Room Features
        let RESIDINF = '';
        if (formValue.formaldr) {
            RESIDINF = 'Formal';
        } else if (formValue.livingroomorarea) {
            RESIDINF = 'Living Room/Area';
        } else if (formValue.familyroomorarea) {
            RESIDINF = 'Family Room/Area';
        }

        this.sellPropertyForm.addControl('residinf', new FormControl(RESIDINF));
        // Basement
        let basement = '';
        if (formValue.fullbasement) {
            basement = 'Full'
        } else if (formValue.partialbasement) {
            basement = 'Partial'
        } else if (formValue.finishedbasement) {
            basement = 'Finished'
        } else if (formValue.unfinishedbasement) {
            basement = 'Unfinished'
        } else if (formValue.nonebasement) {
            basement = 'None'
        }

        this.sellPropertyForm.addControl('basement', new FormControl(basement));
        // Extra Rooms
        let RESIEXTR = '';
        if (formValue.greatroom) {
            RESIEXTR = 'Great Room';
        } else if (formValue.office) {
            RESIEXTR = 'Office';
        } else if (formValue.familyroom) {
            RESIEXTR = 'Family Room';
        } else if (formValue.denstudy) {
            RESIEXTR = 'Den/Study';
        } else if (formValue.jettedtubextra) {
            RESIEXTR = 'Jetted Tub';
        } else if (formValue.loft) {
            RESIEXTR = 'Loft';
        } else if (formValue.gameroom) {
            RESIEXTR = 'Game Room';
        } else if (formValue.convertedgarage) {
            RESIEXTR = 'Converted Garage';
        } else if (formValue.wineroom) {
            RESIEXTR = 'Wine Room';
        }

        this.sellPropertyForm.addControl('resiextr', new FormControl(RESIEXTR));
        // Site Amenities
        let RESISITE = '';
        if (formValue.corner) {
            RESISITE = 'Corner';
        } else if (formValue.culdesac) {
            RESISITE = 'Cul-de-sac';
        } else if (formValue.bluffview) {
            RESISITE = 'Bluff View';
        } else if (formValue.parkview) {
            RESISITE = 'Park View';
        } else if (formValue.riverview) {
            RESISITE = 'River View';
        } else if (formValue.lakeview) {
            RESISITE = 'Lake View';
        } else if (formValue.waterfront) {
            RESISITE = 'Water Front';
        } else if (formValue.crossfenced) {
            RESISITE = 'Cross Fenced';
        } else if (formValue.pasture) {
            RESISITE = 'Pasture';
        } else if (formValue.golfcourse) {
            RESISITE = 'Golf Course';
        }
        this.sellPropertyForm.addControl('resisite', new FormControl(RESISITE));

        // GarageType
        let RESIGART = '';
        if (formValue.garageattached) {
            RESIGART = 'Garage Attached';
        } else if (formValue.garagedetached) {
            RESIGART = 'Garage Detached';
        }

        this.sellPropertyForm.addControl('resigart', new FormControl(RESIGART));
        // Parking Features
        let existingrvparking = '';
        let potentialrvparking = '';
        let carport = '';
        let uncovered = '';
        let workshoparea = '';
        let drivethru = '';
        let autoopener = '';
        let street = '';
        let circulardriveway = '';
        let tandemgarage = '';
        let otherparkingfeatures = '';
        if (formValue.existingrvparking) {
            existingrvparking = 'Existing RV Parking ';
        } else if (formValue.potentialrvparking) {
            potentialrvparking = 'Potential RV Parking ';
        } else if (formValue.carport) {
            carport = 'Carport ';
        } else if (formValue.uncovered) {
            uncovered = 'Uncovered ';
        } else if (formValue.workshoparea) {
            workshoparea = 'Work/Shop Area ';
        } else if (formValue.drivethru) {
            drivethru = 'Drive Thru ';
        } else if (formValue.autoopener) {
            autoopener = 'Auto Opener ';
        } else if (formValue.street) {
            street = 'Street ';
        } else if (formValue.circulardriveway) {
            circulardriveway = 'Circular Driveway ';
        } else if (formValue.tandemgarage) {
            tandemgarage = 'Tandem Garage ';
        } else if (formValue.otherparkingfeatures) {
            otherparkingfeatures = 'Other ';
        }
        let RESIPRKF = '';
        if (existingrvparking || potentialrvparking || carport || uncovered || workshoparea || drivethru ||
            autoopener || street || circulardriveway || tandemgarage || otherparkingfeatures) {
            RESIPRKF = existingrvparking + potentialrvparking + carport + uncovered + workshoparea + drivethru +
                autoopener + street + circulardriveway + tandemgarage + otherparkingfeatures
        }
        this.sellPropertyForm.addControl('resiprkf', new FormControl(RESIPRKF, Validators.required));
        // Pool Type
        if (formValue.poolinground) {
            this.sellPropertyForm.addControl('pool', new FormControl('In Ground'));
        } else if (formValue.poolaboveground) {
            this.sellPropertyForm.addControl('pool', new FormControl('Above Ground'));
        } else {
            this.sellPropertyForm.addControl('pool', new FormControl(''));
        }

        // Pool Features
        let RESIPOLT = '';
        if (formValue.poolfenced) {
            RESIPOLT = 'Fenced';
        } else if (formValue.poolcommunitypool) {
            RESIPOLT = 'Community Pool';
        } else if (formValue.poolgunite) {
            RESIPOLT = 'Gunite';
        } else if (formValue.poolvinyl) {
            RESIPOLT = 'Vinyl';
        } else if (formValue.poolfiberglass) {
            RESIPOLT = 'Fiberglass';
        } else if (formValue.poolheated) {
            RESIPOLT = 'Heated';
        } else if (formValue.poolgrassyarea) {
            RESIPOLT = 'Grassy Area';
        } else if (formValue.poolfiberopticlighting) {
            RESIPOLT = 'Fiber Optic Lighting';
        } else if (formValue.poolpebble) {
            RESIPOLT = 'Pebble';
        } else if (formValue.poolwaterfeature) {
            RESIPOLT = 'Water Feature';
        }

        this.sellPropertyForm.addControl('resipolt', new FormControl(RESIPOLT));

        // Roofing
        let RESIROOF = '';
        if (formValue.roofcompsingle) {
            RESIROOF = 'Comp Single';
        } else if (formValue.rooftile) {
            RESIROOF = 'Tile';
        } else if (formValue.roofwood) {
            RESIROOF = 'Wood';
        } else if (formValue.roofmetal) {
            RESIROOF = 'Metal';
        } else if (formValue.rooftargravel) {
            RESIROOF = 'Tar Gravel';
        } else if (formValue.roofrolled) {
            RESIROOF = 'Rolled';
        } else if (formValue.rooffiberglass) {
            RESIROOF = 'Fiberglass';
        } else if (formValue.roofother) {
            RESIROOF = 'Other';
        }
        this.sellPropertyForm.addControl('resiroof', new FormControl(RESIROOF, Validators.required));

        // Exterior
        let RESIXTRR = '';
        if (formValue.exteriorstucco) {
            RESIXTRR = 'Stucco';
        } else if (formValue.exteriorwood) {
            RESIXTRR = 'Wood';
        } else if (formValue.exteriorbrick) {
            RESIXTRR = 'Brick';
        } else if (formValue.exteriorblock) {
            RESIXTRR = 'Block';
        } else if (formValue.exteriorstone) {
            RESIXTRR = 'Stone';
        } else if (formValue.exteriormetal) {
            RESIXTRR = 'Metal';
        } else if (formValue.exteriorshingle) {
            RESIXTRR = 'Shingle';
        } else if (formValue.exteriorlog) {
            RESIXTRR = 'Log';
        } else if (formValue.exteriorvinyl) {
            RESIXTRR = 'Vinyl';
        } else if (formValue.exteriorother) {
            RESIXTRR = 'Other';
        }
        this.sellPropertyForm.addControl('resixtrr', new FormControl(RESIXTRR, Validators.required));

        // Yard
        let sprinklerfront = '';
        let sprinklerback = '';
        let sprinklerauto = '';
        let sprinklermanual = '';
        let maturelandscape = '';
        let yardfenced = '';
        let fruitnuttrees = '';
        let gardenarea = '';
        let syntheticlawn = '';
        let dripsystem = '';
        if (formValue.sprinklerfront) {
            sprinklerfront = 'Sprinkler Front';
        } else if (formValue.sprinklerback) {
            sprinklerback = 'Sprinkler Back';
        } else if (formValue.sprinklerauto) {
            sprinklerauto = 'Sprinkler Auto';
        } else if (formValue.sprinklermanual) {
            sprinklermanual = 'Sprinkler Manual';
        } else if (formValue.maturelandscape) {
            maturelandscape = 'Mature Landscape';
        } else if (formValue.yardfenced) {
            yardfenced = 'Yard Fenced';
        } else if (formValue.fruitnuttrees) {
            fruitnuttrees = 'Fruit/Nut Trees';
        } else if (formValue.gardenarea) {
            gardenarea = 'Garden Area';
        } else if (formValue.syntheticlawn) {
            syntheticlawn = 'Synthetic Lawn';
        } else if (formValue.dripsystem) {
            dripsystem = 'Drip System';
        }
        let RESIYARD = '';
        if (sprinklerfront || sprinklerback || sprinklerauto || sprinklermanual || maturelandscape || yardfenced || fruitnuttrees ||
            gardenarea || syntheticlawn || dripsystem) {
            RESIYARD = sprinklerfront + sprinklerback + sprinklerauto + sprinklermanual + maturelandscape + yardfenced + fruitnuttrees +
                gardenarea + syntheticlawn + dripsystem;
        }
        this.sellPropertyForm.addControl('resiyard', new FormControl(RESIYARD));

        // SpaType
        let RESISPAT = '';
        if (formValue.spainground) {
            RESISPAT = 'In Ground';
        } else if (formValue.spaaboveground) {
            RESISPAT = 'Above Ground';
        } else if (formValue.communityspa) {
            RESISPAT = 'Community SPA';
        }

        this.sellPropertyForm.addControl('resispat', new FormControl(RESISPAT));
        // Patio
        let RESIPATO = '';
        if (formValue.patiocovered) {
            RESIPATO = 'Covered';
        } else if (formValue.patiouncovered) {
            RESIPATO = 'Uncovered';
        } else if (formValue.patioenclosed) {
            RESIPATO = 'Enclosed';
        } else if (formValue.patiobrick) {
            RESIPATO = 'Brick';
        } else if (formValue.patioconcrete) {
            RESIPATO = 'Concrete';
        } else if (formValue.patiowooddeck) {
            RESIPATO = 'Wood Deck';
        } else if (formValue.patiostone) {
            RESIPATO = 'Stone';
        } else if (formValue.patioscreened) {
            RESIPATO = 'Screened';
        }
        this.sellPropertyForm.addControl('resipato', new FormControl(RESIPATO));

        // Detached Structures
        let detachedshop = '';
        let detachedguesthouse = '';
        let detachedbarn = '';
        let detachedkennel = '';
        let detachedshed = '';
        let detachedtwohomesonlot = '';
        let detachedgreenhouse = '';
        let detachedother = '';
        if (formValue.detachedshop) {
            detachedshop = 'Shop';
        } else if (formValue.detachedguesthouse) {
            detachedguesthouse = 'Guest House';
        } else if (formValue.detachedbarn) {
            detachedbarn = 'Barn';
        } else if (formValue.detachedkennel) {
            detachedkennel = 'Kennel';
        } else if (formValue.detachedshed) {
            detachedshed = 'Shed';
        } else if (formValue.detachedtwohomesonlot) {
            detachedtwohomesonlot = 'Two Homes On Lot';
        } else if (formValue.detachedgreenhouse) {
            detachedgreenhouse = 'Green House';
        } else if (formValue.detachedother) {
            detachedother = 'Other';
        }
        let RESIDETS = '';
        if (detachedshop || detachedguesthouse || detachedbarn || detachedkennel || detachedshed || detachedtwohomesonlot
            || detachedgreenhouse || detachedother) {
            RESIDETS = detachedshop + detachedguesthouse + detachedbarn + detachedkennel + detachedshed + detachedtwohomesonlot
                + detachedgreenhouse + detachedother
        }

        this.sellPropertyForm.addControl('residets', new FormControl(RESIDETS));

        // Special Disclosure
        let RESISPEC = '';
        if (formValue.floodzone) {
            RESISPEC = 'Flood Zone';
        } else if (formValue.agentownerrelated) {
            RESISPEC = 'Agent/Owner Related';
        } else if (formValue.deathonproperty) {
            RESISPEC = 'Death On Property';
        } else if (formValue.seeremarksattachments) {
            RESISPEC = 'See Remarks Attachments';
        }
        this.sellPropertyForm.addControl('resispec', new FormControl(RESISPEC));

        // Reports Available
        let homeinspection = '';
        let reportsavailableroof = '';
        let reportsavailablepest = '';
        let reportsavailableseptic = '';
        let reportsavailablewell = '';
        let waterpotability = '';
        let reportsavailableccr = '';
        let ruleregulations = '';
        let reportsavailablebylaws = '';
        let reportsavailablebudget = '';
        let financialstatement = '';
        let otherhoadocuments = '';
        let reportsavailablenhd = '';
        let preliminarytitlereport = '';

        if (formValue.homeinspection) {
            homeinspection = 'Home Inspection '
        } else if (formValue.reportsavailableroof) {
            reportsavailableroof = 'Available Roof '
        } else if (formValue.reportsavailablepest) {
            reportsavailablepest = 'Available Pest '
        } else if (formValue.reportsavailableseptic) {
            reportsavailableseptic = 'Available Septic '
        } else if (formValue.reportsavailablewell) {
            reportsavailablewell = 'Available Well '
        } else if (formValue.waterpotability) {
            waterpotability = 'Water Potability '
        } else if (formValue.reportsavailableccr) {
            reportsavailableccr = 'CC&R’s '
        } else if (formValue.ruleregulations) {
            ruleregulations = 'Rule Regulations '
        } else if (formValue.reportsavailablebylaws) {
            reportsavailablebylaws = 'Available Bylaws '
        } else if (formValue.reportsavailablebudget) {
            reportsavailablebudget = 'Available Budget '
        } else if (formValue.financialstatement) {
            financialstatement = 'Financial Statement '
        } else if (formValue.otherhoadocuments) {
            otherhoadocuments = 'Other HOA Documents '
        } else if (formValue.reportsavailablenhd) {
            reportsavailablenhd = 'Available NHD '
        } else if (formValue.preliminarytitlereport) {
            preliminarytitlereport = 'Preliminary Title Report '
        }

        let reportavailable = '';
        if (homeinspection || reportsavailableroof || reportsavailablepest || reportsavailableseptic ||
            reportsavailableseptic || reportsavailablewell || waterpotability || reportsavailableccr ||
            ruleregulations || reportsavailablebylaws || reportsavailablebudget || financialstatement ||
            otherhoadocuments || reportsavailablenhd || preliminarytitlereport) {
            reportavailable = homeinspection + reportsavailableroof + reportsavailablepest + reportsavailableseptic +
                reportsavailableseptic + reportsavailablewell + waterpotability + reportsavailableccr +
                ruleregulations + reportsavailablebylaws + reportsavailablebudget + financialstatement +
                otherhoadocuments + reportsavailablenhd + preliminarytitlereport;
        }

        this.sellPropertyForm.addControl('reportavailable', new FormControl(reportavailable));
        // 1st Loan Type
        let RESI1SLN = '';
        if (formValue.fltassumable) {
            RESI1SLN = 'Assumable';
        } else if (formValue.fltnonassumable) {
            RESI1SLN = 'Non Assumable';
        } else if (formValue.fltprivate) {
            RESI1SLN = 'Private';
        } else if (formValue.fltfreeandclear) {
            RESI1SLN = 'Free And Clear';
        }

        this.sellPropertyForm.addControl('resi1sln', new FormControl(RESI1SLN));
        // 2nd Loan Type
        let RESI2LN = '';
        if (formValue.sltassumable) {
            RESI2LN = 'Assumable';
        } else if (formValue.sltnonassumable) {
            RESI2LN = 'Non Assumable';
        } else if (formValue.sltprivate) {
            RESI2LN = 'Private';
        }
        this.sellPropertyForm.addControl('resi2ln', new FormControl(RESI2LN));
        // Seller Will Consider
        let sellerwillconsider = '';
        if (formValue.government) {
            sellerwillconsider = 'Government'
        } else if (formValue.conventional) {
            sellerwillconsider = 'Conventional'
        } else if (formValue.leaseoption) {
            sellerwillconsider = 'Lease Option'
        } else if (formValue.cash) {
            sellerwillconsider = 'Cash'
        } else if (formValue.ownercarry) {
            sellerwillconsider = 'Owner Carry'
        } else if (formValue.limitedfinancing) {
            sellerwillconsider = 'Limited Financing'
        } else if (formValue.cashonly) {
            sellerwillconsider = 'Cash Only'
        }
        this.sellPropertyForm.addControl('sellerwillconsider', new FormControl(sellerwillconsider));

        // Association/Park Amenities
        let Association = '';
        let AssociationParkAmenitiesPool = '';
        let AssociationParkSpa = '';
        let AssociationParkSauna = '';
        let AssociationParkClubHouse = '';
        let AssociationParkExerciseRoom = '';
        let AssociationParkGreenArea = '';
        let AssociationParkRVStorage = '';
        let AssociationParkTennis = '';
        let AssociationParkRacquetBall = '';
        let AssociationParkSecurityGuard = '';
        let AssociationParkLakePond = '';
        let AssociationParkPlayground = '';
        let AssociationParkLandscapeMaint = '';
        let AssociationParkGatedCommunity = '';
        let OtherAssociationPark = '';
        if (formValue.associationparkamenitiespool) {
            AssociationParkAmenitiesPool = 'Pool ';
        }
        if (formValue.associationparkspa) {
            AssociationParkSpa = 'Spa ';
        }
        if (formValue.associationparksauna) {
            AssociationParkSauna = 'Sauna ';
        }
        if (formValue.associationparkclubhouse) {
            AssociationParkClubHouse = 'Club House ';
        }
        if (formValue.associationparkexerciseroom) {
            AssociationParkExerciseRoom = 'Exercise Room ';
        }
        if (formValue.associationparkgreenarea) {
            AssociationParkGreenArea = 'Green Area ';
        }
        if (formValue.associationparkrvstorage) {
            AssociationParkRVStorage = 'RV Storage ';
        }
        if (formValue.associationparktennis) {
            AssociationParkTennis = 'Tennis ';
        }
        if (formValue.associationparkracquetball) {
            AssociationParkRacquetBall = 'Racquet Ball ';
        }
        if (formValue.associationparksecurityguard) {
            AssociationParkSecurityGuard = 'Security Guard ';
        }
        if (formValue.associationparklakepond) {
            AssociationParkLakePond = 'Lake Pond ';
        }
        if (formValue.associationparkplayground) {
            AssociationParkPlayground = 'Playground ';
        }
        if (formValue.associationparklandscapemaint) {
            AssociationParkLandscapeMaint = 'Landscape Maint ';
        }
        if (formValue.associationparkgatedcommunity) {
            AssociationParkGatedCommunity = 'Gated Community';
        }
        if (formValue.atherassociationpark) {
            OtherAssociationPark = 'Other/N/A ';
        }

        if (AssociationParkAmenitiesPool || AssociationParkSpa || AssociationParkSauna || AssociationParkClubHouse
            || AssociationParkExerciseRoom || AssociationParkGreenArea || AssociationParkRVStorage || AssociationParkTennis
            || AssociationParkRacquetBall
            || AssociationParkSecurityGuard || AssociationParkLakePond || AssociationParkPlayground || AssociationParkLandscapeMaint
            || AssociationParkGatedCommunity || OtherAssociationPark) {
            Association = AssociationParkAmenitiesPool + AssociationParkSpa + AssociationParkSauna + AssociationParkClubHouse
                + AssociationParkExerciseRoom
                + AssociationParkGreenArea + AssociationParkRVStorage + AssociationParkTennis + AssociationParkRacquetBall
                + AssociationParkSecurityGuard + AssociationParkLakePond + AssociationParkPlayground + AssociationParkLandscapeMaint
                + AssociationParkGatedCommunity + OtherAssociationPark
        }

        // Unique Conditions
        let RESIUNIQ = '';
        if (formValue.probate) {
            RESIUNIQ = 'Probate';
        } else if (formValue.estatesale) {
            RESIUNIQ = 'Estate Sale';
        } else if (formValue.bankruptcy) {
            RESIUNIQ = 'Bankruptcy';
        } else if (formValue.courtapprovalreq) {
            RESIUNIQ = 'Court Approval Req';
        } else if (formValue.relocompany) {
            RESIUNIQ = 'Relo Company';
        } else if (formValue.exchange) {
            RESIUNIQ = '1031 Exchange';
        }

        this.sellPropertyForm.addControl('resiuniq', new FormControl(RESIUNIQ));

        // Park Description
        let parkdescr = '';
        if (formValue.parkspacerented) {
            parkdescr = 'Park Space Rented'
        } else if (formValue.parkspaceowned) {
            parkdescr = 'Park Space Owned'
        } else if (formValue.privatespacerented) {
            parkdescr = 'Private Space Rented'
        } else if (formValue.privatespaceowned) {
            parkdescr = 'Private Space Owned'
        } else if (formValue.oncountytaxroll) {
            parkdescr = 'On County Tax Roll'
        }

        this.sellPropertyForm.addControl('parkdescr', new FormControl(parkdescr));
        // Mobile Home Type
        let mobilehometype = '';
        if (formValue.singlewide) {
            mobilehometype = 'Single Wide'
        } else if (formValue.doublewide) {
            mobilehometype = 'Double Wide'
        } else if (formValue.triplewide) {
            mobilehometype = 'Triple Wide'
        } else if (formValue.expando) {
            mobilehometype = 'Expando'
        } else if (formValue.othermht) {
            mobilehometype = 'Other'
        }
        this.sellPropertyForm.addControl('mobilehometype', new FormControl(mobilehometype));

        this.user_id = this.userService.getUserId();
        const address = formValue.streetnumber + ', ' + formValue.streetname + ', ' + formValue.city + ', ' + formValue.country;
        const age = this.currentYear - formValue.yearbuilt;
        const listingdate = this.sellPropertyForm.value.listingdate ? new Date(this.sellPropertyForm.value.listingdate) : null;
        const contingentexpirationdate = this.sellPropertyForm.value.contingentexpirationdate ?
            new Date(this.sellPropertyForm.value.contingentexpirationdate) : null;

        this.sellPropertyForm.addControl('smd_street_address', new FormControl(address));
        this.sellPropertyForm.addControl('age', new FormControl(age));
        this.sellPropertyForm.addControl('user_id', new FormControl(this.user_id));
        this.sellPropertyForm.addControl('association', new FormControl(Association));
        this.sellPropertyForm.addControl('listingdate', new FormControl(listingdate));
        this.sellPropertyForm.addControl('contingentexpirationdate', new FormControl(contingentexpirationdate));
        this.sellPropertyForm.addControl('enteredtomls', new FormControl(false));
        this.createListingObj(this.sellPropertyForm.value);
    }

    createListingObj(formValue) {
        this.smdListingObject = new SmdListingModel(
            formValue.smd_listing_id, formValue.smd_street_address, formValue.area, formValue.city, formValue.country, formValue.crossstreetaddress, formValue.directions, formValue.region, formValue.streetname, formValue.streetnumber,
            formValue.streetpostdirection, formValue.streetsuffix, formValue.zipcode, this.user_id, formValue.agreementtype, formValue.apn, formValue.auction, formValue.association, formValue.age,
            formValue.bathrooms, formValue.additionalapn, formValue.additionalapn2, formValue.additionalapn3,
            formValue.additionalapn4, formValue.additionalapn5, formValue.additionaldebt, formValue.agentremarks, formValue.agerestrictions, formValue.bedroomlevel, formValue.commissionamount, formValue.commissiontype,
            formValue.commissioncomments, formValue.bedrooms, formValue.hoassociation, formValue.otheramenities, formValue.hoadues, formValue.mobilehometype, formValue.emailoffers, formValue.excludedfromsale,
            formValue.email, formValue.gatecode, formValue.basement, formValue.fireplace, formValue.foundation, formValue.numberoffireplaces, formValue.fireplacelocation, formValue.showing, formValue.alarmcheckbox,
            formValue.showinginstr, formValue.alarm, formValue.directionstoproperty, formValue.lotmeasurement, formValue.lotsizesource, formValue.lotsquarefootage, formValue.propertysubtype1, formValue.propertysubtype2,
            formValue.propertysubtype3, formValue.propertytype, formValue.phonetoshow, formValue.parkdescr, formValue.permanentfoundation, formValue.reportavailable, formValue.ownername, formValue.ownerphone,
            formValue.squarefootage, formValue.squarefootagesource, formValue.supralockbox, formValue.shortsale, formValue.sellerwillconsider, formValue.showavmonvow, formValue.showcmntsonvow, formValue.yearbuilt,
            formValue.yearbuiltexception, formValue.availabledate, formValue.contingentexpirationdate, formValue.listingdate, formValue.pendingdate, formValue.subleaseexpiration, formValue.ftrustdeedamt, formValue.strustdeedamt,
            formValue.garagespaces, formValue.leasetype, formValue.leasetypemonthly, formValue.marketingremarks, formValue.notes, formValue.occupantname, formValue.occupanttype, formValue.pool, formValue.publishtointernet,
            formValue.eatingareaarea, formValue.eatingareadim, formValue.eatingarealen, formValue.eatingareawid, formValue.secondbedroomarea, formValue.secondbedroomdim, formValue.secondbedroomlen, formValue.secondbedroomwid,
            formValue.thirdbedroomarea, formValue.thirdbedroomdim, formValue.thirdbedroomlen, formValue.thirdbedroomwid, formValue.fourthbedroomarea, formValue.fourthbedroomdim, formValue.fourthbedroomlen,
            formValue.fourthbedroomwid, formValue.utilityroomarea, formValue.utilityroomdim, formValue.utilityroomlen, formValue.utilityroomwid, formValue.mainbedroomarea, formValue.mainbedroomdim, formValue.mainbedroomlen,
            formValue.mainbedroomwid, formValue.livingroomarea, formValue.livingroomdim, formValue.livingroomlen, formValue.livingroomwid, formValue.kitchenarea, formValue.kitchendim, formValue.kitchenlen, formValue.kitchenwid,
            formValue.greatroomarea, formValue.greatroomdim, formValue.greatroomlen, formValue.greatroomwid, formValue.formaldiningroomarea, formValue.formaldiningroomdim, formValue.formaldiningroomlen,
            formValue.formaldiningroomwid, formValue.extraroomarea, formValue.extraroomdim, formValue.extraroomlen, formValue.extraroomwid, formValue.familyroomarea, formValue.familyroomdim, formValue.familyroomlen,
            formValue.familyroomwid, formValue.showaddresstopublic, formValue.schooldistrict, formValue.schoolname1, formValue.schoolname2, formValue.schoolname3, formValue.schoolscomment, formValue.sublease,
            formValue.transactiontype, formValue.listingagentmlsid, formValue.listingcoagentmlsid, formValue.listingcoofficemlsid, formValue.listingofficemlsid, formValue.resi1sln, formValue.resi2ln, formValue.resibssq,
            formValue.resibthf, formValue.resicnss, formValue.residets, formValue.residinf, formValue.residsft, formValue.residual, formValue.resiener, formValue.resiextr, formValue.resiflor, formValue.resifreq,
            formValue.resigart, formValue.resigpm, formValue.resigsel, formValue.resihtcl, formValue.resihudn, formValue.resiktcn, formValue.resilbox, formValue.resilics, formValue.resilndr, formValue.resilsde,
            formValue.resiltdm, formValue.resimodl, formValue.resipato, formValue.resipknm, formValue.resipolt, formValue.resiprkf, formValue.resirebk, formValue.resirent, formValue.resiroof, formValue.resiscop,
            formValue.resisite, formValue.resisnum, formValue.resispat, formValue.resispay, formValue.resispec, formValue.resispft, formValue.resistor, formValue.resitype, formValue.resiunds, formValue.resiuniq,
            formValue.resiwlhp, formValue.resiwtrl, formValue.resiwatr, formValue.resixtrr, formValue.resiyard, formValue.resizone, formValue.listingprice, formValue.unit, formValue.enteredtomls, formValue.latitude,
            formValue.longitude);

    }

    onSellProperty() {
        if (this.sellPropertyForm.valid &&
            this.sellPropertyForm.value.longitude != null &&
            this.sellPropertyForm.value.latitude != null) {
            this.isValid = true;
            this.getDataFromForm();
            this.listingService.postListing(this.smdListingObject).subscribe(data => {
                    if (data) {
                        this.localStorage.setItem('listing_id', data.smd_listing_id);
                        this.localStorage.setItem('listing', JSON.stringify(data));
                        this.localStorage.setItem('stage', 'marketing-stage-control-panel');
                        this.router.navigate(['/marketing-stage-control-panel'], {queryParams: {stage: 'marketing-stage'}});
                    }
                },
                error => {
                    this.errorMessage = true;
                });
        } else {
            this.isValid = false;
        }
    }
}
