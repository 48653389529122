import {Component, OnInit} from '@angular/core';
import {PropertiesService} from "./properties.service";
import {Router} from "@angular/router";
import {PropertyModel} from "../models/property.model";

@Component({
    selector: 'ms-my-properties',
    templateUrl: './my-properties.component.html',
    styleUrls: ['./my-properties.component.scss']
})
export class MyPropertiesComponent implements OnInit {
    properties: PropertyModel[];

    constructor(public property_service: PropertiesService, private router: Router) {
        this.property_service.getProperties().subscribe(prop => {
            this.properties = prop;
        });
    }

    ngOnInit() {

    }

    onSellProperty() {
        this.router.navigate(['sell-property']);
    }

}
