import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ms-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
    @Input () index: number;
    @Input() property: any;

  constructor() {
  }

  ngOnInit() {
  }

}
