import {Injectable, Input, OnInit} from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {UserModel, UserRole} from "../models/user.model";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {api_url} from "../../environments/environment";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }),
    withCredentials: true
};

@Injectable()
export class UsersService implements OnInit {
    private api_url = api_url.url;
    data: any = [];
    users: UserModel[] = [];
    user: UserModel;
    private userData = new BehaviorSubject(undefined);
    private userId: string;
    private userEditedData = new BehaviorSubject(undefined);
    private roleEditedData = new BehaviorSubject(undefined);

    constructor(private httpClient: HttpClient
                ) {
    }

    ngOnInit() {
    }

    searchUsers(data: string) {
        return this.httpClient.get<any>(`${this.api_url}/search?index=user&query=${data}`, {withCredentials: true})
            .pipe(catchError(this._handleError))
    }

    getUser(user_id: string): Observable<UserModel> {
        return this.httpClient.get<UserModel>(`${this.api_url}/user/${user_id}`, {withCredentials: true})
            .pipe(catchError(this._handleError))
    }

    getUsers(): Observable<UserModel[]> {
        return this.httpClient.get<UserModel[]>(`${this.api_url}/user`, httpOptions)
            .pipe(catchError(this._handleError))
    }

    getUserRoles(): Observable<UserRole[]> {
        return this.httpClient.get<UserRole[]>(`${this.api_url}/user-role`, {withCredentials: true})
            .pipe(catchError(this._handleError))
    }

    getUserRole(user_role_id): Observable<UserRole> {
        return this.httpClient.get<UserRole>(`${this.api_url}/user-role/${user_role_id}`, {withCredentials: true})
            .pipe(catchError(this._handleError))
    }

    login(data) {
        return this.httpClient.post<any>(`${this.api_url}/login`,
            JSON.stringify(data), httpOptions
        ).pipe(
            map((res: UserModel) => {
                return res
            })
        );
    }

    refreshToken(data) {
        return this.httpClient.post<any>(`${this.api_url}/refresh-token`,
            JSON.stringify(data), httpOptions
        ).pipe(
            map((res: UserModel) => {
                return res
            })
        )
    }

    postUser(data) {
        return this.httpClient.post<any>(`${this.api_url}/register`,
            data, httpOptions
        ).pipe(
            map((res: UserModel) => {
                return res
            })
        );
    }

    postUserBySuperAdmin(data) {
        return this.httpClient.post<Promise<UserModel>>(`${this.api_url}/user`,
            data, httpOptions
        ).pipe(
            map((res: UserModel) => {
                return res
            })
        );
    }

    updateUser(user_id, data) {
        return this.httpClient
            .put<UserModel>(`${this.api_url}/user/${user_id}`, data, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    deleteUser(user) {
        return this.httpClient
            .delete(`${this.api_url}/user/${user.user_id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    postUserEmail(email): Observable<any> {
        const mail = {email: email};
        return this.httpClient.post(`http://apidev-env.eba-mn6fvn5p.us-west-2.elasticbeanstalk.com/api/v1/forgot-password`, mail, httpOptions)
    }

    getTokens(): Observable<any> {
        return this.httpClient.get(`${this.api_url}/password-reset/`)
            .pipe(catchError(this._handleError));
    }

    getToken(token): Observable<any> {
        return this.httpClient.get(`${this.api_url}/restore-password?reset_token=${token}`)
            .pipe(catchError(this._handleError));
    }

    updateUserPassword(data) {
        return this.httpClient
            .post<any>(`http://apidev-env.eba-mn6fvn5p.us-west-2.elasticbeanstalk.com/api/v1/forgot-password/restore`, data, httpOptions)
            .pipe(catchError(this._handleError));
    }

    sendUserData(data: UserModel) {
        this.userData.next({data: data});
    }

    getUserData() {
        return this.userData;
    }

    getUserId() {
        this.userId = JSON.parse(localStorage.getItem('user')).user_id;
        return this.userId;
    }

    sendUserEditedData(data: UserModel) {
        this.userEditedData.next({data: data});
    }

    getUserEditedData() {
        return this.userEditedData;
    }

    sendRoleEditedData(data) {
        this.roleEditedData.next({data: data});
    }

    getRoleEditedData() {
        return this.roleEditedData;
    }

    getUserObject() {
        return JSON.parse(localStorage.getItem('user'));
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
