// The file for the current environment will overwrite this one during build.
// Different environments can be found in ./environment.{dev|prod}.ts, and
// you can create your own and use it with the --env flag.
// The build system defaults to the dev environment.

export const environment = {
  production: false
};

export const upload_url = {
  url: 'https://evening-anchorage-3159.herokuapp.com/api/'
};

export const api_url = {
  url: 'https://api.sellmydigs.com/api/v1'
};

export const API_Key = {
  key: 'AIzaSyAxzlzn2btmiIec0XyUIeHDZSRJplOgYlI'
}

