import {Component, Input, OnInit} from '@angular/core';
import {SimilarHousesMapComponent} from "./similar-houses-map/similar-houses-map.component";
import {MapsAPILoader} from "@agm/core";
import {HousesService} from "../../services/houses.service";
import {MatDialog, MatDialogRef} from "@angular/material";
import {HouseModel} from "../../models/house.model";
import {LocalStorageService} from "../../services/local-storage.service";
import {ListingsService} from "../../services/listings.service";


@Component({
    selector: 'ms-similar-houses',
    templateUrl: './similar-houses.component.html',
    styleUrls: ['./similar-houses.component.scss']
})
export class SimilarHousesComponent implements OnInit {
    housesArr: HouseModel[]
    similarHouses: MatDialogRef<SimilarHousesMapComponent>;
    @Input() userProperty;

    constructor(public mapsApiLoader: MapsAPILoader,
                public housesService: HousesService,
                public localStorage: LocalStorageService,
                public listingService: ListingsService,
                public dialog: MatDialog) {
        this.housesArr = [];
    }

    ngOnInit() {
        const similarProperty = this.housesService.getSimilarProperties();
            this.housesService.getSimilarHouses(similarProperty).subscribe(houses => {
                this.housesArr = houses;
                this.localStorage.setItem('houses', JSON.stringify(this.housesArr));
            })
    }

    openSimilarHousesMap() {
        this.similarHouses = this.dialog.open(SimilarHousesMapComponent, {
            disableClose: false,
        });
        this.similarHouses.componentInstance.userProperty = this.userProperty;

        this.similarHouses.afterClosed().subscribe(result => {
            this.similarHouses = null;
        });
    }
}
