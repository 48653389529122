import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable, throwError} from "rxjs/index";
import {SmdListingModel} from "../models/smd-listing.model";
import {catchError, map} from "rxjs/operators";
import {api_url} from "../../environments/environment";
import {HttpService} from "./http.service";

@Injectable()
export class ListingsService {
    private api_url = api_url.url;
    private auth_token;
    private propertyInfo = new BehaviorSubject(undefined);
    private unreviewedListings = new BehaviorSubject(undefined);


    constructor(private httpClient: HttpClient,  public httpService: HttpService) {
    }

    getAllListings(): Observable<SmdListingModel[]> {
        return this.httpClient.get<SmdListingModel[]>(`${this.api_url}/smd_listing`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    getUserListings(user_id: string): Observable<SmdListingModel[]> {
        return this.httpClient.get<SmdListingModel[]>(`${this.api_url}/smd_listing`, {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getListing(id): Observable<SmdListingModel> {
        return this.httpClient.get<SmdListingModel>(`${this.api_url}/smd_listing/${id}`, {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getStage(id) {
        return this.httpClient.get<any>(`${this.api_url}/smd_listing/${id}/stage`, {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    goToTheNextStage(listing: SmdListingModel) {
        return this.httpClient.put<SmdListingModel>(`${this.api_url}/smd_listing/${listing.smd_listing_id}/nextstage`,
            listing,
            {withCredentials: true})
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    postListing(data: SmdListingModel): Observable<SmdListingModel> {
        const httpOptions = this.httpService.getHttpOptions();
        return this.httpClient.post<SmdListingModel>(`${this.api_url}/smd_listing`,
            JSON.stringify(data), httpOptions
        )
    }

    editRequired(listing, fields): Observable<SmdListingModel> {
        return this.httpClient
            .put<SmdListingModel>(`${this.api_url}/smd_listing/${listing.smd_listing_id}`,
                fields, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    enterToMLS(listing): Observable<SmdListingModel> {
        return this.httpClient.put<SmdListingModel>(`${this.api_url}/smd_listing/enter-to-mls/${listing.smd_listing_id}`,
            {}, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    updateListing(listing): Observable<SmdListingModel> {
        return this.httpClient
            .put<SmdListingModel>(`${this.api_url}/smd_listing/${listing.smd_listing_id}`, listing, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    deleteListing(listing) {
        return this.httpClient
            .delete(`${this.api_url}/listing/${listing.smd_listing_id.uuid}?auth_token=${this.auth_token}`)
            .pipe(catchError(this._handleError));
    }

    sendPropertyData(data: any) {
        this.propertyInfo.next({data: data});
    }

    getPropertyData() {
        return this.propertyInfo;
    }

    sendListingsNumber(data: number) {
        this.unreviewedListings.next({data: data});
    }

    getListingsNumber() {
        return this.unreviewedListings;
    }

    formatFullDate(date) {
        let yy = date.getFullYear();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        date = yy + '/' + mm + '/' + dd;
        return date
    }

    formatDate(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        date = mm + '/' + dd;
        return date
    }

    getListingStage(stage_id) {
        switch (stage_id) {
            case 2: return 'mls';
            case 3: return 'marketing-stage-control-panel';
            case 4: return 'offer-stage';
            case 5: return 'escrow-stage';
            case 6: return 'close-stage';
            default: return 'prepare-stage';
        }
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
