import {Injectable} from '@angular/core';
import {UserRole} from '../models/user.model';

@Injectable()
export class LocalStorageService {

    roleData: UserRole;

    constructor(
    ) {
    }

    getItem(key) {
        return localStorage.getItem(key);
    }

    setItem(key, value) {
        return localStorage.setItem(key, value);
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

    removeAll() {
        return localStorage.clear();
    }

    setUserRole(key, value) {
        return localStorage.setItem(key, value);
    }

    getRoleData() {
        const data = JSON.parse(localStorage.getItem('role_data'));
        return data;
    }

    getListingId() {
        return localStorage.getItem('listing_id');
    }

    getUserRole() {
        const resources = JSON.parse(localStorage.getItem('role_data')).resources;
        switch (resources.length) {
            case 3: return 'super_admin';
            case 2: return 'admin';
            default: return 'user';
        }
    }

    returnListing() {
        return JSON.parse(localStorage.getItem('listing'));
    }
}

