import { Component, OnInit } from '@angular/core';
import {WidgetService} from "../../../services/widget.service";

@Component({
  selector: 'ms-countdown-widget',
  templateUrl: './widget-popup.component.html',
  styleUrls: ['./widget-popup.component.scss']
})
export class WidgetPopupComponent implements OnInit {
  widgetInfo: any;

  constructor(public widgetService: WidgetService) { }

  ngOnInit() {
    this.widgetService.getData().subscribe(data => {
      this.widgetInfo = data;
    })
  }

}
