import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable, throwError} from "rxjs";
import {OfferModel} from "../models/offer.model";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {LocalStorageService} from "./local-storage.service";
import {api_url} from "../../environments/environment";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class OfferService {
    private api_url = api_url.url;
    private listing_id;
    private offerData = new BehaviorSubject(undefined);
    private offerDetail = new BehaviorSubject(undefined);
    private updatedOfferDetail = new BehaviorSubject(undefined);
    private offerEditData = new BehaviorSubject(undefined);

    constructor(private httpClient: HttpClient,
                private localStorage: LocalStorageService) {
    }

    getAllOffers(): Observable<OfferModel[]> {
        this.listing_id = this.localStorage.getItem('listing_id');
        return this.httpClient.get<OfferModel[]>(`${this.api_url}/offer/listing/${this.listing_id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    postOffer(data: Observable<OfferModel>) {
        return this.httpClient.post<OfferModel>(`${this.api_url}/offer`,
            data, {withCredentials: true}
        )
    }

    updateOffer(offer) {
        return this.httpClient
            .put(`${this.api_url}/offer/${offer.offer_id}`, offer,
                {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    deleteOffer(offer) {
        return this.httpClient
            .delete(`${this.api_url}/offer/${offer.offer_id}`, {withCredentials: true})
            .pipe(catchError(this._handleError));
    }

    sendOfferData(data: OfferModel) {
        this.offerData.next({data: data});
    }

    getOfferData() {
        return this.offerData;
    }

    sendOfferDetail(data: Array<any>) {
        this.offerDetail.next({data: data});
    }

    getOfferDetail() {
        return this.offerDetail;
    }

    sendOfferEditData(data: Array<any>) {
        this.offerEditData.next({data: data});
    }

    getOfferEditData() {
        return this.offerEditData;
    }

    sendUpdatedOfferDetail(data: Array<any>) {
        this.updatedOfferDetail.next({data: data});
    }

    getUpdatedOfferDetail() {
        return this.updatedOfferDetail;
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
