import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {api_url} from "../../environments/environment";
import {
    Inventory,
    MedianPrice
} from "../marketing-stage-control-panel/marketing-stage-widget/marketing-stage-widget.component";

@Injectable()
export class WidgetService {
    private api_url = api_url.url;
    private widgetInfo = new BehaviorSubject(undefined);

    constructor(private httpClient: HttpClient) {
    }

    getInventory(): Observable<Inventory> {
        return this.httpClient.get<Inventory>(`${this.api_url}/rets/inventory`)
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getMedianPrice(): Observable<MedianPrice> {
        return this.httpClient.get<MedianPrice>(`${this.api_url}/rets/medianprice`)
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    getDOM(): Observable<any> {
        return this.httpClient.get<any>(`${this.api_url}/rets/dom`)
            .pipe(map(res => res),
                catchError(this._handleError));
    }

    sendData(value: object) {
        this.widgetInfo.next(value);
    }

    getData() {
        return this.widgetInfo;
    }

    private _handleError(err: HttpErrorResponse | any) {
        const errorMsg = err.message || 'Error: Unable to complete request.';
        return throwError(errorMsg);
    }
}
