"use strict";

import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from "@angular/material";
import {CookieService} from "ngx-cookie-service/cookie-service/cookie.service";
import {WelcomePopupComponent} from "../login-page/welcome-popup/welcome-popup.component";
import {LocalStorageService} from "../services/local-storage.service";
import {UsersService} from "../services/users.service";
import {UserModel} from "../models/user.model";
import {LoginService} from "../services/login.service";
import {SmdListingModel} from "../models/smd-listing.model";
import {ListingsService} from "../services/listings.service";

@Component({
    selector: 'ms-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements OnInit {
    welcomePopup: MatDialogRef<WelcomePopupComponent>;
    customizerIn: boolean;
    dark: object;
    boxed: boolean;
    stage: string;
    stage_id: number;
    user: UserModel;
    openProfileInfo: boolean = true;

    @ViewChild('sidenav') sidenav;
    admins: boolean;
    userId: string;
    role: string;
    activeTab: string;
    needsReview: number;

    constructor(private router: Router,
                private userService: UsersService,
                public localStorage: LocalStorageService,
                public listingsService: ListingsService,
                private loginService: LoginService,
                public cookieService: CookieService,
                public dialog: MatDialog) {
        this.router.events.subscribe((event: any) => {
            if (event.url) {
                const url = event.url;
                if (url.includes('?stage')) {
                    this.checkStage();
                    this.router.navigate(['/' + this.stage], {
                        queryParams: {
                            yourParamName: null,
                            youCanRemoveMultiple: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                }
            }
        });
        this.userId = this.userService.getUserId();
        this.listingsService.getListingsNumber().subscribe(data => {
            this.needsReview = data ? data.data : 0;
        })
    }

    getUser() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.userId = this.user.user_id;
        if (this.user.popup === 0 || this.user.popup === null) {
            setTimeout(() => this.openPopup());
        }
    }

    checkTokens() {
        const accessToken = this.cookieService.get('access_token');
        const refreshToken = this.cookieService.get('refresh_token');
        const data = {
            refresh_token: refreshToken,
            fingerprint: this.cookieService.get('fingerprint')
        };
        if (!accessToken && refreshToken) {
            this.userService.refreshToken(data).subscribe(user => {
                const access_token = this.loginService.getCookie('access_token');
                const refresh_token = this.loginService.getCookie('refresh_token');
                this.cookieService.set('access_token', access_token);
                this.cookieService.set('refresh_token', refresh_token);
                this.user = user;
            });
        } else {
            this.getUser();
        }
    }

    ngOnInit() {
        this.role = this.localStorage.getUserRole();
        this.localStorage.removeItem('listing');
        this.checkTokens();
        if (this.role === 'user') {
            this.admins = false;
            this.listingsService.getUserListings(this.userId).subscribe((listings: SmdListingModel[]) => {
                if (listings.length === 1) {
                    this.listingsService.getStage(listings[0].smd_listing_id).subscribe(listing => {
                        const stage = this.listingsService.getListingStage(listing.stage_id);
                        this.stage_id = listing.stage_id;
                        this.localStorage.setItem('stage', stage);
                        this.localStorage.setItem('listing_id', listings[0].smd_listing_id);
                        this.localStorage.setItem('stage_id', this.stage_id);
                        this.listingsService.getListing(listings[0].smd_listing_id).subscribe(list => {
                            this.localStorage.setItem('listing', JSON.stringify(list));
                            this.checkStage();
                        })
                    }, error => {
                        this.localStorage.setItem('stage', 'prepare-stage');
                        this.stage_id = 0;
                        this.localStorage.setItem('stage_id', this.stage_id);
                        this.checkStage();
                    });
                } else {
                    this.router.navigate(['/all-listings/' + this.userId]);
                }
            })
        } else if (this.role === 'admin' || this.role === 'super_admin') {
            this.admins = true;
            this.router.navigate(['/all-listings']);
        }
    }

    checkStage() {
        const stage = this.localStorage.getItem('stage');
        this.stage_id = JSON.parse(this.localStorage.getItem('stage_id'));
        this.stage = stage;
        this.activeTab = this.stage;
        this.router.navigate(['/' + stage]);
    }

    openPopup() {
        this.welcomePopup = this.dialog.open(WelcomePopupComponent, {
            disableClose: false
        });

        this.welcomePopup.afterClosed().subscribe(result => {
            this.welcomePopup = null;
        });
    }

    logout() {
        this.cookieService.deleteAll();
        this.localStorage.removeAll();
        this.router.navigate(['/login']);
    }

}
